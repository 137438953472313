import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { DefenceCity, OffenceCity } from "api/api.types";
import { useMyProfile } from "hooks/useMyProfile";
import { AttackModal } from "pages/WorldDomination/WDGridPanel/AttackModal";
import {
  addAssetsAndQueueToCities,
  addAttacksToCities,
  formCitiesList,
  formClanCitiesList,
  mergeCities,
  transform,
} from "pages/WorldDomination/WDGridPanel/helpers";
import * as s from "pages/WorldDomination/WDGridPanel/WDGrid.styled";
import { WDGridRow } from "pages/WorldDomination/WDGridPanel/WDGridRow";
import React, { useMemo } from "react";
import {
  useGetClanAssetsQuery,
  useGetClanLeaderboardQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
} from "redux/rtkQuery/rtkApi";
import {
  actions,
  selectAttack,
  selectFilter,
  selectSearch,
} from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";

export type ICity = { name: string; points: number; id: string };
export type IRow = {
  tier: number;
  rank: number;
  clanName: string;
  totalPoints: number;
  vaultedPoints: number;
  cities: ICity[];
  cityEconomy: number;
  clanId: string;
};
export type IAllRows = { tier: number; groupRows: IRow[] }[];

export function WDGrid() {
  const dispatch = useAppDispatch();

  const filter = useAppSelector(selectFilter);
  const search = useAppSelector(selectSearch);
  const attack = useAppSelector(selectAttack);

  const { data: event } = useGetEventQuery();
  let skip: typeof skipToken | undefined = skipToken;
  if (event?.id !== undefined && event?.extensions?.worldDomination !== false)
    skip = undefined;

  const { data: clanLb } = useGetClanLeaderboardQuery(
    event && !skip
      ? { event, params: { clanSlots: true, allItems: true } }
      : skipToken
  );
  const { data: turn } = useGetEventTurnQuery(skip ?? event?.id!);
  const { data: assets } = useGetClanAssetsQuery(skip ?? event?.id!);
  const { data: queue } = useGetRoundQueueQuery();
  const user = useMyProfile();

  const lastTurnDefense: DefenceCity[] | undefined = useMemo(
    () =>
      turn?.battleSummaryList.find((i) => i.round === turn.round - 1)?.defence
        ?.citiesList,
    [turn?.round]
  );

  const lastTurnOffense: OffenceCity[] | undefined = useMemo(
    () =>
      turn?.battleSummaryList?.find((i) => i.round === turn.round - 1)?.offence
        ?.citiesList,
    [turn?.round]
  );

  // ALL HARD CALCULATION DOWN
  const cities = useMemo(() => formCitiesList(clanLb), [clanLb]);
  const clanCities = useMemo(() => formClanCitiesList(turn), [turn]);
  const mergedCities = useMemo(
    () => mergeCities(cities, clanCities),
    [cities, clanCities]
  );

  const citiesWithQueue = useMemo(
    () => addAssetsAndQueueToCities(mergedCities, assets, queue),
    [queue, assets, mergedCities]
  );
  const citiesFilled = useMemo(
    () => addAttacksToCities(citiesWithQueue, lastTurnDefense, lastTurnOffense),
    [lastTurnDefense, lastTurnOffense, citiesWithQueue]
  );
  let allRows = useMemo(
    () => transform(clanLb, search, event),
    [clanLb, search, assets, queue, event]
  );

  if (!clanLb || !event || !turn || !user || !assets) return null;

  const myClanName = user.clan.name;
  let num = 0;
  if (!allRows) return null;

  // Data for My Clan tab
  // select my cities from lb or from turn
  if (filter === "My Cities") {
    const filteredRowsFromLB = allRows.filter((item) => {
      return item.groupRows.find((row) => row.clanName === myClanName);
    });
    if (filteredRowsFromLB.length) {
      filteredRowsFromLB[0].groupRows = filteredRowsFromLB[0].groupRows.filter(
        (clan) => clan.clanName === myClanName
      );
      allRows = filteredRowsFromLB;
    } else {
      allRows = [
        {
          tier: 100,
          groupRows: [
            {
              tier: 100,
              rank: 100,
              clanName: myClanName,
              cityEconomy: 0,
              totalPoints: 0,
              vaultedPoints: 0,
              cities: turn.clan.slotsList.filter((c) => c.kind === 2),
              clanId: "undefined",
            },
          ],
        },
      ];
    }
  }

  return (
    <div>
      {allRows.map((group) => {
        return (
          <s.TierGroup key={group.tier}>
            <s.Tier val={group.tier} />
            <s.Rows>
              {group.groupRows.map((row, idx) => {
                num++;
                return (
                  <WDGridRow
                    citiesFilled={citiesFilled}
                    key={idx}
                    data={row}
                    num={num}
                    myClanName={myClanName}
                  />
                );
              })}
            </s.Rows>
          </s.TierGroup>
        );
      })}
      {attack?.city?.id && (
        <AttackModal onClose={() => dispatch(actions.setAttack(undefined))} />
      )}
    </div>
  );
}

export type ICityFull = {
  id: string;
  kind: number;
  name: string;
  points: number;
  status: number;
  shields: number;
  shieldsPlanned: number;
  attacksPlanned: number;
  successfulNukes: number;
  failedNukes: number;
  damagePoints: number;
  vampirePoints: number;
};

export type ICitiesObj = {
  [key: string]: ICityFull;
};
