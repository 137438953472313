import { IIndividualLBItem } from "api";
import economic from "assets/economic-point-icon-grey.png";
import military from "assets/military-point-icon-grey.png";
import members from "components/TieredLeaderboard/assets/members.png";
import total from "components/TieredLeaderboard/assets/totalWhite.png";
import * as s from "components/TieredLeaderboard/componetns/Tablet.styled";
import { usePlayer } from "hooks/usePlayer";

export function I_TabletTierPlayer(props: { player: IIndividualLBItem }) {
  const player = usePlayer(props.player.id);
  return (
    <s.IndividualTiredItem>
      {/*clan*/}
      <div>
        <div>{props.player.rank}</div>
        <div>{player?.name}</div>
      </div>
      {/*  members*/}
      <div>
        <img src={members} alt="members" />
        {player?.clan?.name}
      </div>
      {/*points*/}
      <div>
        <div>
          <img src={military} alt="military" />
          {props.player.militaryScore}
        </div>
        <div>
          <img src={economic} alt="economic" />
          {props.player.economicScore}
        </div>
        <div>
          <img src={total} alt="total" />
          {props.player.totalScore}
        </div>
      </div>
    </s.IndividualTiredItem>
  );
}
