import React from "react";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { css } from "styled-components/macro";

const StyledBlock = styled.div`
  position: absolute;
  top: 61px;
  margin: auto;
  width: 268px;
  z-index: 300;
  left: -7px;
  border: 1px solid #4e4e4e;
  background-color: black;
`;

const StyledOption = styled.div<{ active: boolean }>`
  position: relative;
  color: grey;
  padding: 16px;
  font-size: 12px;
  text-align: center;
  border-style: solid;
  border-width: 2px;
  border-image: linear-gradient(to right, transparent, grey, transparent) 0 0 1
    0;
  background-repeat: no-repeat;
  text-transform: uppercase;

  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      color: orange;
      border-image: linear-gradient(to right, transparent, orange, transparent) 0 0 1 0;
      background-image: radial-gradient(ellipse at bottom, #ffa50030, transparent 0%);
    `}
  &:hover {
    color: orange;
    border-image: linear-gradient(to right, transparent, orange, transparent) 0 0 1 0;
    background-image: radial-gradient(ellipse at bottom, #ffa50030, transparent 0%);
  }
`;

const CWSumbenu = (props: { onClose: any }) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  
  return (
    <StyledBlock>
      <StyledOption
        active={pathname.includes("/clanWars/intraClanLB")}
        onClick={() => {
          navigate("/clanWars/intraClanLB");
          props.onClose();
        }}
      >
        Intra-Clan Leaderboard
      </StyledOption>
      <StyledOption
        active={pathname.includes("/clanWars/tieredLB")}
        onClick={() => {
          navigate("/clanWars/tieredLB/clan");
          props.onClose();
        }}
      >
        Clan War Tiered Leaderboard
      </StyledOption>
      <StyledOption
        active={pathname.includes("/clanWars/transferHistory")}
        onClick={() => {
          navigate("/clanWars/transferHistory");
          props.onClose();
        }}
      >
        Treasury Transfer History
      </StyledOption>
      <StyledOption
        active={pathname.includes("/clanWars/worldDomination")}
        onClick={() => {
          navigate("/clanWars/worldDomination");
          props.onClose();
        }}
      >
        World Domination
      </StyledOption>
    </StyledBlock>
  );
};

export default CWSumbenu;