import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWDStreamItem } from "api/stream";
import { ICity } from "pages/WorldDomination/WDGridPanel/WDGrid";
import memoize from "proxy-memoize";
import { RootState } from "store";

export type IFilter = "Available Points" | "Population" | "My Cities";

export type IWorldDomination = {
  filter: IFilter;
  search: string;
  attack?: {
    city: ICity;
    clanId: string;
  };
  buildShieldCity?: ICity;
  stream: IWDStreamItem[];
  lastTurnEnded: boolean;
  eventEnded: boolean;
};

const initialState: IWorldDomination = {
  filter: "Available Points",
  search: "",
  stream: [],
  lastTurnEnded: true,
  eventEnded: false,
};

export const worldDomination = createSlice({
  name: "worldDomination",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<IFilter>) => {
      state.filter = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setAttack: (
      state,
      action: PayloadAction<{ city: ICity; clanId: string } | undefined>
    ) => {
      state.attack = action.payload;
    },
    setBuildShieldCity: (state, action: PayloadAction<ICity | undefined>) => {
      state.buildShieldCity = action.payload;
    },
    streamAdd: (state, action: PayloadAction<IWDStreamItem>) => {
      state.stream.push(action.payload);
    },
    streamRemove: (state, action: PayloadAction<string>) => {
      state.stream = state.stream.filter((i) => i.requestId !== action.payload);
    },
    setLastTurnEnded: (state, action: PayloadAction<boolean>) => {
      state.lastTurnEnded = action.payload;
    },
    setEventEnded: (state,action:PayloadAction<boolean>) => {
      state.eventEnded = action.payload;
    },
  },
});

export const actions = worldDomination.actions;
export const { streamAdd, setLastTurnEnded, setEventEnded } = worldDomination.actions;

//selectors
export const selectFilter = memoize(
  (state: RootState) => state.worldDomination.filter
);
export const selectSearch = memoize(
  (state: RootState) => state.worldDomination.search
);
export const selectAttack = memoize(
  (state: RootState) => state.worldDomination.attack
);
export const selectBuildShield = memoize(
  (state: RootState) => state.worldDomination.buildShieldCity
);

export const selectWDStream = memoize(
  (state: RootState) => state.worldDomination.stream
);
export const selectLastTurnEnded = memoize(
  (state: RootState) => state.worldDomination.lastTurnEnded
);

export const selectEventEnded = memoize(
  (state: RootState) => state.worldDomination.eventEnded
);

// export const selectStreamItemById = (id: string) =>
//   memoize((state: RootState) =>{
//     let res = state.worldDomination.stream.find((i) => i.requestId === id);
//     greenLog(res)
//     if (res === undefined) return res;
//     return !!res?.error;
//   });

export const selectStreamItemById2 = (id: string) => (state: RootState) =>
  state.worldDomination.stream.find((i) => i.requestId === id);

export default worldDomination.reducer;
