import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cgs_getTokenMetadata, staking_getSlotId } from "api";
import { ITokenMetadata } from "api/api.types";
import { AppThunk, RootState } from "store";

export type IFiltersType = "outfits" | "weapons";
export type IFiltersRarity = "all" | 0 | 1 | 2 | 3 | 4 | 5;
export type IStakePageState = {
  filters: {
    type: IFiltersType;
    rarity: IFiltersRarity[];
  };
  selectedItemId?: string;
  selectedItemMetadata?: ITokenMetadata;
};

const initialState: IStakePageState = {
  filters: {
    type: "outfits",
    rarity: ["all"],
  },
};

export const stakePage = createSlice({
  name: "stakePage",
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<IFiltersType>) => {
      state.filters.type = action.payload;
    },
    setSelectedItemId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedItemId = action.payload;
    },
    toggleFilter: (state, action: PayloadAction<IFiltersRarity>) => {
      if (action.payload === "all") {
        state.filters.rarity = ["all"];
      } else {
        if (state.filters.rarity.includes(action.payload)) {
          state.filters.rarity = state.filters.rarity.filter(
            (i) => i !== action.payload
          );
        } else {
          state.filters.rarity = state.filters.rarity.filter(
            (i) => i !== "all"
          );
          state.filters.rarity.push(action.payload);
        }
      }
      if (state.filters.rarity.length === 0) state.filters.rarity = ["all"];
    },
    setSelectedItemMetadata: (
      state,
      action: PayloadAction<ITokenMetadata | undefined>
    ) => {
      state.selectedItemMetadata = action.payload;
    },
  },
});

export const actions = stakePage.actions;

//selectors
export const selectFilters = (state: RootState) => state.stakePage.filters;
//thunks
export const fetchItemMetadata =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(actions.setSelectedItemMetadata(undefined));
    const response = await cgs_getTokenMetadata(id);
    dispatch(actions.setSelectedItemMetadata(response));
  };

export const stakeItem = async (
  item_id: string,
  slot_set_id: string,
  slot_number: number
) => {
  const response = await staking_getSlotId(item_id, slot_set_id, slot_number);
  console.log("stakeItemResponse", response);
  return response;
};

export default stakePage.reducer;
