import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

const url = window.location.href;
export const wallets = [new PhantomWalletAdapter()];

const prod = "https://prod-server.miniroyale.io";
const dev = "https://staging-server.miniroyale.io";

let server: string;
let network
let env: 'prod' | 'localhost' | 'dev'


if (url.includes("social.miniroyale.io")) {
  env= 'prod'
  server = prod;
  network = WalletAdapterNetwork.Mainnet;
} else if (url.includes("social-react.miniroyale.io")) {
  env = "dev"
  server = prod;
  network = WalletAdapterNetwork.Mainnet;
} else if (url.includes("social-react-test.miniroyale.io")) {
  env='dev'
  server = dev;
  network = WalletAdapterNetwork.Devnet;
} else { // localhost
  env='localhost'
  server = dev;
  network = WalletAdapterNetwork.Devnet;
}

export { server, env };


export const endpoint = network === WalletAdapterNetwork.Mainnet ? "https://falling-winter-cherry.solana-mainnet.quiknode.pro/14d3c147745fa68a6f0d7072af99d64ae6a6aaf9/" : clusterApiUrl(network);
export const serverGW = server + "/miniroyale-gateway/";
