import { CityItem } from "pages/WorldDomination/WDGridPanel/CityItem";
import {
  ICitiesObj,
  ICity,
  IRow,
} from "pages/WorldDomination/WDGridPanel/WDGrid";
import * as s from "pages/WorldDomination/WDGridPanel/WDGrid.styled";
import React, { useCallback } from "react";
import {
  actions,
  selectLastTurnEnded,
} from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import { numberWithCommas } from "utils/numberWithComas";

export const WDGridRow = React.memo(function WDGridRow({
  data: { clanName, totalPoints, vaultedPoints, cities, clanId },
  num,
  myClanName,
  citiesFilled,
}: {
  data: IRow;
  num: number;
  myClanName: string;
  citiesFilled: ICitiesObj;
}) {
  const dispatch = useAppDispatch();
  const lastTurnEnded = useAppSelector(selectLastTurnEnded);
  const isMyClan = clanName === myClanName;
  const handleCityClick = useCallback(
    (city: ICity) => {
      if (lastTurnEnded) return;
      if (!isMyClan) dispatch(actions.setAttack({ city, clanId }));
      else {
        dispatch(actions.setBuildShieldCity(city));
      }
    },
    [lastTurnEnded, dispatch, isMyClan]
  );

  return (
    <s.Row num={num}>
      <s.Num>{num} </s.Num>
      <s.ClanName>{clanName}</s.ClanName>
      <s.TotalPoints>{numberWithCommas(totalPoints)}</s.TotalPoints>
      <s.VaultedPoints>{numberWithCommas(vaultedPoints)}</s.VaultedPoints>
      {cities.map((city) => (
        <CityItem
          city={city}
          key={city.id}
          attacksPlanned={citiesFilled[city.id].attacksPlanned}
          shields={citiesFilled[city.id].shields}
          shieldsPlanned={citiesFilled[city.id].shieldsPlanned}
          successfulNukes={citiesFilled[city.id].successfulNukes}
          failedNukes={citiesFilled[city.id].failedNukes}
          handleCityClick={handleCityClick}
        />
      ))}
    </s.Row>
  );
});
