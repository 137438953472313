import { IIndividualLBItem } from "api";
import economic from "assets/economic-point-icon-grey.png";
import military from "assets/military-point-icon-grey.png";
import total from "components/TieredLeaderboard/assets/totalWhite.png";
import { IndividualTiredItem } from "components/TieredLeaderboard/componetns/Desk.styled";
import { usePlayer } from "hooks/usePlayer";
import Tooltip from "rc-tooltip";

export const I_DeskTierPlayer = (props: { player: IIndividualLBItem }) => {
  const player = usePlayer(props.player.id);

  return (
    <IndividualTiredItem>
      {/*player*/}
      <div>
        <div>{props.player.rank}</div>
        <div>{player?.name}</div>
      </div>
      {/*  clan*/}
      <div>{player?.clan?.name}</div>
      {/*points*/}
      <div>
        <Tooltip placement="topLeft" overlay={<div>Military Points</div>}>
          <div>
            <img src={military} alt="military" />
            {props.player.militaryScore}
          </div>
        </Tooltip>
        <Tooltip placement="topLeft" overlay={<div>Economic Points</div>}>
          <div>
            <img src={economic} alt="economic" />
            {props.player.economicScore}
          </div>
        </Tooltip>
        <Tooltip placement="topLeft" overlay={<div>Total Points</div>}>
          <div>
            <img src={total} alt="total" />
            {props.player.totalScore}
          </div>
        </Tooltip>
      </div>
    </IndividualTiredItem>
  );
};
