import butterImg from "assets/butter_icon.png";
import eOrbsIcon from "components/Staking/StakeForEventPoints/assets/eOrbs.png";
import { ShardImage } from "components/Transfer/Currencies/components/ShardImage";
import { IRewardItem } from "../../../api/api.types";
import { numberWithCommas } from "../../../utils/numberWithComas";
import Box from "../../Box";
import bpIcon from "../assets/bpBig.png";
import * as s from "./Desk.styled";
import { RewardItem } from "./RewardItem";
import { RewardToken } from "./RewardToken";

export function DeskTooltipContent({
  battlePassPoints,
  butter,
  iRewardItems,
  eOrbs,
}: {
  iRewardItems: IRewardItem[];
  butter: number;
  battlePassPoints: number;
  eOrbs: number;
}) {
  return (
    <Box flexDirection="column" gap={6}>
      <s.TooltipCherarBP>
        <div>
          <img src={butterImg} alt="butter" width={30} />
          <div>
            <div>Butter</div>
            <div>{numberWithCommas(butter)}</div>
          </div>
        </div>
        <div>
          <img src={bpIcon} alt="bp" />
          <div>
            <div>Battle Points</div>
            <div>{numberWithCommas(battlePassPoints)}</div>
          </div>
        </div>
      </s.TooltipCherarBP>
      <s.TooltipCherarBP>
        <div>
          <img src={eOrbsIcon} height={24} alt="eo" />
          <div>
            <div>Enriched Orbs</div>
            <div>{numberWithCommas(eOrbs)}</div>
          </div>
        </div>
      </s.TooltipCherarBP>

      <s.TooltipInner>
        {iRewardItems.map((item) => {
          if (item.inventory)
            return (
              <RewardItem
                item={item.inventory!}
                key={item.inventory?.itemId!}
              />
            );
          if (item.token)
            return (
              <RewardToken token={item.token!} key={item.token!.configId} />
            );
          if (item.offchain)
            return (
              <ShardImage
                key={item.offchain.account}
                account={item.offchain.account}
                amount={item.offchain.amount}
              />
            );
          else return null;
        })}
      </s.TooltipInner>
    </Box>
  );
}
