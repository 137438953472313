import { IIndividualLBItem } from "api";
import { IEvent, IRewardListEntry } from "api/api.types";
import { I_TabletTierPlayer } from "components/TieredLeaderboard/componetns/IndividualLB/I_TabletTierPlayer";
import { Place } from "components/TieredLeaderboard/componetns/Place";
import * as s from "components/TieredLeaderboard/componetns/Tablet.styled";
import { TabletRewards } from "components/TieredLeaderboard/componetns/TabletRewards";

export function I_TabletTierItem({
  players,
  event,
  item,
}: {
  players: IIndividualLBItem[];
  event: IEvent;
  item: IRewardListEntry;
}) {
  const playersFiltered = players.filter(
    (player) =>
      player.rank >= item.placeFromInclusive &&
      player.rank < item.placeToExclusive
  );
  if (playersFiltered.length === 0) return null;
  return (
    <s.Tier key={item.tier} tier={item.tier}>
      <Place event={event} place={item.tier} />
      <div className="clans">
        {playersFiltered.map((player) => (
          <I_TabletTierPlayer key={player.id} player={player} />
        ))}
      </div>
      <TabletRewards
        rewards={event.individualRewardsList[item.tier - 1].rewardsList}
      />
    </s.Tier>
  );
}
