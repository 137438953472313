import { IEvent } from "api/api.types";
import { MobTierItem } from "components/TieredLeaderboard/componetns/MobTierItem";

export function MobTable({
  clans,
  event,
}: {
  clans: IGetClanLeaderboard[];
  event: IEvent;
}) {
  return (
    <div>
      {event.rewardsList.map((item) => (
        <MobTierItem clans={clans} item={item} event={event} key={item.tier} />
      ))}
    </div>
  );
}

