import styled from "styled-components/macro";

export const NukesPlanned = styled.div<{ count: number }>`
  position: absolute;
  right: 6px;
  bottom: 6px;

  font-weight: 400;
  font-size: 14px;
  color: #ec2e00;
  &:after {
    content: "+${(props) => props.count}";
  }
`;
export const PercentsLost = styled.div<{ count: number }>`
  font-weight: 400;
  font-size: 14px;
  color: #ec2e00;
  &:after {
    content: "${(props) => props.count}% lost";
  }
`;