import currentPlace from "assets/currentPlace.png";
import economicPoints from "assets/economicPoints.png";
import Box from "components/Box";
import Img from "components/Img";
import { Bonuses } from "components/Staking/StakeForEventPoints/InfoBloc/Bonuses";
import { Contribution } from "components/Staking/StakeForEventPoints/InfoBloc/Contribution";
import { PointsInfo } from "components/Staking/StakeForEventPoints/InfoBloc/PointsInfo";
import { RarityBonuses } from "components/Staking/StakeForEventPoints/InfoBloc/RarityBonuses";
import * as s from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import Text from "components/Text/Text";
import {
  selectEventStatus,
  selectStakedItemsCount,
} from "redux/slices/eventPointsSlice";
import { useAppSelector } from "store";
import { numberWithCommas } from "utils/numberWithComas";
import { StakeEPInfoBlock1 } from "./StakeEPInfoBlock1";
import { Timer } from "./Timer";

export function InfoBlock() {
  const stakedItemsCount = useAppSelector(selectStakedItemsCount);
  const eventStatus = useAppSelector(selectEventStatus);

  if (!eventStatus) return null;
  return (
    <s.HeadWrapper>
      <s.HeadTop>
        <div className="title">Stake for Event Points</div>
        {eventStatus.status === "current" && stakedItemsCount ? (
          <Timer />
        ) : (
          <PointsInfo />
        )}
      </s.HeadTop>
      <s.Blocks>
        <StakeEPInfoBlock1 />
        <Bonuses />
        <RarityBonuses />
      </s.Blocks>
      <Box gap={20} mt={40}>
        <ClanPerformance />
        <Contribution />
      </Box>
    </s.HeadWrapper>
  );
}

function ClanPerformance() {
  const clanEventInfo = useAppSelector(state=>state.eventPointsPage.clanEventInfo)
  return (
    <Box column gap={10} w="30%">
      <Text yellow s={20} w={400} uppercase>
        Clan Performance:
      </Text>
      <Box gap={10}>
        <s.ClanPerformanceItem>
          <Img src={currentPlace} h={45} mb={23} />
          <Text yellow s={22} w={400} mb={2}>
            {numberWithCommas(clanEventInfo?.clanScore?.position)}
          </Text>
          <Text grey s={14} w={350}>
            Current Place
          </Text>
        </s.ClanPerformanceItem>
        <s.ClanPerformanceItem>
          <Img src={economicPoints} h={45} mb={23} />
          <Text yellow s={22} w={400} mb={2}>
            {numberWithCommas(clanEventInfo?.clanScore?.economicPoints)}
          </Text>
          <Text grey s={14} w={350} center>
            Clan Economic <br />
            Points
          </Text>
        </s.ClanPerformanceItem>
      </Box>
    </Box>

  )
}
