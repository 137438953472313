import { useClickOutside } from "hooks/useClickOutside";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import img from "../assets/submenu-selector.png";
import * as s from "./sumbenu.styled";

export function SubmenuMobile() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const intraClanLB = pathname.includes("intraClanLB");
  const tieredLB = pathname.includes("tieredLB");
  const transfer = pathname.includes("transferHistory");
  const worldDomination = pathname.includes("worldDomination");

  const ref = useClickOutside(open, () => {
    setOpen(false);
  });

  return (
    <s.Wrapper ref={ref}>
      <s.MobileSubmenuClickable onClick={() => setOpen(!open)}>
        Clan War Tiered Leaderboard
        <img src={img} alt="submenu" />
      </s.MobileSubmenuClickable>
      <s.ModalWrapper open={open}>
        <s.MenuitemMob onClick={() => navigate("/clanWars/intraClanLB")} active={intraClanLB}>
          Intra-Clan Leaderboard
        </s.MenuitemMob>
        <s.MenuitemMob onClick={() => navigate("/clanWars/tieredLB/clan")} active={tieredLB}>
          Clan War Tiered Leaderboard
        </s.MenuitemMob>
        <s.MenuitemMob onClick={() => navigate("/clanWars/transferHistory")} active={transfer}>
          Clan Treasury
        </s.MenuitemMob>
        <s.MenuitemMob onClick={() => navigate("/clanWars/worldDomination")} active={worldDomination}>
          Treasury Transfer History
        </s.MenuitemMob>
      </s.ModalWrapper>
    </s.Wrapper>
  );
}
