import { getAccounts, sendTransaction } from "api";
import butter from "assets/butter_icon.png";
import mobButter from "assets/mobButter.png";
import { Gap } from "components/Gap";
import { QuantityCounter } from "components/QuantityCounter/quantityCounter";
import { Spinner } from "components/Spinner";
import { TokenImage } from "components/TieredLeaderboard/componetns/TokenImage";
import { ShardImage } from "components/Transfer/Currencies/components/ShardImage";
import {
  NameField,
  StyledLogo,
} from "components/Transfer/Currencies/Currencies.style";
import { ShardName } from "components/Transfer/Currencies/ShardName";
import {
  Border,
  ButtonContainer,
  ConfirmBtn,
  Content,
  GradientContainer,
  MoadlQuantityItem,
  ModalCloseBtn,
  modalStyles,
  RecipientContainer,
  RecipientWrapper,
  RejectBtn,
  SpinnerContainer,
  StyledQuantity,
  StyleSuccesIcon,
  SubTitle,
  Title,
  WeaponContainer,
  WeaponItem,
  Wrapper,
} from "components/Transfer/TransferModal/transferModal.style";
import { useIsMobile } from "hooks/useMediaQuery";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { IgameData, IModalProps } from "types/transferTypes";
import { v4 as uuidv4 } from "uuid";

export const TransferModal = ({
  open,
  setOpen,
  recipient,
  currentItem,
  setGdImage,
  setUpdate,
  setGdname,
}: IModalProps) => {
  const [isSuccess, setSuccess] = useState(false);
  const isMobile = useIsMobile();
  const [amount, setAmount] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [currentUser, setUser] = useState([] as any);

  let reqId = uuidv4();
  useEffect(() => {
    recipient &&
      getAccounts([recipient?.id]).then((res) => {
        setUser(res[0]);
      });
  }, [recipient]);

  const handleClose = () => {
    setSuccess(false);
    setOpen(false);
    setAmount(1);
  };

  const handleSubmit = () => {
    setLoading(true);
    sendTransaction(amount, recipient?.id, currentItem, reqId)
      .then(() => {
        reqId = uuidv4();
        setSuccess(true);
        setUpdate(uuidv4());
        setLoading(false);
      })
      .catch((error: Error) =>
        console.log(
          error.message === "duplicate",
          "операция уже совершена",
          error
        )
      );
  };

  const setStartAmount = () => {
    if ((currentItem as IgameData)?.item?.itemId) return 1;
    else return (currentItem as IgameData)?.total || (currentItem as number);
  };
  if (currentItem === null) {
    setOpen(false);
    return null;
  }
  return (
    <Modal
      style={modalStyles}
      isOpen={open}
      shouldCloseOnEsc
      preventScroll
      onRequestClose={handleClose}
    >
      <Wrapper>
        <Content>
          <Title isSuccess={isSuccess}>
            {isSuccess && <StyleSuccesIcon />}
            Transfer Confirmation
          </Title>
          <ModalCloseBtn onClick={handleClose} />
          {!isSuccess && (
            <SubTitle>
              Please select the quantity of the item that you want to send
            </SubTitle>
          )}
          <RecipientContainer>
            Recipient:
            <RecipientWrapper>
              {recipient?.name}
              <div style={{ opacity: "0.7" }}>#{currentUser?.suffix}</div>
            </RecipientWrapper>
          </RecipientContainer>
          {!isMobile && (
            <GradientContainer isSuccess={isSuccess}></GradientContainer>
          )}
          {!isMobile && <Border />}
          <WeaponContainer>
            <WeaponItem>
              {typeof currentItem === "number" ? (
                <>
                  <Gap w={10} />
                  <StyledLogo src={isMobile ? mobButter : butter} alt="BIcon" />
                </>
              ) : "fund" in currentItem ? (
                <ShardImage account={currentItem.fund.account} />
              ) : (
                <TokenImage info={currentItem} setItemImage={setGdImage} />
              )}

              <div>
                <NameField>
                  {typeof currentItem !== "number" && "fund" in currentItem
                    ? <ShardName account={currentItem.fund.account}/>
                    : setGdname(currentItem as IgameData)}
                </NameField>
              </div>
            </WeaponItem>
            <MoadlQuantityItem>
              Quantity:
              {isSuccess ? (
                <StyledQuantity>{amount}</StyledQuantity>
              ) : (
                <QuantityCounter
                  amount={setStartAmount()}
                  setAmount={setAmount}
                />
              )}
            </MoadlQuantityItem>
          </WeaponContainer>
        </Content>
        {!isSuccess && (
          <ButtonContainer>
            <RejectBtn onClick={handleClose}>Cancel</RejectBtn>
            <ConfirmBtn onClick={() => setStartAmount() && handleSubmit()}>
              Confirm & Send
            </ConfirmBtn>
          </ButtonContainer>
        )}
        {isLoading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </Wrapper>
    </Modal>
  );
};
