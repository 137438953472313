import mintableGoldIcon from "components/Staking/StakeForEventPoints/assets/mintableGoldIcon.png";
import stakingBoostIcon from "components/Staking/StakeForEventPoints/assets/stakingBoostIcon.png";
import category from "components/TieredLeaderboard/assets/category.png";
import styled, { css } from "styled-components/macro";
import { mapRarityToColor } from "utils/mappers/mapRarityToColor";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 65px 150px 170px 170px 170px 150px 155px;
  column-gap: 5px;
  row-gap: 15px;
  padding: 30px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  margin: 20px 0;

  & .title {
    grid-column: span 7;
    font-weight: 500;
    font-size: 35px;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 5px;
  }

  & .header {
    font-weight: 350;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.7;
    margin-bottom: -9px;
  }
`;

export const GridBlock = styled.div`
  background: #1f1f1f;
  border-radius: 4px;
  height: 220px;
`;

export const EmptyImg = styled.div`
  background: #383838;
  border: 1px solid #6f6f6f;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;

  &:before {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "Empty";
    font-weight: 350;
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.3);
  }
`;

export const ItemBlock = styled(GridBlock)`
  padding: 9px;
  display: flex;
  flex-direction: column;

  & .processing {
    background: #383838;
    border: 1px solid #6f6f6f;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 7px;

    &:before {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      content: "Staking is Processing...";
      font-weight: 350;
      font-size: 16px;
      text-align: center;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  & .unstaking {
    background: #383838;
    border: 1px solid #6f6f6f;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 7px;

    &:before {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      content: "Unstaking is Processing...";
      font-weight: 350;
      font-size: 16px;
      text-align: center;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  & .processing_text_staking {
    flex: 0 0 43px;
    display: flex;
    justify-content: center;

    &:before {
      flex: 0 0 43px;
      display: flex;
      align-items: center;
      content: "Processing...";
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  & .processing_text_unstaking {
    flex: 0 0 43px;
    display: flex;
    justify-content: center;

    &:before {
      flex: 0 0 43px;
      display: flex;
      align-items: center;
      content: "Unstaking...";
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

export const Set = styled(GridBlock)<{ val: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "${({ val }) => val}";
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;
  }
`;

export const PointsRateBlock = styled(GridBlock)<{ val: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "${({ val }) => val}";
    font-weight: 500;
    font-size: 26px;
    color: #ffb60c;
  }
`;

export const Unlocked = styled(GridBlock)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  & > div:nth-child(1) {
    font-weight: 350;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }

  & > div:nth-child(2) {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffb60c;
  }

  & > div.remaining {
    align-items: center;
    background: #303030;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    gap: 10px;
    padding-left: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const Locked = styled(GridBlock)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;

  & > img {
    width: 22px;
    margin-bottom: 11px;
  }

  & > .text {
    font-weight: 350;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }

  & > .price {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffb60c;
    margin-bottom: 12px;
  }
`;

const Button = styled.button`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  height: 43px;
  cursor: pointer;
`;

export const GreenButton = styled(Button)<{
  h?: number;
  w?: number;
  disabled?: boolean;
  $loading?: boolean;
  center?: boolean;
}>`
  ${({ center }) => center && "margin: 0 auto;"}
  white-space: break-spaces;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(138, 199, 0, 0.4);
  border: 1px solid #8ac700;
  box-shadow: 0px 10px 22px rgba(138, 199, 0, 0.4),
    0px 5px 10px 2px rgba(34, 60, 80, 0.3) inset;
  ${({ h }) => h && "height:" + h + "px"};
  ${({ w }) => w && "width:" + w + "px"};
  &:hover:not(:active) {
    background: #8ac700;
    box-shadow: 0px 10px 22px rgba(138, 199, 0, 0.4);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: rgba(61, 61, 61, 0.4);
      border: 1px solid #464646;
      box-shadow: none;
      &:hover:not(:active) {
        box-shadow: none;
        background: rgba(61, 61, 61, 0.4);
        cursor: not-allowed;
      }
    `}
  ${({ $loading }) =>
          $loading &&
    css`
      &:hover:not(:active) {
        background: rgba(138, 199, 0, 0.4);
        cursor: initial;
      }
    `}
  ${({ $loading, disabled }) =>
    (!$loading && !disabled) &&
    css`
      &:active {
        box-shadow: 0px 10px 22px rgba(138, 199, 0, 0.4),
          0px 5px 16px 5px rgba(0, 0, 0, 0.3) inset;
      }
    `}
`;

export const RedButton = styled(Button)<{
  h?: number;
  w?: number;
  disabled?: boolean;
  $loading?: boolean;
  center?:boolean;
}>`
  ${({ center }) => center && "margin: 0 auto;"}
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 0, 0, 0.4);
  border: 1px solid #ff0000;
  box-shadow: 0px 10px 22px rgba(255, 0, 0, 0.4),
    0px 5px 10px 2px rgba(34, 60, 80, 0.3) inset;
  ${({ h }) => h && "height:" + h + "px"};
  ${({ w }) => w && "width:" + w + "px"};
  &:hover:not(:active) {
    background: #ff0000;
    box-shadow: 0px 10px 22px rgba(255, 0, 0, 0.4);
  }

  ${({ disabled }) =>
  disabled &&
  css`
      background: rgba(61, 61, 61, 0.4);
      border: 1px solid #464646;
      box-shadow: none;
      &:hover:not(:active) {
        box-shadow: none;
        background: rgba(61, 61, 61, 0.4);
        cursor: not-allowed;
      }
    `}
  ${({ $loading }) =>
  $loading &&
  css`
      &:hover:not(:active) {
        background: rgba(255, 0, 0, 0.4);
        cursor: initial;
      }
    `}
  ${({ $loading, disabled }) =>
  (!$loading && !disabled) &&
  css`
      &:active {
        box-shadow: 0px 10px 22px rgba(255, 0, 0, 0.4),
          0px 5px 16px 5px rgba(0, 0, 0, 0.3) inset;
      }
    `}
`;


export const YellowButton = styled(Button)`
  background: rgba(255, 182, 12, 0.4);
  border: 1px solid #ffb60c;
  box-shadow: 0px 4px 10px rgba(255, 182, 12, 0.2);

  &:hover {
    background: #ffb60c;
  }
`;



export const ItemWrap = styled.div<{
  rarity: number;
  pointer?: boolean;
  w?: number;
  h?: number;
}>`
  position: relative;
  width: ${({ w }) => (w ? `${w}px` : "100%")};
  height: ${({ h }) => (h ? `${h}px` : "auto")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ rarity }) => mapRarityToColor(rarity)};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
  padding: 4px;
  margin-bottom: 7px;

  & > img {
    width: 100%;
    clip-path: polygon(7% 0, 100% 0, 100% 100%, 0 100%, 0 7%);
  }

  & > div.upsBlock {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 5px;
  }
`;

export const LevelBage = styled.div<{ level: number }>`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 34px;
  background: url(${category});
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  &:after {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #ffb60c;
    content: "${({ level }) => level}";
  }
`;

export const Mintable = styled.img.attrs({
  src: mintableGoldIcon,
})`
  height: 23px;
`;

export const StakingBoost = styled.img.attrs({
  src: stakingBoostIcon,
})`
  height: 23px;
`;

export const ItemBlockLocked = styled(GridBlock)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 350;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.5);

  &:before {
    content: "unlock set to stake items here";
  }
`;

export const PointsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #1f1f1f;
  border-radius: 4px;
  text-align: center;
`;
