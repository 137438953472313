// import * as Sentry from "@sentry/react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  IItemsAttributes,
  staking_getSlotId,
  staking_setSlotStatus,
} from "api";
import { SimpleModal } from "components/CommonMessageModal/SimpleModal";
import { SimpleModalSpinner } from "components/CommonMessageModal/SimpleModalSpinner";
import { Spinner } from "components/Spinner";
import { CharListItem } from "components/Staking/StakeForEventPoints/StakePage/CharListItem";
import * as s from "components/Staking/StakeForEventPoints/StakePage/StakeItemPage.styled";
import Text from "components/Text/Text";
import { WalletButton } from "components/WalletButton";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { selectAllItems } from "redux/slices/collectionSlice";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { fetchItemMetadata } from "redux/slices/stakePageSlice";
import { getSolBalance } from "stakeItemApi/getSolBalance";
import { stakeItemSolana } from "stakeItemApi/stakeItemApi";
import { useAppDispatch, useAppSelector } from "store";
import { IGeneratedItem } from "types/stakingTypes";
import { greenLog } from "utils/advancedLog";
import { mapMetadataToImg } from "utils/mappers/mapMetadataToImg/mapMetadataToImg";
import { mapRarityStrToNum } from "utils/mappers/mapRarityStrToNum";
import { mapRarityToColor } from "utils/mappers/mapRarityToColor";
import { mapRarityToIcon } from "utils/mappers/mapRarityToIcon/mapRarityToIcon";
import Box from "../../../Box";
import { ItemView } from "../Grid/ItemView";
import { StakeButton } from "../Grid/StakeButton";
import staking from "./assets/staking.png";
import { Info } from "./Info";

require("@solana/wallet-adapter-react-ui/styles.css");

type IStatusText = "Idle" | "Staking...";

export function RightSideContent({
  item,
  itemsAttributes,
}: {
  item: IGeneratedItem;
  itemsAttributes: IItemsAttributes | undefined;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const wallet = useWallet();

  const { set: setId, slot: slotNumber } = useParams()!;
  const [status, setStatus] = useState<IStatusText>("Idle");
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { totalItems } = useAppSelector(selectAllItems);
  const tokenMetadata = useAppSelector(
    (state) => state.stakePage.selectedItemMetadata
  );

  useEffect(() => {
    if (item.id) dispatch(fetchItemMetadata(item.id));
  }, [item?.id]);

  if (!setId || !slotNumber) return null;

  // Запрос на стейкинг
  const handleStakeItem = async () => {
    if (status === "Staking...") return;
    console.log(`stakingParams: `, { item, setId, slotNumber });
    setStatus("Staking...");
    let slotId_: string = "";
    let tx: string = "";
    try {
      const balance = await getSolBalance(wallet);
      if (balance < 0.02) throw "balance";
      const { slotId, id } = await staking_getSlotId(
        item.id,
        setId,
        +slotNumber
      );
      slotId_ = slotId;
      greenLog(slotId_, "slotId_");

      setShowSpinner(true);
      tx = await stakeItemSolana(wallet, item.generated.tokenMint, slotId);
      await staking_setSlotStatus(id, tx, "staking");
      setShowSpinner(false);
      setShowModal(true);
    } catch (e: any) {
      setShowSpinner(false);
      if (e?.error?.message === "User rejected the request.") {
        console.log("user rejected request");
        dispatch(
          addErrorMessage({
            title: "Staking canceled",
            text: "The transaction was canceled by user",
          })
        );
      } else if (e === "balance") {
        console.log("insufficient balance");
        dispatch(
          addErrorMessage({
            text: "You need SOL balance > 0.02 to stake items",
          })
        );
      } else {
        greenLog(wallet?.publicKey?.toString(), "wallet");
        greenLog(item.generated.tokenMint, "item.generated.tokenMint");
        greenLog(slotId_, "slotId");
        greenLog(tx, "tx");

        // Sentry.withScope((scope)=> {
        //   // scope.clear();
        //   scope.setLevel("error");
        //   scope.setContext('stakingInfo',{
        //     wallet:wallet?.publicKey?.toString(),
        //     tokenMint:item.generated.tokenMint,
        //     slotId:slotId_,
        //     tx
        //   })
        //   // The exception has the event level set by the scope (info).
        //   Sentry.captureException(new Error("Staking error"));
        // });
        // Sentry.captureMessage("Staking error", scope => {
        //   // scope.clear();
        //   scope.setContext('stakingInfo',{
        //     wallet:wallet?.publicKey?.toString(),
        //     token:item.generated.tokenMint,
        //     slotId,
        //     tx
        //   })
        //   return scope;
        // });
        dispatch(
          addErrorMessage({
            text: "An error occurred during the staking. Please, reload page and try again.",
          })
        );
      }
    }
    setStatus("Idle");
  };

  if (!wallet.connected) return <WalletButton />;

  const curentItem = totalItems.filter(
    (mainItem) => mainItem.id === item.id
  )[0];
  
  const characteristics = tokenMetadata
    ? _.cloneDeep(tokenMetadata.attrsList)
    : [];
  
  if(!itemsAttributes) return null;
  const rarityNum = mapRarityStrToNum(itemsAttributes[item.id]["Rarity"].value.string);
  return (
    <>
      {item && (
        <>
          <div className="block1">
            <ItemView
              src={itemsAttributes[item.id]["Image Thumbnail URL"].value.string}
              rarity={rarityNum}
            />
            <Info />
          </div>
          <Box fullWidth gap={7} h={40} mb={7} mt={15}>
            <s.Status>
              <img src={staking} />
              <Text w={700} s={16} mr={4}>
                Status:
              </Text>
              <Text w={700} s={16} grey>
                {status}
              </Text>
            </s.Status>
            <s.Rarity rarity={rarityNum}>
              <img src={mapRarityToIcon(rarityNum)} />
              <Text w={700} s={16} color={mapRarityToColor(rarityNum)}>
                {itemsAttributes[item.id]["Rarity"].value.string}
              </Text>
            </s.Rarity>
          </Box>
          <StakeButton onClick={handleStakeItem} />
          <s.CharacteristicListScrollable>
            <s.CharacteristicListContent>
              {characteristics.length > 0 ? (
                characteristics
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item, idx) => {
                    // const
                    const name = item.name.replace(/\s/g, "").toLowerCase();
                    const stringvalue = item.stringvalue
                      .replace(/\s/g, "")
                      .toLowerCase();
                    const icon =
                      mapMetadataToImg[stringvalue] || mapMetadataToImg[name];
                    return (
                      <CharListItem
                        key={idx}
                        icon={icon}
                        label={item.name}
                        text={item.stringvalue}
                      />
                    );
                  })
              ) : (
                <Spinner />
              )}
            </s.CharacteristicListContent>
          </s.CharacteristicListScrollable>
        </>
      )}
      {showModal && (
        <SimpleModal
          text="Item successfully staked"
          onClose={() => navigate(-1)}
        />
      )}
      <SimpleModalSpinner show={showSpinner} />
    </>
  );
}
