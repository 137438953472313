import shard from "assets/shard.png";
import styled from "styled-components/macro";
import { mapRarityToColor } from "utils/mappers/mapRarityToColor";

export const ShardImage = styled.div<{ rarity: number; w?: number }>`
  position: relative;
  width: ${({ w }) => w ?? 90}px;
  height: ${({ w }) => w ?? 90}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ rarity }) => mapRarityToColor(rarity)};

  & > img.token {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    width: 13px;
    height: 13px;
  }
`;

export const ItemImg = styled.img`
  width: 93%;
  height: 93%;
  clip-path: polygon(10% 0, 70% 0, 100% 30%, 100% 100%, 0 100%, 0 10%);
`;

export const ShardIcon = styled.img.attrs({
  src:shard
})`
  position: absolute;
  top:5px;
  right:5px;
  width: 12px;
  height: 12px;
`;
