import {Connection} from "@solana/web3.js";
import {endpoint} from "config";

// connection
const connection = new Connection(endpoint);

export async function getSolBalance(wallet:any) {
  let balance =  await connection.getBalance(wallet.publicKey);
  return balance/10**9
}
