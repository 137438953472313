import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import { SubmenuMobile } from "./SubmenuMobile";
import * as s from "./sumbenu.styled";

export function Submenu() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const pathname = useLocation().pathname;

  const navigate = useNavigate();
  return (
    <>
      {isMobile ? (
        <SubmenuMobile />
      ) : (
        <s.Wrap>
          <s.Menuitem
            onClick={() => navigate("/clanWars/intraClanLB")}
            active={pathname.includes("/clanWars/intraClanLB")}
          >
            Intra-Clan Leaderboard
          </s.Menuitem>
          <s.Menuitem
            onClick={() => navigate("/clanWars/tieredLB/clan")}
            active={pathname.includes("/clanWars/tieredLB")}
          >
            Clan War Tiered Leaderboard
          </s.Menuitem>
          <s.Menuitem
            onClick={() => navigate("/clanWars/transferHistory")}
            active={pathname.includes("/clanWars/transferHistory")}
          >
            Treasury Transfer History
          </s.Menuitem>
          <s.Menuitem
            onClick={() => navigate("/clanWars/worldDomination")}
            active={pathname.includes("/clanWars/worldDomination")}
          >
            World Domination
          </s.Menuitem>
        </s.Wrap>
      )}
    </>
  );
}
