import { IIndividualLBItem } from "api";
import { IEvent } from "api/api.types";
import * as s from "components/TieredLeaderboard/componetns/Desk.styled";
import { I_DeskTierItem } from "components/TieredLeaderboard/componetns/IndividualLB/I_DeskTierItem";

export function I_DeskTableIndividual({
  players,
  event,
}: {
  players: IIndividualLBItem[];
  event: IEvent;
}) {
  return (
    <div>
      <s.IndividualHeader>
        <div>TIER</div>
        <div>PLAYER</div>
        <div>CLAN</div>
        <div>POINTS</div>
        <div>REWARDS</div>
      </s.IndividualHeader>
      {event.individualRewardsList.map((item) => (
        <I_DeskTierItem
          players={players}
          item={item}
          event={event}
          key={item.tier}
        />
      ))}
    </div>
  );
}
