// import * as Sentry from "@sentry/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { staking_setSlotStatus } from "api";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { GridCtx } from "components/Staking/StakeForEventPoints/Grid/Grid";
import {
  GreenButton,
  RedButton,
} from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import { modalStyles } from "components/Staking/StakeForEventPoints/Grid/UnlockModal/UnlockModal";
import { WalletButton } from "components/WalletButton";
import React, { useContext } from "react";
import Modal from "react-modal";
import { fetchSlots } from "redux/slices/eventPointsSlice";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { unStakeItem } from "stakeItemApi/stakeItemApi";
import { useAppDispatch } from "store";
import { ItemView } from "../ItemView";
import * as s from "./UnstakeModal.styled";

type Itx = string | { message: string } | undefined;

export function Unstake() {
  const wallet = useWallet();
  const dispatch = useAppDispatch();
  const [context, setField] = useContext(GridCtx);
  const handleClose = () => setField("showUnstakeModal", false);
  // Тут запрос на анстейк
  const item = context.selectedUnstakeItem!;
  // const set = context.selectedUnstakeSet!;
  // const slot = context.selectedUnstakeSlot!;
  const unstakeId = context.selectedUnstakeId!;
  const slotId = context.selectedUnstakeSlotId!;
  // Запрос на анстейкинг
  const handleUnstake = async () => {
    console.log(`item: `, item);
  
    setField("selectedUnstakeStatus", "pending");
    let tx:Itx
    try {

      tx = (await unStakeItem(wallet, slotId)) as Itx;
      console.log("transaction", tx);
      if (!tx) throw "empty transaction";
      
      if (typeof tx === "object" && "message" in tx) {
        // Temporal fix for unstaking
        console.log("tx.message", tx.message);
        if (tx.message.includes("Account does not exist"))
          tx = (await unStakeItem(wallet, 0)) as Itx;
          // tx = undefined
        if (!tx) throw "empty transaction";
        if(typeof tx !== "string") throw "error";
        await staking_setSlotStatus(unstakeId, tx, "unstaking");
        dispatch(fetchSlots());
      } else {
        await staking_setSlotStatus(unstakeId, tx, "unstaking");
        dispatch(fetchSlots());
      }
    } catch (e: any) {
      if (e?.error?.message === "User rejected the request.") {
        dispatch(
          addErrorMessage({
            title: "Staking canceled",
            text: "The transaction was canceled by user",
          })
        );
      } else {
        dispatch(
          addErrorMessage({
            text: "An error occurred during unstaking. Please, reload page and try again.",
          })
        );
        // Sentry.withScope((scope)=> {
        //   scope.setLevel("error");
        //   scope.setContext('unstakingInfo',{
        //     wallet:wallet?.publicKey?.toString(),
        //     tokenMint:item.generated.tokenMint,
        //     slotId,
        //     tx
        //   })
        //   // The exception has the event level set by the scope (info).
        //   Sentry.captureException(new Error("Unstaking error"));
        // });
      }
    } finally {
      setField("selectedUnstakeStatus", "idle");
      setField("showUnstakeModal", false);
    }
  };

  return (
    <Modal
      isOpen={context.showUnstakeModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      preventScroll
      onRequestClose={handleClose}
      style={modalStyles}
    >
      {wallet.connected ? (
        <s.Unstake>
          <img src={closeIcon} onClick={handleClose} />
          <div className="title">Unstake confirmation</div>
          <div className="titleHelp">
            Are you sure you want to unstake this item?
          </div>
          <ItemView
            className="item"
            currentItem={context.selectedUnstakeItem as any}
          />
          <div className="buttons">
            <RedButton onClick={handleClose}>Cancel</RedButton>
            <GreenButton onClick={handleUnstake}>Confirm</GreenButton>
          </div>
        </s.Unstake>
      ) : (
        <WalletButton />
      )}
    </Modal>
  );
}
