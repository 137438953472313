import styled, { css } from "styled-components/macro";

export const Wrap = styled.div`
  @media (max-width: 760px) {
    width: 100%;
    margin-bottom: 16px;
    column-gap: 6px;
  }
  column-gap: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: calc(50% - 5px);
`;

export const Menuitem = styled.div<{ active: boolean }>`
  height: 52px;
  display: flex;
  flex: 1 1 200px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  padding: 0 30px;
  background: rgba(0, 0, 0, 0.9);
  @media (max-width: 760px) {
    width: 50%;
    padding: 10px;
  }
  ${({ active }) =>
    active &&
    css`
      color: #ffb60c;
      border: 1px solid #ffb60c;
      background: radial-gradient(
          56.23% 86% at 52.14% 100%,
          rgba(255, 128, 12, 0.2) 0%,
          rgba(255, 128, 12, 0) 100%
        ),
        rgba(0, 0, 0, 0.7);
    `}
`;

export const MobileSubmenuClickable = styled.div`
  cursor: pointer;
  width: 100%;
  height: 52px;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 22px 0 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;

  & > img {
    width: 10px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ModalWrapper = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: absolute;
  z-index: 100;
  top: 45px;
  right: 0;
  left: 0;
  background: black;
  padding: 20px;
  flex-direction: column;
  row-gap: 30px;
`;

export const MenuitemMob = styled.div<{ active: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      color: #ffb60c;
    `}
`;
