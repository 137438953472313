import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import StakeItemPage from "components/Staking/StakeForEventPoints/StakePage/StakeItemPage";
import { endpoint, wallets } from "config";
import StakeEventPoints from "pages/StakeForEventPointsPage";
import { StakingHomePage } from "pages/StakingHomePage";
import React from "react";
import { Route, Routes } from "react-router-dom";

export function StakingWrapper() {
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Routes>
            {/*TOP Route is /staking/* */}
            <Route path="/" element={<StakingHomePage />} />
            <Route
              path="eventPoints"
              element={
                <ProtectedRoute redirectTo="/staking">
                  <StakeEventPoints />
                </ProtectedRoute>
              }
            />
            <Route
              path="eventPoints/stake/:set/:slot"
              element={
                <ProtectedRoute redirectTo="/staking">
                  <StakeItemPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
