import {
  getAccounts,
  getClanMembers,
  getMiniTableItems,
  getTransferHistory,
  inventory_getItemMany,
} from "api";
import {
  IClanMembersResponse,
  IItemsNormalized,
  MembersList,
} from "api/api.types";
import butter from "assets/butter_icon.png";
import cheddar from "assets/cheddar_icon.png";
import mockSvg from "assets/mockSvg.svg";
import enrOrbs from "assets/Orbs.svg";
import { TokenImage } from "components/TieredLeaderboard/componetns/TokenImage";
import {
  SClanIcon,
  SCupIcon,
  SPolygon,
} from "components/Transfer/ClanMembers/ClanMembers.style";
import { ShardImage } from "components/Transfer/Currencies/components/ShardImage";
import {
  ColoredAmount,
  ContainerBlock,
  ContainerHeader,
  SContainerItem,
  SGreenTriangle,
  SHeaderItem,
  SRedTriangle,
  StatusText,
  SubtitleWrapper,
  TimeText,
} from "components/Transfer/TransferHistory/TransferHistory.style";
import { MobileTransferTable } from "components/Transfer/TransferHistory/TransferHistoryMobile";
import { useIsMobile } from "hooks/useMediaQuery";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  IHistory,
  IHistoryItem,
  IInfo,
  IminiItem,
  IUser,
} from "types/transferTypes";
import { formatToMounthDay, getUTCTime } from "utils/dateFormater";
import { v4 as uuidv4 } from "uuid";

type HistoryProps = {
  clanName: string;
};


export const TransferHistoy = ({ clanName }: HistoryProps) =>{
  const isMobile = useIsMobile();
  const [history, setHistory] = useState<IHistoryItem[]>([])
  const [gameImages, setImages] = useState<IItemsNormalized>({})
  const [nextPage, setPage] = useState('')
  const [miniTables, setMini] = useState<IminiItem[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [clanMembers, setClanMembers] = useState<MembersList[]>([])
  const url = "https://assets-cdn.miniroyale.io/";

  const loadMore = (nextPage: string) => {
      getTransferHistory(nextPage).then((data: IHistory) => {
        setHistory((prev: IHistoryItem[]) => [...prev, ...data.operationsList])
        setPage(data.nextPage)
          const ids = data?.operationsList.map(({ item }: IHistoryItem) => item?.info?.gamedataItem?.itemId).filter((item: string) => item)
          inventory_getItemMany(ids).then((data: IItemsNormalized) => {
            setImages((prev: IItemsNormalized) => ({...prev, ...data}))})

          const miniIds = data.operationsList?.map(({ item }: IHistoryItem) => ({id: item?.info?.mintableToken?.configId, lvl: item?.info?.mintableToken?.level}))
          .filter((item: {id: string, lvl: number}) => item.id)
          getMiniTableItems(miniIds).then((data: IminiItem[]) => setMini((prev: IminiItem[]) => [...prev, ...data]))
    
          const sentByIds = data.operationsList?.map((item: IHistoryItem) => item?.by?.userId)
          const recipientIds = data.operationsList?.map((item: IHistoryItem) => item?.recipient?.userId)
          const uniqueSendByIds = [...new Set([...sentByIds, ...recipientIds])]
          getAccounts(uniqueSendByIds).then((res) => setUsers((prev: any) => [...prev, ...res]))
        })
  }

  useEffect(() => {
    loadMore('')
    getClanMembers().then((data: IClanMembersResponse) => setClanMembers(data?.[0]?.membersList))
  }, [])

  const getUserName = (id: string, type?: string) => {
    if( type === 'CLAN_EVENT_TIER_REWARD' || type === 'TREASURY_DONATE') return `${clanName}`;
    else if(type === 'CLAN_EVENT_START_RAID') return 'Clan wars Raid';
    else return id ? users.find((item: IUser) => item?.id === id)?.name : ''
  }

  const getUserSuffix = (id: string) => {
    return id ? `#${users.find((item: IUser) => item?.id === id)?.suffix}` :''
  }

  const getRole = (userId: string) => {
    const res = (clanMembers as any)?.find((user: any) => user?.playerId?.value === userId)?.role
    
    if(res === 1) return "Member"
    if(res === 2) return "Leader"
    if(res === 3) return "Officer"
    else return ""
  }

  const setItemImage = (info: IInfo): any => {
    const gameItemId = info?.gamedataItem?.itemId
    const miniId = info?.mintableToken?.configId
    if (info?.butter) return butter
    else if(info?.enrichedOrb) return enrOrbs
    else if(info?.enrichedOrb) return cheddar
    else if(miniId) {

      const result = {
        src: miniTables?.filter((item: IminiItem) => miniId === item.id)?.[0]?.asset?.iconUrl,
        rarity: miniTables?.filter((item: IminiItem) => miniId === item.id)?.[0]?.rarity
      }

      return result
    }
    else if(gameItemId){
      const src = gameImages?.[gameItemId]?.standard?.asset?.icon

      const result = {
        src: `${url}${src}`,
        rarity: gameImages?.[gameItemId]?.rarity
      }
      return result
    }
    else return mockSvg
  }
    return (
    <div style={{width: '100%'}}>
        {!isMobile && <ContainerHeader>
            <SHeaderItem>
              TIME SENT
              <SPolygon/>
            </SHeaderItem>
            <SHeaderItem>
              ITEM
              <SPolygon/>
            </SHeaderItem>
            <SHeaderItem>
              QUANTITY SENT
              <SPolygon/>
            </SHeaderItem>
            <SHeaderItem>
              RECIPIENT NAME
              <SPolygon/>
            </SHeaderItem>
            <SHeaderItem>
              SENT BY
              <SPolygon/>
            </SHeaderItem>
          </ContainerHeader>}
          <InfiniteScroll
                dataLength={history.length} //This is important field to render the next data
                next={() => loadMore(nextPage)}
                hasMore={!!nextPage}
                loader={<h4>Loading...</h4>}
              >
            {!isMobile && history?.map(({ by, item, createdAt, recipient, type }: IHistoryItem, i: number) => { 
              const isNegative = Math.sign(item?.amount) === -1
              return <ContainerBlock key={i} isNegative={isNegative}  >
              <SContainerItem>
                {isNegative ? <SRedTriangle /> : <SGreenTriangle />}
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                {formatToMounthDay(createdAt?.seconds)}
                <TimeText>{getUTCTime(createdAt?.seconds)} UTC</TimeText>
                </div>
              </SContainerItem>
              <SContainerItem>
                {item?.info?.offchainFund
                  ? (
                  <ShardImage account={item.info.offchainFund.account} />
                ) : (
                  <TokenImage info={item?.info} setItemImage={setItemImage} />
                )}
                {/*<TokenImage info={item?.info} setItemImage={setItemImage} />*/}
              </SContainerItem>
              <SContainerItem><ColoredAmount isNegative={isNegative}>{`${!isNegative ? '+' : ''}${item?.amount}`}</ColoredAmount></SContainerItem>
              <SContainerItem>
                {type === 'CLAN_EVENT_START_RAID' && <SCupIcon color="grey" />}
                {type === 'CLAN_EVENT_TIER_REWARD' && <SClanIcon />}
                {getUserName(recipient?.userId, type)}<div style={{ opacity: '0.7'}}>
                  {getUserSuffix(recipient?.userId)}</div>
              </SContainerItem>
              <SContainerItem>
                  <SubtitleWrapper>
                    <div>
                    {getUserName(by?.userId)}<span style={{ opacity: '0.7'}}>{getUserSuffix(by?.userId)}</span>
                    </div>
                    {by?.userId && <StatusText>{getRole(by?.userId)}</StatusText>}
                  </SubtitleWrapper>
                  </SContainerItem>
            </ContainerBlock>})}
          </InfiniteScroll>
          {isMobile && history?.map((item: IHistoryItem) => <MobileTransferTable key={uuidv4()} data={item} setItemImage={setItemImage} getUserName={getUserName} getUserSuffix={getUserSuffix} getRole={getRole} />)}
    </div>
    )
}