import Box from "components/Box";
import * as s from "components/IntraClan/styled";
import { Submenu } from "components/IntraClan/Submenu/Submenu";
import { SubmenuTired } from "components/IntraClan/SubmenuTired/SubmenuTired";
import { ClanTieredLeaderboard } from "components/TieredLeaderboard/ClanTieredLeaderboard";
import { Title } from "components/TieredLeaderboard/componetns/Title";
import { IndividualTieredLeaderboard } from "components/TieredLeaderboard/IndividualTieredLeaderboard";
import { Wrapper as StWrapper } from "components/Wrapper";
import React from "react";
import { useParams } from "react-router";

export default function TieredLBPage() {
  const params = useParams();
  const type = params["type"];

  return (
    <StWrapper>
      <s.TopWrapper>
        <Submenu />
      </s.TopWrapper>
      <Box alignItems="center" mt={20} mb={25} justifyContent="space-between">
        <Title text="Tiered Leaderboard" />
        {/*<EventEndTimer />*/}
        <SubmenuTired />
      </Box>
      {type === "clan" && <ClanTieredLeaderboard />}
      {type === "individual" && <IndividualTieredLeaderboard />}
    </StWrapper>
  );
}
