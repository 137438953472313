import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  cgs_getConfigDescription,
  clanevents_addToRoundQueue,
  clanevents_getClanAssets,
  clanevents_getClanLeaderboard,
  clanevents_getEventTurn,
  clanevents_getLeaderboardViewEvent,
  clanevents_getProductPrice,
  clanevents_getRestrictions,
  clanevents_getRoundQueue,
  clanevents_getUserRestrictions,
  clanevents_removeFromRoundQueue,
  clans_info,
  IGetClanLeaderboardParams, shards_getAllRecipes,
} from "api";
import {
  EventTurnRaw, IAllRecipes,
  IClanInfo2, IConfigDescription,
  IEvent,
  IGetClanAssets,
  IRoundQueue,
  IUserRestrictions,
  QueueRestrictions,
} from "api/api.types";
import {
  IAddToQueue,
  IGetProductPrice,
  IGetProductPriceParams,
  IRemoveFromQueue,
} from "redux/rtkQuery/rtkApiTypes";
import { setEventEnded } from "redux/slices/worldDominationSlice";

type CustomErrorType = { code: string; message: string };

export const rtkApi = createApi({
  reducerPath: "rtkApi",
  baseQuery: fakeBaseQuery<CustomErrorType>(),
  endpoints: (build) => ({
    getEvent: build.query<IEvent, void>({
      async queryFn(_arg) {
        const data = await clanevents_getLeaderboardViewEvent();
        return { data };
      },
    }),
    getEventTurn: build.query<EventTurnRaw, string>({
      queryFn(eventId) {
        return clanevents_getEventTurn(eventId)
          .then((data) => ({ data }))
          .catch((e) => ({
            error: {
              code: e.code,
              message: e.message,
            },
          }));
      },
    }),
    getRoundQueue: build.query<IRoundQueue, void>({
      queryFn(_arg, { dispatch }) {
        return clanevents_getRoundQueue()
          .then((data) => ({ data }))
          .catch((e) => {
            if (e?.message === "no active event") dispatch(setEventEnded(true));
            return {
              error: {
                code: e.code,
                message: e.message,
              },
            };
          });
      },
    }),
    getClanAssets: build.query<IGetClanAssets, string>({
      queryFn(eventId) {
        return clanevents_getClanAssets(eventId)
          .then((data) => ({ data }))
          .catch((e) => ({
            error: {
              code: e.code,
              message: e.message,
            },
          }));
      },
    }),
    addToQueue: build.mutation<any, IAddToQueue>({
      async queryFn(arg, { dispatch }) {
        try {
          let result = await clanevents_addToRoundQueue(arg);
          await dispatch(
            rtkApi.endpoints.getRoundQueue.initiate(undefined, {
              forceRefetch: true,
            })
          );
          if (!result) return { error: { message: false, code: 0 } };
          return { data: true };
        } catch (e: any) {
          return {
            error: {
              code: e.code,
              message: e.message,
            },
          };
        }
      },
    }),
    removeFromQueue: build.mutation<any, IRemoveFromQueue>({
      async queryFn(arg, { dispatch }) {
        try {
          await clanevents_removeFromRoundQueue(arg);
          await dispatch(
            rtkApi.endpoints.getRoundQueue.initiate(undefined, {
              forceRefetch: true,
            })
          );
          return { data: true };
        } catch (e: any) {
          return {
            error: {
              code: e.code,
              message: e.message,
            },
          };
        }
      },
    }),
    getClanLeaderboard: build.query<
      IGetClanLeaderboard[],
      { event: IEvent; params: IGetClanLeaderboardParams }
    >({
      async queryFn(arg) {
        return clanevents_getClanLeaderboard(arg.event, arg.params)
          .then((data) => ({ data }))
          .catch((e) => ({
            error: {
              code: e.code,
              message: e.message,
            },
          }));
      },
    }),
    getProductPrice: build.query<IGetProductPrice, IGetProductPriceParams>({
      async queryFn(arg) {
        return clanevents_getProductPrice(arg)
          .then((data) => ({ data }))
          .catch((e) => ({
            error: {
              code: e.code,
              message: e.message,
            },
          }));
      },
    }),
    getUserRestrictions: build.query<IUserRestrictions, void>({
      async queryFn(_arg) {
        return clanevents_getUserRestrictions()
          .then((data) => ({ data }))
          .catch((e) => ({
            error: {
              code: e.code,
              message: e.message,
            },
          }));
      },
    }),
    getRestrictions: build.query<QueueRestrictions, void>({
      async queryFn(_arg) {
        return clanevents_getRestrictions()
          .then((data) => ({ data }))
          .catch((e) => ({
            error: {
              code: e.code,
              message: e.message,
            },
          }));
      },
    }),
    getClanInfo: build.query<IClanInfo2, void>({
      async queryFn(_arg) {
        return clans_info()
          .then((data) => ({ data }))
          .catch((e) => ({
            error: {
              code: e.code,
              message: e.message,
            },
          }));
      },
    }),
    shardsAllRecipes: build.query<IAllRecipes, void>({
      async queryFn(_arg) {
        return shards_getAllRecipes()
          .then((data) => ({ data }))
          .catch((e) => ({
            error: {
              code: e.code,
              message: e.message,
            },
          }));
      },
    }),
    getConfigDescription: build.query<IConfigDescription, {id:string,lvl:number }>({
      async queryFn(_arg) {
        return cgs_getConfigDescription(_arg.id,_arg.lvl)
          .then((data) => ({ data }))
          .catch((e) => ({
            error: {
              code: e.code,
              message: e.message,
            },
          }));
      },
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetClanAssetsQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
  useAddToQueueMutation,
  useRemoveFromQueueMutation,
  useGetClanLeaderboardQuery,
  useGetUserRestrictionsQuery,
  useGetRestrictionsQuery,
  useGetProductPriceQuery,
  useGetClanInfoQuery,
  useShardsAllRecipesQuery,
  useGetConfigDescriptionQuery
} = rtkApi;
