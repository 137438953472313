import { IIndividualLBItem } from "api";
import { IEvent, IRewardListEntry } from "api/api.types";
import * as s from "components/TieredLeaderboard/componetns/Desk.styled";
import { DeskRewards } from "components/TieredLeaderboard/componetns/DeskRewards";
import { I_DeskTierPlayer } from "components/TieredLeaderboard/componetns/IndividualLB/I_DeskTierPlayer";
import { Place } from "components/TieredLeaderboard/componetns/Place";

export function I_DeskTierItem({
  players,
  event,
  item,
}: {
  players: IIndividualLBItem[];
  event: IEvent;
  item: IRewardListEntry;
}) {
  const players_ = players
    .filter(
      (player) =>
        player.rank >= item.placeFromInclusive &&
        player.rank < item.placeToExclusive
    )
    .sort((a, b) => a.rank - b.rank);

  if (players_.length === 0) return null;
  return (
    <s.Tier key={item.tier} tier={item.tier}>
      <Place event={event} place={item.tier} />
      <div className="clans">
        {players_.map((player) => (
          <I_DeskTierPlayer key={player.id} player={player} />
        ))}
      </div>
      <DeskRewards
        rewards={event.individualRewardsList[item.tier - 1].rewardsList}
      />
    </s.Tier>
  );
}
