import butterImg from "assets/butter_icon.png";
import eOrbsIcon from "components/Staking/StakeForEventPoints/assets/eOrbs.png";
import bpIcon from "components/TieredLeaderboard/assets/bp.png";
import * as s from "components/TieredLeaderboard/componetns/Desk.styled";
import { RewardItem } from "components/TieredLeaderboard/componetns/RewardItem";
import { ShardImage } from "components/Transfer/Currencies/components/ShardImage";
import Tooltip from "rc-tooltip";
import { numberWithCommas } from "utils/numberWithComas";
import { IRewardItem } from "../../../api/api.types";
import seeAll from "../assets/seeAll.svg";
import { DeskTooltipContent } from "./DeskTooltipContent";
import "./rc-tooltip.css";
import { RewardToken } from "./RewardToken";

export function DeskRewards({ rewards }: { rewards: IRewardItem[] }) {
  const butter = rewards.find((item) => item.butter)?.butter?.amount || 0;
  const battlePassPoints =
    rewards.find((item) => item.battlePassPoints)?.battlePassPoints?.amount ||
    0;
  const eOrbs = rewards.find((i) => i.enrichedOrbs)?.enrichedOrbs?.amount || 0;
  const inventory = rewards.filter((i) => i.inventory);
  const tokens = rewards.filter((i) => i.token);
  const shards = rewards.filter((i) => i.offchain);
  const totalItems = inventory.length + tokens.length + shards.length;
  const allRewards = inventory.concat(tokens).concat(shards);
  let shownRewards: IRewardItem[];
  if (totalItems > 3) {
    shownRewards = allRewards.slice(0, 2);
  } else shownRewards = allRewards;

  return (
    <s.Rewards>
      <div>
        <Tooltip placement="top" overlay={<div>Butter</div>}>
          <div>
            <img src={butterImg} alt="butter" width={24} />
            {numberWithCommas(butter)}
          </div>
        </Tooltip>
        <Tooltip placement="top" overlay={<div>Battle Pass Points</div>}>
          <div>
            <img src={bpIcon} alt="bp" />
            {numberWithCommas(battlePassPoints)}
          </div>
        </Tooltip>
        <Tooltip placement="top" overlay={<div>Enriched Orbs</div>}>
          <div>
            <img src={eOrbsIcon} height={24} alt="eo" />
            {numberWithCommas(eOrbs)}
          </div>
        </Tooltip>
      </div>
      <div>
        {shownRewards.map((item) => {
          if (item.inventory)
            return (
              <RewardItem
                item={item.inventory!}
                key={item.inventory?.itemId!}
              />
            );
          if (item.token)
            return (
              <RewardToken token={item.token!} key={item.token!.configId} />
            );
          if (item.offchain)
            return (
              <ShardImage
                key={item.offchain.account}
                account={item.offchain.account}
                amount={item.offchain.amount}
              />
            );
          else return null;
        })}
        {/* TOOLTIP BLOCK IF MUCH ITEMS*/}
        {totalItems > 3 && (
          <Tooltip
            placement="topLeft"
            overlay={
              <DeskTooltipContent
                iRewardItems={allRewards}
                butter={butter}
                battlePassPoints={battlePassPoints}
                eOrbs={eOrbs}
              />
            }
          >
            {/*TOOLTIP TRIGGER*/}
            <s.SeeAll>
              <div>+{totalItems - 2}</div>
              <div>
                See all
                <img src={seeAll} alt="seeAll" />
              </div>
            </s.SeeAll>
          </Tooltip>
        )}
      </div>
    </s.Rewards>
  );
}
