import { IIndividualLBItem } from "api";
import { IEvent } from "api/api.types";
import { I_MobTierItem } from "components/TieredLeaderboard/componetns/IndividualLB/I_MobTierItem";

export function I_MobTable({
  players,
  event,
}: {
  players: IIndividualLBItem[];
  event: IEvent;
}) {
  return (
    <div>
      {event.rewardsList.map((item) => (
        <I_MobTierItem
          players={players}
          item={item}
          event={event}
          key={item.tier}
        />
      ))}
    </div>
  );
}
