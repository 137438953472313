import styled, { css } from "styled-components/macro";

export const ContainerHeader = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 7fr 3fr;
  background-color: #1f1f1f;
  margin-bottom: 10px;
  margin-right: 10px;

  @media (max-width: 1279px) {
    grid-template-columns: 7fr 6fr;
  }

  @media (max-width: 767px) {
    margin-right: 0;
    grid-template-columns: 4fr 4fr;
  }
`;

export const ContainerBlock = styled.div<{ selected?: boolean}>`
    display: grid;
    margin-right: 10px;
    grid-auto-flow: row;
    grid-template-columns: 7fr 3fr;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 700;
    background-color: #1F1F1F;
    min-height: 90px;
    border: 1px solid #151515;

    @media (min-width: 1280px) {
        background: ${({ selected }) => selected && 'linear-gradient(0deg, rgba(253, 181, 11, 0.06), rgba(253, 181, 11, 0.06)), #1F1F1F'};
        border: ${({ selected }) => selected && '2px solid #FFB60C'};
  }

    &:hover {
        ${({ selected }) => !selected && css`
            background: #252525;
            border: 1px solid rgba(255, 255, 255, 0.2);
            cursor: pointer;

            button {
                background-color: #8AC700;
                box-shadow: 0px 10px 22px rgba(138, 199, 0, 0.4);
            }
    `};
    }

    @media (max-width: 1279px) {
        grid-template-columns: 7fr 6fr;
  }

    @media (max-width: 767px) {
        grid-template-columns: 5fr 4fr;
        margin-right: 0;
        min-height: 60px;
    }
`;

export const ScrollContainer = styled.div`
    overflow-y: scroll;
    max-height: 700px;
    &::-webkit-scrollbar-thumb {
        background-color: rgb(200, 138, 3);
  }

    &::-webkit-scrollbar-track {
        background-color: rgb(61, 74, 87);
        border-radius: 41px;
    }

    &::backdrop {
        background-color: rgb(7, 17, 27);
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    @media (max-width: 1279px) {
        overflow-y: hidden;
        max-height: none;
  }
`;

export const StyledLogo = styled.img<{ src: string }>`
    src: src;
    margin-right: 35px;

    @media (max-width: 767px) {
        margin-right: 17px;
    }
`;

export const NameField = styled.div`
    margin-left: 35px;

    @media (max-width: 767px) {
        margin-left: 17px;
    }
`;

export const StyledOrbLogo = styled(StyledLogo)`
    margin-left: -3px;

    @media (max-width: 767px) {
        margin-left: 0;
    }
`;

export const Subtitle = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: #FFB60C;
    opacity: 0.9;
    font-weight: 325;
`;

export const ContainerItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    line-height: 19px;
    opacity: 0.9;
    & :first-child {
        border-right: 2px solid #151515;
    }

    @media (max-width: 1279px) {
        justify-content: space-between;
        margin: 0 10px 0 55px;
  }

    @media (max-width: 767px) {
        font-size: 12px;
        margin: 0 10px 0 20px;
        & :first-child {
            border-right: none;
        }
    }
  
`;

export const LeftConteinerItem = styled(ContainerItem)`
    justify-content: flex-start;
    margin: 7px 0 7px 19px;

    @media (max-width: 767px) {
        margin: 0 10px 0 20px;
    }
`;

export const WeaponConteinerItem = styled(ContainerItem)`
    justify-content: flex-start;

    @media (max-width: 1279px) {
        margin: 0;
  }
    & :first-child {
        border-right: none;
    }
`;

export const WeaponQuantityItem = styled(ContainerItem)`
    margin: 7px 0;
    border-left: 2px solid #151515;
    display: flex;
    flex-direction: column;
    @media (max-width: 1279px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 10px 0 55px;
  }

  @media (max-width: 767px) {
        padding: 0 10px 0 20px;
        border-left: none;
    }
`;
