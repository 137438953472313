import { IItemsAttributes } from "api";
import * as s from "components/Staking/StakeForEventPoints/StakePage/StakeItemPage.styled";
import { actions, selectFilters } from "redux/slices/stakePageSlice";
import { useAppDispatch, useAppSelector } from "store";
import { IGeneratedItem } from "types/stakingTypes";
import { mapRarityStrToNum } from "utils/mappers/mapRarityStrToNum";
import { ItemView } from "../Grid/ItemView";

export function ItemsList({
  items,
  itemsAttributes,
}: {
  items: IGeneratedItem[];
  itemsAttributes: IItemsAttributes | undefined;
}) {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilters);
  // const { totalItems } = useSelector(selectAllItems);

  let filteredItems = items;

  if (!itemsAttributes) return null;
  filteredItems.forEach((i) =>
    console.log(
      "itemsAttributesIIdItemTypeValueString",
      itemsAttributes[i.id]["Item Type"].value.string
    )
  );

  // фильтр по рарности
  if (!filters.rarity.includes("all"))
    filteredItems = filteredItems.filter((i) => {
      // @ts-ignore
      return filters.rarity.includes(
        mapRarityStrToNum(itemsAttributes[i.id].Rarity.value.string)
      );
    });
  if (filters.type === "outfits") {
    filteredItems = filteredItems.filter((i) => {
      // @ts-ignore
      return ["Character"].includes(
        itemsAttributes[i.id]["Item Type"].value.string
      );
    });
  } else {
    filteredItems = filteredItems.filter((i) => {
      // @ts-ignore
      return ["Weapon"].includes(
        itemsAttributes[i.id]["Item Type"].value.string
      );
    });
  }

  if (!filteredItems.length) return null;

  return (
    <s.ItemListScrollable>
      <s.ItemListContent>
        {filteredItems.map((item: any) => {
          return (
            <s.ItemZoom key={item.id}>
              <ItemView
                src={
                  itemsAttributes[item.id]["Image Thumbnail URL"].value.string
                }
                rarity={mapRarityStrToNum(
                  itemsAttributes[item.id]["Rarity"].value.string
                )}
                w={155}
                h={155}
                pointer
                currentItem={item}
                onClick={() => {
                  dispatch(actions.setSelectedItemId(item.id));
                }}
              />
            </s.ItemZoom>
          );
        })}
      </s.ItemListContent>
    </s.ItemListScrollable>
  );
}
