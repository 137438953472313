import tier1bg from "components/TieredLeaderboard/assets/tier1tablet.png";
import tier2bg from "components/TieredLeaderboard/assets/tier2tablet.png";
import tier3bg from "components/TieredLeaderboard/assets/tier3tablet.png";
import styled from "styled-components/macro";

export const Header = styled.div`
  display: flex;
  background: #171717;
  margin-bottom: 8px;
  height: 40px;

  & > div {
    text-align: center;
    line-height: 40px;
    font-weight: 350;
    font-size: 12px;
    border-right: 1px solid #ffffff1f;
    color: rgba(255, 255, 255, 0.5);
  }

  & > div:nth-child(1) {
    flex: 1 0 298px;
  }

  & > div:nth-child(2) {
    flex: 0 1 70px;
  }

  & > div:nth-child(3) {
    flex: 1 0 350px;
  }
`;

export const IndividualHeader = styled(Header)`
  & > div:nth-child(1) {
    flex: 1 1 100px;
  }

  & > div:nth-child(2) {
    flex: 1 1 100px;
  }

  & > div:nth-child(3) {
    flex: 0 0 350px;
  }
`;

export const TierItem = styled.div`
  display: flex;
  flex: 1;
  min-height: 36px;

  & > div:nth-child(1) {
    flex: 1 0 298px;
  }

  & > div:nth-child(2) {
    flex: 0 1 70px;
  }

  & > div:nth-child(3) {
    flex: 1 0 350px;
  }

  //CLAN
  & > div:nth-child(1) {
    display: flex;
    align-items: stretch;
    margin-right: 1px;
    font-weight: 350;
    font-size: 12px;

    & > div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      color: black;
      background: #ffb60c;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      color: white;
      background: black;
    }
  }

  //MEMBERS
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    font-weight: 350;
    font-size: 12px;
    background: black;
    margin-right: 1px;
    color: white;
    column-gap: 3px;

    & > img {
      filter: opacity(0.5);
    }
  }

  //POINTS
  & > div:nth-child(3) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    //align-content: center;
    align-items: center;
    width: 340px;
    font-weight: 350;
    font-size: 12px;
    background: black;
    margin-right: 1px;
    color: white;
    column-gap: 3px;
    padding: 0 16px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
`;

export const IndividualTiredItem = styled(TierItem)`
  & > div:nth-child(1) {
    flex: 1 1 100px;
  }

  & > div:nth-child(2) {
    flex: 1 1 100px;
  }

  & > div:nth-child(3) {
    flex: 0 0 341px;
  }
`;

export const Tier = styled.div<{ tier: number }>`
  display: flex;
  flex-direction: column;
  background: rgba(23, 23, 23, 0.75);
  padding: 10px;
  align-items: stretch;
  margin-bottom: 5px;

  //TIER
  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ tier }) => (tier < 4 ? "black" : "white")};
    background: ${({ tier }) =>
      tier === 1
        ? `url(${tier1bg})`
        : tier === 2
        ? `url(${tier2bg})`
        : tier === 3
        ? `url(${tier3bg})`
        : "black"};
    background-size: cover;
    margin-bottom: 20px;
    padding: 0 38px 0 19px;
    height: 46px;

    & > div:nth-child(1) {
      font-weight: 700;
      font-size: 22px;
    }

    & > div:nth-child(2) {
      font-size: 14px;
    }
  }

  // Block with clans rows
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    margin-bottom: 5px;
  }
`;

export const Rewards = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 6px;
  font-weight: 350;
  font-size: 12px;
  background: black;
  color: white;
  column-gap: 6px;

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    & > div {
      display: flex;
      column-gap: 8px;
      width: 113px;
      height: 44px;
      align-items: center;
      background: #171717;
      padding-left: 9px;
    }
  }

  //box for reward items
  & > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    column-gap: 6px;
  }
`;
