import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  clanevents_getClanEventInfo,
  clanevents_getCurrentEvent,
  getClanMembers,
  getTreasuryOrbs,
  staking_getSlots,
} from "api";
import { ClanEventInfo, IEvent } from "api/api.types";
import { AppThunk, RootState } from "store";
import { ISlots } from "types/stakingTypes";
import { secondsToDHM } from "utils/dateHelpers";

export interface Details {
  basePointsRate: number;
  bonusPointsRate: number;
}

export interface PaymentDetails {
  amount: number;
  currency: string;
  unlockDurationText: string;
}

export interface PaymentDetails {
  amount: number;
  currency: string;
  unlockDurationText: string;
}

// TODO: выпилить бы этот слайс к такой то матери
const initialState: {
  event: IEvent;
  slots: ISlots;
  membersCount: number;
  enrichedOrbs: number;
  economicPoints: number;
  stakedItemsCount?: number;
  clanEventInfo?:ClanEventInfo;
} = {
  event: {
    id: "",
    endAt: {
      seconds: 0,
      nanos: 0,
    },
    bannerUrl: "",
    name: "",
    startAt: {
      seconds: 0,
      nanos: 0,
    },
    rewardsList: [],
    individualRewardsList:[],
    extensions: {
      worldDomination: false,
      worldDominationConfig: {
        assets: {
          buildingLimits: {
            ironDomeCount: 0,
          },
          oilRig: {
            passiveBonusPoints: 0,
            activeBonusPercent: 0,
          },
          missileSilo: {
            nukeCapacity: 0,
            freeNukesCount: 0,
          },
        },
        attacks: {
          attackCityByNuke: {
            nukeDestructionPercent: 0,
            nukeVampirePercent: 0,
            ironDomeProtectionPercent: 0,
          },
        },
      },
    },
  },
  slots: {
    bonusesList: [],
    setsList: [],
    lootWeaponRate:0,
    lootWeaponUncommonRate:0,
    lootWeaponRareRate:0,
    lootWeaponEpicRate:0,
    lootWeaponLegendaryRate:0,
    lootCharacterRate:0,
    lootCharacterUncommonRate:0,
    lootCharacterRareRate:0,
    lootCharacterEpicRate:0,
    lootCharacterLegendaryRate:0,
    premiumCharacterRate:0,
    premiumCharacterUncommonRate:0,
    premiumCharacterRareRate:0,
    premiumCharacterEpicRate:0,
    premiumCharacterLegendaryRate:0,
    specialCharacterRate:0,
    specialCharacterUncommonRate:0,
    specialCharacterRareRate:0,
    specialCharacterEpicRate:0,
    specialCharacterLegendaryRate:0,
  },
  membersCount: 0,
  enrichedOrbs: 0,
  economicPoints: 0,
  stakedItemsCount: undefined,
};

export const eventPointsPage = createSlice({
  name: "eventPoints",
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<IEvent>) => {
      state.event = action.payload;
    },
    setSlots: (state, action: PayloadAction<ISlots>) => {
      state.slots = action.payload;
    },
    setMembersCount: (state, action: PayloadAction<any>) => {
      state.membersCount = action.payload;
    },
    setOrbs: (state, action: PayloadAction<any>) => {
      state.enrichedOrbs = action.payload;
    },
    setEconomicPoints: (state, action: PayloadAction<any>) => {
      state.economicPoints = action.payload;
    },
    setStakedItemsCount: (state, action: PayloadAction<number>) => {
      state.stakedItemsCount = action.payload;
    },
    setClanEventInfo: (state, action: PayloadAction<ClanEventInfo>) => {
      state.clanEventInfo = action.payload;
    },
  },
});

export const {
  setEvent,
  setSlots,
  setMembersCount,
  setOrbs,
  setEconomicPoints,
  setStakedItemsCount,
  setClanEventInfo
} = eventPointsPage.actions;

export const fetchEvent = (): AppThunk => async (dispatch) => {
  let response = await clanevents_getCurrentEvent();
  // if (response?.startAt?.seconds > Date.now() / 1000) {
  //   response = await clanevents_getPreviousEvent();
  // }
  dispatch(setEvent(response));
};

export const fetchSlots = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const eventId = state.eventPointsPage.event.id;
  if (eventId === "") return;
  const response = await staking_getSlots(eventId);

  if (response) {
    dispatch(setSlots(response));
    const stakedItems = response.setsList.reduce(
      (acc: any, next: any) =>
        (acc + next.slotsList.reduce(
          (acc: any, next: any) => (acc += !!next.id && 1),
          0
        )),
      0
    );
    dispatch(setStakedItemsCount(stakedItems));
  }
};

export const fetchClanMembers = (): AppThunk => async (dispatch) => {
  const response = await getClanMembers();

  dispatch(setMembersCount(response.length));
};

export const fetchEnrOrbs = (): AppThunk => async (dispatch) => {
  const response = await getTreasuryOrbs();

  dispatch(setOrbs(response?.enrichedOrbs?.total));
};

export const fetchClanPointsInfo =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const eventId = state.eventPointsPage.event.id;
    try {
      const response = await clanevents_getClanEventInfo(eventId);
      dispatch(setEconomicPoints(response?.playerScore?.economicPoints));
      dispatch(setClanEventInfo(response))
    } catch (e) {
      console.log(e);
    }
  };

export default eventPointsPage.reducer;

//selectors
export const selectEvent = (state: RootState) => state.eventPointsPage.event;

export type IEventStatus =
  | {
      status: "past" | "current" | "future";
      time: string;
    }
  | undefined;

// Возвращает тип события и время до начала-окончания. Рассчитывается от стора.
export const selectEventStatus = (state: RootState): IEventStatus => {
  const event = state?.eventPointsPage?.event;

  if (!event) return undefined;
  const start = event.startAt.seconds;
  const now = Math.floor(new Date().getTime() / 1000);
  const end = event.endAt.seconds;

  if (now > start && now < end)
    return {
      status: "current",
      time: secondsToDHM(end - now),
    };

  if (now > end)
    return {
      status: "past",
      time: "",
    };

  if (now < start)
    return {
      status: "future",
      time: secondsToDHM(start - now),
    };
};

export const selectSlots = (state: RootState) =>
  state.eventPointsPage.slots.setsList;

export const selectSlotsInfo = (state: RootState) =>
  state.eventPointsPage.slots;

export const selectBonuses = (state: RootState) =>
  state.eventPointsPage.slots?.bonusesList;

export const selectMembersCount = (state: RootState) =>
  state.eventPointsPage.membersCount;

export const selectOrbs = (state: RootState) =>
  state.eventPointsPage.enrichedOrbs;

export const selectEconomicPoints = (state: RootState) =>
  state.eventPointsPage.economicPoints;

export const selectStakedItemsCount = (state: RootState) =>
  state.eventPointsPage.stakedItemsCount;
