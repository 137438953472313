import { skipToken } from "@reduxjs/toolkit/query/react";
import Box from "components/Box";
import Img from "components/Img";
import { MobilePlaceholder } from "components/MobilePlaceholder";
import { Spinner } from "components/Spinner";
import icon from "components/Staking/StakeForEventPoints/assets/infoIcon.svg";
import { useMyProfile } from "hooks/useMyProfile";
import { InfoModal } from "pages/WorldDomination/InfoModal";
import { ProcessingModal } from "pages/WorldDomination/ProcessingModal";
import { WDGridPanel } from "pages/WorldDomination/WDGridPanel/WDGridPanel";
import { WDStatsPanelView } from "pages/WorldDomination/WDStatsPanel/WDStatsPanelView";
import { WDStatsPanelViewDisabled } from "pages/WorldDomination/WDStatsPanel/WDStatsPanelViewDisabled";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  useGetClanAssetsQuery,
  useGetClanLeaderboardQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
} from "redux/rtkQuery/rtkApi";
import {
  selectLastTurnEnded,
  setLastTurnEnded,
} from "redux/slices/worldDominationSlice";
import { useAppSelector } from "store";
import * as s from "./WorldDomination.styled";

export function WDHomePage() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 1199 });
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showProcessingModal, setShowProcessingModal] = useState(false);

  const player = useMyProfile();
  const lastTurnEnded = useAppSelector(selectLastTurnEnded);
  const { data: event } = useGetEventQuery(undefined, {
    pollingInterval: 300_000,
  });

  let skip: typeof skipToken | undefined = skipToken;
  // условие, при котором продолжаем загужать данные
  if (
    event?.id !== undefined &&
    event?.extensions?.worldDomination === true
    // Date.now() / 1000 < event?.endAt.seconds + 86400
  )
    skip = undefined;

  const {
    data: assets,
    error: assetsErr,
    refetch: refetchAssets,
  } = useGetClanAssetsQuery(skip ?? event?.id!);

  const {
    data: turn,
    error: turnErr,
    refetch: refetchTurn,
  } = useGetEventTurnQuery(skip ?? event?.id!, {
    pollingInterval: 20_000,
  });

  const {
    data: queue,
    error: queueErr,
    refetch: refetchQueue,
  } = useGetRoundQueueQuery(skip, {
    pollingInterval: 20_000,
  });

  const {
    data: clanLb,
    error: clanLbErr,
    refetch: refetchclanLb,
  } = useGetClanLeaderboardQuery(
    event && !skip
      ? { event, params: { clanSlots: true, allItems: true } }
      : skipToken
  );

  useEffect(() => {
    if (turn?.round) {
      refetchclanLb();
      refetchAssets();
      refetchQueue();
    }
  }, [turn?.round]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!turn) return;
      const dif = turn.turn.endsAt.seconds - Date.now() / 1000;
      // greenLog(dif, "dif");

      const now = Date.now() / 1000;
      const end = turn.turn.endsAt.seconds;
      // greenLog(end, "end");
      // greenLog(now, "now");
      // greenLog(new Date(end * 1000), "new Date(end)");

      if (end - now < 0 && turn.round !== turn.total)
        setShowProcessingModal(true);
      else setShowProcessingModal(false);
    }, 3000);
    return () => clearInterval(interval);
  }, [turn]);

  // Set lastTurnEnded in store
  useEffect(() => {
    const interval = setInterval(() => {
      let lastTurnEnded_ = true;
      if (turn)
        lastTurnEnded_ =
          turn.round === turn.total &&
          turn.turn.endsAt.seconds * 1000 < Date.now();

      if (lastTurnEnded_ !== lastTurnEnded)
        dispatch(setLastTurnEnded(lastTurnEnded_));
    }, 5000);
    return () => clearInterval(interval);
  }, [turn]);

  if (isMobile) return <MobilePlaceholder text="World Domination" />;
  if (!player || !event || (!skip && !turn))
    return (
      <Box h={400}>
        <Spinner />
      </Box>
    );

  return (
    <s.WdWrapper>
      <s.Header>
        <s.HeaderText>World Domination</s.HeaderText>
        <Img
          src={icon}
          w={24}
          h={24}
          onClick={() => setShowInfoModal(true)}
          pointer
        />
        <s.Grow />
        {player?.clan && (
          <>
            <s.UserAvatar />
            <s.UserClanName>{player?.clan?.name}</s.UserClanName>
            <s.UserClanHash>#{player?.clan?.slug}</s.UserClanHash>
          </>
        )}
      </s.Header>
      {skip && (
        <>
          <WDStatsPanelViewDisabled />
          <WDGridPanel skip />
        </>
      )}
      {!skip && lastTurnEnded && (
        <>
          <WDStatsPanelViewDisabled />
          <WDGridPanel />
        </>
      )}
      {!skip && clanLb && turn && !lastTurnEnded && (
        <>
          <WDStatsPanelView />
          <WDGridPanel />
        </>
      )}
      {showInfoModal && <InfoModal onClose={() => setShowInfoModal(false)} />}
      {showProcessingModal && <ProcessingModal />}
    </s.WdWrapper>
  );
}
