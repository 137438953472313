export enum BonusType {
  UNKNOWN_BONUS_TYPE = 0,
  MAIN_BONUS = 1,
  LEVEL_BONUS = 2,
  RARITY_BONUS_EPIC = 3,
  RARITY_BONUS_LEGENDARY = 4,
  RARITY_BONUS_RARE = 5,
}

export interface IItem {
  id: string
  name: string
  type: BonusType
  category: number
  rarity: number
  prototype: string
  standard?: any
  progress?: Progress
  generated?: Generated
}

interface ICollectionInfo {
  id: string;
  name: string;
  collectionName: string
}

export interface IAllItemsInfo {
  characters: number;
  mintableItems: number;
  weapons: number;
  lootCollection: number;
  premiumCollection: number;
  collectionInfo: ICollectionInfo[];
  totalItems: IItem[];
}

export interface ICollection {
  allItems: IAllItemsInfo;
}

export interface IGeneratedItem {
  id: string;
  name: string;
  type: number;
  category: number;
  rarity: number;
  prototype: string;
  progress: Progress;
  generated: Generated;
}

export interface Progress {
  level: number;
  points: number;
  nextLevelPoints: number;
  currLevelPoints: number;
}

export interface Generated {
  asset: Asset;
  mintStatus: number;
  dropType: number;
  season: string;
  tokenAddress: string;
  tokenMint: string;
}

export interface Asset {
  icon: string;
  mesh: string;
  texture: string;
  materialsMap: [string, MaterialsMap][];
  perksList: any[];
}

export interface MaterialsMap {
  baseColor: string;
  normalMap: string;
  emissionMap: string;
  ambientOcclusion: string;
}
export interface ISlots {
  bonusesList: IBonusItem[];
  setsList: ISetListItem[];
  lootWeaponRate:number
  lootWeaponUncommonRate:number
  lootWeaponRareRate:number
  lootWeaponEpicRate:number
  lootWeaponLegendaryRate:number
  lootCharacterRate:number
  lootCharacterUncommonRate:number
  lootCharacterRareRate:number
  lootCharacterEpicRate:number
  lootCharacterLegendaryRate:number
  premiumCharacterRate:number
  premiumCharacterUncommonRate:number
  premiumCharacterRareRate:number
  premiumCharacterEpicRate:number
  premiumCharacterLegendaryRate:number
  specialCharacterRate:number
  specialCharacterUncommonRate:number
  specialCharacterRareRate:number
  specialCharacterEpicRate:number
  specialCharacterLegendaryRate:number
}

export interface IBonusItem {
  id: string;
  description: string;
  icon: string;
  name: string;
  percent: number;
  type: number;
}

export interface ISetListItem {
  details: {
    basePointsRate: number;
    bonusPointsRate: number;
    unlockedTill: { nanos: number; seconds: number };
  };
  paymentDetails: {
    amount: number;
    currency: string;
    unlockDurationText: string;
  };
  id: string;
  slotsList: ISlotsListItem[];
}

export interface ISlotsListItem {
  baseRate: number;
  bonusRate: number;
  bonusesList: string[];
  itemId: string;
  rateType: number;
  status: number;
  id: string;
  slotId:number;
}

export interface IToltipData {
  totalPointsRate: number;
  itemBonuses?: IBonusItem[];
  status: string;
  nft: number;
}
