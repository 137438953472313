import butterImg from "assets/butter_icon.png";
import eOrbsIcon from "components/Staking/StakeForEventPoints/assets/eOrbs.png";
import bpIcon from "components/TieredLeaderboard/assets/bp.png";
import { RewardItem } from "components/TieredLeaderboard/componetns/RewardItem";
import { ShardImage } from "components/Transfer/Currencies/components/ShardImage";
import { numberWithCommas } from "utils/numberWithComas";
import { IRewardItem } from "../../../api/api.types";
import * as s from "./Mob.styled";
import { RewardToken } from "./RewardToken";

export function MobRewards(props: { rewards: IRewardItem[] }) {
  const butter = props.rewards.find((item) => item.butter)?.butter?.amount || 0;
  const battlePassPoints =
    props.rewards.find((item) => item.battlePassPoints)?.battlePassPoints
      ?.amount || 0;
  const eOrbs =
    props.rewards.find((i) => i.enrichedOrbs)?.enrichedOrbs?.amount || 0;
  const inventory = props.rewards.filter((item) => item.inventory);
  const tokens = props.rewards.filter((item) => item.token);
  const shards = props.rewards.filter((i) => i.offchain);
  return (
    <s.Rewards>
      <div>
        <div>
          <img src={butterImg} alt="butter" width={23} />
          {numberWithCommas(butter)}
        </div>
        <div>
          <img src={bpIcon} alt="bp" />
          {numberWithCommas(battlePassPoints)}
        </div>
        <div>
          <img src={eOrbsIcon} height={24} alt="bp" />
          {numberWithCommas(eOrbs)}
        </div>
      </div>

      <div>
        {inventory.map((item) => (
          <RewardItem item={item.inventory!} key={item.inventory?.itemId!} />
        ))}
        {tokens.map((item) => (
          <RewardToken token={item.token!} key={item.token!.configId} />
        ))}
        {shards.map((i) => (
          <ShardImage
            key={i.offchain.account}
            account={i.offchain.account}
            amount={i.offchain.amount}
          />
        ))}
      </div>
    </s.Rewards>
  );
}
