import { IIndividualLBItem } from "api";
import { IEvent } from "api/api.types";
import { I_TabletTierItem } from "components/TieredLeaderboard/componetns/IndividualLB/I_TabletTierItem";
import * as s from "../Tablet.styled";

export function I_TabletTable({
  players,
  event,
}: {
  players: IIndividualLBItem[];
  event: IEvent;
}) {
  return (
    <div>
      <s.IndividualHeader>
        <div>CLAN</div>
        <div>MEMBERS</div>
        <div>POINTS</div>
      </s.IndividualHeader>
      {event.individualRewardsList.map((item) => (
        <I_TabletTierItem
          players={players}
          item={item}
          event={event}
          key={item.tier}
        />
      ))}
    </div>
  );
}
