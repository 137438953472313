import React from "react";

type IProps = {
  children?: React.ReactNode;
  w?: React.CSSProperties["fontWeight"];
  s?: React.CSSProperties["fontSize"];
  mt?: React.CSSProperties["marginTop"];
  mb?: React.CSSProperties["marginBottom"];
  ml?: React.CSSProperties["marginLeft"];
  mr?: React.CSSProperties["marginRight"];
  visible?: boolean;
  pointer?: boolean;
  onClick?: any;
  fullWidth?: boolean;
  color?: string;
  underline?: boolean;
  white?: boolean;
  yellow?: boolean;
  grey?: boolean;
  blue?: boolean;
  green?: boolean;
  red?: boolean;
  capitalize?: boolean;
  inline?: boolean;
  center?: boolean;
  uppercase?: boolean;
};

//default font-weight = 400
//default color white
const Text = React.memo(function Text(props: IProps) {
  const color = props.grey
    ? "#838383"
    : props.yellow
    ? "#FFB60C"
    : props.blue
    ? "#85C4FF"
    : props.green
    ? "#59B015"
    : props.white
    ? "#FFFFFF"
    : props.red
    ? "#ec2e00"
    : props.color
    ? props.color
    : "#FFFFFF";

  const css = {
    ...(props.mt ? { marginTop: props.mt } : {}),
    ...(props.mb ? { marginBottom: props.mb } : {}),
    ...(props.ml ? { marginLeft: props.ml } : {}),
    ...(props.mr ? { marginRight: props.mr } : {}),
    ...(props.w ? { fontWeight: props.w } : { fontWeight: 400 }),
    ...(props.s ? { fontSize: props.s } : {}),
    ...(props.visible === undefined
      ? {}
      : { visibility: props.visible ? "visible" : "hidden" }),
    ...(props.pointer ? { cursor: "pointer" } : {}),
    ...(props.underline ? { textDecoration: "underline" } : {}),
    ...(props.fullWidth ? { width: "100%" } : {}),
    ...(color ? { color } : {}),
    ...(props.capitalize ? { textTransform: "capitalize" } : {}),
    ...(props.uppercase ? { textTransform: "uppercase" } : {}),
    ...(props.center ? { textAlign: "center" } : {}),
    ...(props.inline ? { display: "inline" } : {}),
    whiteSpace: "break-spaces",
  };
  return (
    //@ts-ignore
    <div style={css} onClick={props.onClick}>
      {props.children}
    </div>
  );
});

export default Text;
