import {
  DefenceCity,
  EventTurnRaw,
  IEvent,
  IGetClanAssets,
  IRoundQueue,
  OffenceCity,
} from "api/api.types";
import _ from "lodash";
import {
  IAllRows,
  ICitiesObj,
  IRow,
} from "pages/WorldDomination/WDGridPanel/WDGrid";

export function formCitiesList(
  clanLb: IGetClanLeaderboard[] | undefined
): ICitiesObj {
  if (!clanLb || clanLb.length === 0) return {};
  return clanLb.reduce((acc, i) => {
    const cities = i.worldDomination.slotsList.filter((j) => j.kind === 2);
    cities.forEach((city) => {
      acc[city.id] = Object.assign(
        {
          shields: 0,
          shieldsPlanned: 0,
          attacksPlanned: 0,
          successfulNukes: 0,
          failedNukes: 0,
          damagePoints: 0,
          vampirePoints: 0,
        },
        city
      );
    });
    return acc;
  }, {} as ICitiesObj);
}

export function formClanCitiesList(turn: EventTurnRaw | undefined): ICitiesObj {
  if (!turn || !turn.clan) return {};
  const cities = turn.clan.slotsList.filter((c) => c.kind === 2);
  const cities_ = {} as unknown as ICitiesObj;
  cities.forEach(city=>{
    cities_[city.id] = Object.assign(
      {
        shields: 0,
        shieldsPlanned: 0,
        attacksPlanned: 0,
        successfulNukes: 0,
        failedNukes: 0,
        damagePoints: 0,
        vampirePoints: 0,
      },
      city
    );
  })
  return cities_ ;
}

export function addAssetsAndQueueToCities(
  cities: ICitiesObj,
  assets: IGetClanAssets | undefined,
  queue: IRoundQueue | undefined
) {
  const cities_ = _.cloneDeep(cities);
  if (!assets || !queue) return cities_;
  assets.cityShieldsList.forEach((i) => {
    cities_[i.cityId].shields++;
  });
  // add shields planned to cities
  queue.cityShield.forEach((i) => {
    cities_[i.cityId].shieldsPlanned += i.count;
  });
  // add attacks
  queue.attackCityByNuke.forEach((i) => {
    cities_[i.cityId].attacksPlanned += i.count;
  });
  return cities_;
}

export function addAttacksToCities(
  cities: ICitiesObj,
  lastTurnDefense: DefenceCity[] | undefined,
  lastTuenOffense: OffenceCity[] | undefined
) {
  const cities_ = _.cloneDeep(cities);
  if (lastTuenOffense && lastTuenOffense.length > 0) {
    lastTuenOffense.forEach((i) => {
      cities_[i.cityId].successfulNukes = i.nukes.successful;
      cities_[i.cityId].failedNukes = i.nukes.failed;
      cities_[i.cityId].vampirePoints = i.nukes.vampirePoints;
    });
  }
  if (lastTurnDefense && lastTurnDefense.length > 0) {
    lastTurnDefense.forEach((i) => {
      cities_[i.cityId].successfulNukes = i.successfulNukes;
      cities_[i.cityId].failedNukes = i.failedNukes;
      cities_[i.cityId].damagePoints = i.damagePoints;
    });
  }
  return cities_;
}

export function transform(
  clanLb: IGetClanLeaderboard[] | undefined,
  search: string,
  event:IEvent | undefined,
): IAllRows | undefined {
  if (!clanLb || !event) return;
  const rewardList = event.rewardsList
  const allRows: IAllRows = [];
  for (let tier = 1; tier <= rewardList.length + 1; tier++) {
    const rewardRow = rewardList.find(i=>i.tier === tier)
    let groupRows: IRow[] = [];
    let clans
    if(rewardRow){
      clans = clanLb.filter((clan) => clan.rank >=rewardRow.placeFromInclusive && clan.rank <rewardRow.placeToExclusive);
    } else {
      clans = clanLb.filter((clan) => clan.rank >rewardList[rewardList.length-1].placeToExclusive)
    }
    clans.forEach((clan) => {
      let rowCreated: IRow;
      const slotsList = clan.worldDomination.slotsList;
      const cities = slotsList.filter((i) => i.kind === 2);
      const vault = slotsList.find((i) => i.kind === 1);
      rowCreated = {
        tier,
        clanName: clan.name,
        cities,
        rank:clan.rank,
        cityEconomy: 1,
        totalPoints: clan.totalScore,
        vaultedPoints: vault!.points,
        clanId: clan.id,
      };

      if (
        search &&
        rowCreated.clanName.toLowerCase().includes(search.toLowerCase())
      ) {
        groupRows.push(rowCreated);
      } else if (!search) groupRows.push(rowCreated);
    });
    groupRows.sort((a, b) => b.totalPoints - a.totalPoints);
    if (groupRows.length > 0) allRows.push({ tier, groupRows });
  }
  return allRows;
}

export function mergeCities(cities: ICitiesObj, clanCities: ICitiesObj) {
  const cities_ = _.cloneDeep(cities);
  const clanCities_ = _.cloneDeep(clanCities);
  return Object.assign(cities_, clanCities_);
}