import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { NameField } from "components/Transfer/Currencies/Currencies.style";
import {
  useGetConfigDescriptionQuery,
  useShardsAllRecipesQuery,
} from "redux/rtkQuery/rtkApi";

export function ShardName(props: { account: string }) {
  const { data: allShards } = useShardsAllRecipesQuery();
  let token;
  if (allShards && allShards[props.account])
    token = { id: allShards[props.account].configId, lvl: 1 };
  const { data: config } = useGetConfigDescriptionQuery(token ?? skipToken);
  console.log("config", config);

  return (
    <div>
      <NameField>{config?.name}</NameField>
    </div>
  );
}
