import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { RewardItemAmount } from "components/TieredLeaderboard/componetns/TieredLeaderboard.styled";
import {
  useGetConfigDescriptionQuery,
  useShardsAllRecipesQuery,
} from "redux/rtkQuery/rtkApi";
import * as s from "./ShardImage.styled";

export function ShardImage({
  account,
  amount,
  w = 90,
}: {
  account: string;
  amount?: number;
  w?: number;
}) {
  const { data: allShards } = useShardsAllRecipesQuery();
  let token;
  if (allShards && allShards[account])
    token = { id: allShards[account].configId, lvl: 1 };
  const { data: config } = useGetConfigDescriptionQuery(token ?? skipToken);
  const url = config?.asset?.iconUrl;
  const rarity = config?.rarity;

  return (
    <s.ShardImage w={w} rarity={rarity || 1}>
      {amount && <RewardItemAmount amount={amount} />}
      {url && <s.ItemImg src={url} alt={"icon"} />}
      <s.ShardIcon />
    </s.ShardImage>
  );
}
