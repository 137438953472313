import Box from "components/Box";
import { Gap } from "components/Gap";
import GreenButton from "components/GreenButton";
import Img from "components/Img";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import Text from "components/Text";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import * as s from "pages/WorldDomination/WDStatsPanel/ConstructionModal.styled";
import Modal from "react-modal";
import styled from "styled-components/macro";
import img from "./assets/WDInfoImg.png";

export function InfoModal(props: { onClose: any }) {
  return (
    <Modal
      isOpen
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll
      onRequestClose={() => props.onClose()}
    >
      <>
        <s.Close onClick={() => props.onClose()}>
          <img src={closeIcon} />
        </s.Close>
        <Wrapper>
          {/*<Scroll>*/}
            <Box w={887} h={268}>
              <Img src={img} w={887} h={268} />
            </Box>
            <Gap h={21} />
            <Text grey w={400}>
              World Domination is a turn-based mode that plays out on top of a
              normal Clan War event. As Clans earn Military and Economic points,
              they also automatically earn Build Points that can be spent on
              Buildings, Defenses and Nukes.
              <br />
              <br />
              Attack enemy Clans' cities with Nukes to destroy and steal some of
              their Leaderboard Score, protect your cities from attackers with
              Shields and Iron Domes, and build Oil Rigs to earn active and
              passive bonuses for more score points. Choose your own strategy
              and tactic - defensive, offensive or economic!
              <br />
              <br />
              Detailed guide you can find here <OuterLink href="https://faq.miniroyale.io/general-questions/clan-wars-world-domination-guide">Clan Wars World Domination Guide</OuterLink>
            </Text>
          {/*</Scroll>*/}
          <Gap h={30} />
          <GreenButton w={208} h={60} center onClick={() => props.onClose()}>
            OK
          </GreenButton>
        </Wrapper>
      </>
    </Modal>
  );
}

export const Wrapper = styled.div`
  //width: 1000px;
  //height: 600px;
  padding: 44px 50px;
`;

export const Scroll = styled.div`
  max-height: 437px;
  overflow-y: auto;
  padding: 3px 34px 3px 3px;
  &::-webkit-scrollbar-thumb {
    background-color: rgb(255, 138, 3);
    border-radius: 41px;
  }

  &::-webkit-scrollbar-track {
    background-color: #3d3d3d;
    border-radius: 41px;
  }

  &::backdrop {
    background-color: rgb(7, 17, 27);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

export const OuterLink = styled.a`
  color:white;
`;
