import Box from "components/Box";
import basePperH from "components/Staking/StakeForEventPoints/assets/battlePperH.svg";
import bonusPperH from "components/Staking/StakeForEventPoints/assets/bonusPperH.svg";
import economyPointsEarned from "components/Staking/StakeForEventPoints/assets/economyPointsEarned.svg";
import stakedNFTs from "components/Staking/StakeForEventPoints/assets/stakedNfts.svg";
import * as s from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import { InfoIcon } from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import Text from "components/Text";
import {
  selectBonuses,
  selectEconomicPoints,
  selectEventStatus,
  selectSlotsInfo,
  selectStakedItemsCount,
} from "redux/slices/eventPointsSlice";
import { useAppSelector } from "store";
import { MyTooltip } from "../../MyTooltip";

const basePointsPerHourNew = [
  // {fieldName:"lootWeaponRate",name:"Loot Weapon",value: 4},
  {fieldName:"lootWeaponUncommonRate",name:"Loot Weapon Uncommon",value: 6},
  {fieldName:"lootWeaponRareRate",name:"Loot Weapon Rare",value: 8},
  {fieldName:"lootWeaponEpicRate",name:"Loot Weapon Epic",value: 10},
  {fieldName:"lootWeaponLegendaryRate",name:"Loot Weapon Legendary",value: 12},
  // {fieldName:"lootCharacterRate",name:"Loot Character ",value: 8},
  {fieldName:"lootCharacterUncommonRate",name:"Loot Character Uncommon",value: 12},
  {fieldName:"lootCharacterRareRate",name:"Loot Character Rare",value: 14},
  {fieldName:"lootCharacterEpicRate",name:"Loot Character Epic",value: 16},
  {fieldName:"lootCharacterLegendaryRate",name:"Loot Character Legendary",value: 18},
  // {fieldName:"premiumCharacterRate",name:"Premium Character",value: 20},
  {fieldName:"premiumCharacterUncommonRate",name:"Premium Character Uncommon",value: 20},
  {fieldName:"premiumCharacterRareRate",name:"Premium Character Rare",value: 22},
  {fieldName:"premiumCharacterEpicRate",name:"Premium Character Epic",value: 24},
  {fieldName:"premiumCharacterLegendaryRate",name:"Premium Character Legendary",value: 26},
  // {fieldName:"specialCharacterRate",name:"Special Character ",value: 24},
  {fieldName:"specialCharacterUncommonRate",name:"Special Character Uncommon",value: 24},
  {fieldName:"specialCharacterRareRate",name:"Special Character Rare",value: 26},
  {fieldName:"specialCharacterEpicRate",name:"Special Character Epic",value: 28},
  {fieldName:"specialCharacterLegendaryRate",name:"Special Character Legendary",value: 30},
]
export function Contribution() {
  const ePoints = useAppSelector(selectEconomicPoints);
  const bonuses = useAppSelector(selectBonuses);
  const slotsInfo = useAppSelector(selectSlotsInfo);
  const eventStatus = useAppSelector(selectEventStatus);

  const levelUpBouses = bonuses
    .filter((bonus: any) => bonus.name.toLowerCase().includes("level"))
    .sort((a: any, b: any) => a.percent - b.percent);

  const baseHourPoints = slotsInfo.setsList.reduce(
    (acc: any, setListItem) =>
      acc + (setListItem?.details?.basePointsRate || 0),
    0
  );

  const bonusHourPoints = slotsInfo.setsList.reduce(
    (acc: any, setListItem) =>
      acc + (setListItem?.details?.bonusPointsRate || 0),
    0
  );
  const stakedItemsCount = useAppSelector(selectStakedItemsCount);
  
  console.log("slotsInfo", slotsInfo);
  
  
  return (
    <Box column gap={10} w="70%">
      <Text yellow s={20} w={400} uppercase>
        my contribution:
      </Text>
      <s.Contribution>
        <div className="item">
          <img src={stakedNFTs} />
          <div className="count">{stakedItemsCount || "-"}</div>
          <div className="text">
            Staked NFTs <br />
            Towards Event
          </div>
        </div>
        <div className="item">
          <MyTooltip
            overlay={
              <s.TooltipContent>
                  {basePointsPerHourNew.map(item=>(
                    <div>
                      <span style={{ color: "#FFB60C" }}>{item.name}</span> ={" "}
                      {/*@ts-ignore*/}
                      {slotsInfo[item.fieldName]} points/hr
                    </div>
                    ))}
                  
              </s.TooltipContent>
            }
          >
            <InfoIcon />
          </MyTooltip>
          <img src={basePperH} />
          <div className="count">
            {(eventStatus?.status === "current" && baseHourPoints) || "-"}
          </div>
          <div className="text">
            Base Points <br />
            per Hour
          </div>
        </div>
        <div className="item">
          <img src={economyPointsEarned} />
          <div className="count">{ePoints || "-"}</div>
          <div className="text">
            Economic Points earned Towards Current Event
          </div>
        </div>
        <div className="item">
          <MyTooltip
            overlay={
              <s.TooltipContent>
                <div style={{ marginBottom: "16px" }}>
                  In Addition to the Event attribute bonuses, the following item
                  level bonuses apply:
                </div>
                {levelUpBouses?.map((item: any, i: number) => (
                  <div key={i}>
                    <span style={{ color: "#FFB60C" }}>{item.name}</span> ={" "}
                    {item.description}
                  </div>
                ))}
              </s.TooltipContent>
            }
          >
            <InfoIcon />
          </MyTooltip>
          <img src={bonusPperH} />
          <div className="count">
            {(eventStatus?.status === "current" && bonusHourPoints) || "-"}
          </div>
          <div className="text">
            Bonus Points <br />
            Per Hour
          </div>
        </div>
      </s.Contribution>
    </Box>
  );
}
