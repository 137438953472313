import category from "components/TieredLeaderboard/assets/category.png";
import styled from "styled-components/macro";
import { mapRarityToColor } from "utils/mappers/mapRarityToColor";
import tier1bg from "../assets/tier1desk.png";
import tier2bg from "../assets/tier2desk.png";
import tier3bg from "../assets/tier3desk.png";

export const TierItem = styled.div`
  display: flex;
  flex: 1;
  min-height: 36px;
  //CLAN
  & > div:nth-child(1) {
    display: flex;
    align-items: stretch;
    margin-right: 1px;
    width: 219px;
    font-weight: 350;
    font-size: 12px;

    & > div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      color: black;
      background: #ffb60c;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      color: white;
      background: black;
    }
  }

  //MEMBERS
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    font-weight: 350;
    font-size: 12px;
    background: black;
    margin-right: 1px;
    color: white;
    column-gap: 3px;

    & > img {
      filter: opacity(0.5);
    }
  }

  //POINTS
  & > div:nth-child(3) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    //align-content: center;
    align-items: center;
    width: 340px;
    font-weight: 350;
    font-size: 12px;
    background: black;
    margin-right: 1px;
    color: white;
    column-gap: 3px;
    padding: 0 16px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
`;

export const Tier1 = styled.div`
  height: 120px;
  background: rgba(23, 23, 23, 0.75);
  padding: 5px;
  display: flex;
  align-items: stretch;
  margin-bottom: 5px;
  //TIER
  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    background: url(${tier1bg});
    width: 110px;
    margin-right: 5px;

    & > div:nth-child(1) {
      font-weight: 700;
      font-size: 22px;
    }

    & > div:nth-child(2) {
      //font-weight: 350;
      font-size: 14px;
    }
  }
`;

export const Tier2 = styled(Tier1)`
  //TIER
  & > div:nth-child(1) {
    background: url(${tier2bg});
  }

  // Block with clans rows
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
  }
`;

export const Tier3 = styled(Tier2)`
  height: auto;

  & > div:nth-child(1) {
    background: url(${tier3bg});
  }
`;

export const Tier = styled(Tier3)`
  & > div:nth-child(1) {
    color: white;
    background: black;
  }
`;

export const Rewards = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 6px;
  width: 413px;
  font-weight: 350;
  font-size: 12px;
  background: black;
  color: white;
  column-gap: 3px;

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    & > div {
      display: flex;
      column-gap: 8px;
      width: 113px;
      height: 44px;
      align-items: center;
      background: #171717;
      padding-left: 9px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: space-between;

    & > div {
      width: 90px;
      height: 90px;
      background: darkred;
      margin-right: 6px;
    }
  }
`;

export const RewardItem = styled.div<{ rarity: number,w?:number }>`
  position: relative;
  width: ${({ w }) => w ?? 90}px;
  height: ${({ w }) => w ?? 90}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ rarity }) => mapRarityToColor(rarity)};

  & > img {
    width: 93%;
    height: 93%;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 10%);
  }

  & > img.token {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    width: 13px;
    height: 13px;
  }
`;

export const RewardItemAmount = styled.div<{ amount: number }>`
  top: 8px;
  left: 8px;
  position: absolute;
  color: white;
  z-index: 10;
  font-weight: 700;
  font-size: 12px;

  &:after {
    content: "X${({ amount }) => amount}";
  }
`;
export const LevelBage = styled.div<{ category?: number }>`
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: url(${category});
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  &:after {
    font-weight: 700;
    font-size: 8px;
    text-align: center;
    text-transform: uppercase;
    color: #ffb60c;
    content: "${({ category }) => category}";
  }
`;