import { cgs_getConfigDescription } from "api";
import { Box } from "components/Box/Box";
import { ItemView } from "components/Staking/StakeForEventPoints/Grid/ItemView";
import * as s from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import Text from "components/Text";
import { useSwrImmutable } from "hooks/useSwr";
import React from "react";
import { selectBonuses, selectEvent } from "redux/slices/eventPointsSlice";
import { useAppSelector } from "store";
import { BonusType, IBonusItem } from "types/stakingTypes";

export function RarityBonuses() {
  const bonuses = useAppSelector(selectBonuses);
  const event = useAppSelector(selectEvent);

  const rarityBonuses = bonuses.filter((bonus: IBonusItem) =>
    [
      BonusType.RARITY_BONUS_RARE,
      BonusType.RARITY_BONUS_LEGENDARY,
      BonusType.RARITY_BONUS_EPIC,
    ].includes(bonus.type)
  );

  const isFutureEvent = (event?.endAt as any)?.startsIn;

  return (
    <div>
      <div className="title">rarity staking bonuses:</div>
      <s.RarityBonuses>
        {rarityBonuses?.map((item: IBonusItem, i: number) => (
          <RaritySingleBonus item={item} key={i} />
        ))}
      </s.RarityBonuses>
    </div>
  );
}

function RaritySingleBonus (props:{item:IBonusItem}) {
  const [configDescription] = useSwrImmutable(
    [props.item.name],
    cgs_getConfigDescription
  );
  return (
    <Box gap={13}>
      <div>
        <ItemView src={configDescription?.asset?.iconUrl} w={97} h={97} rarity={configDescription?.rarity}/>
      </div>
      <div className="information">
        <div className="white">{configDescription?.name}</div>
        <Text s={14} w={350} color={localMapRarityToColor(configDescription?.rarity)}>{props.item?.description}</Text>
      </div>
    </Box>
  )
}

function localMapRarityToColor(rarity: number | undefined) {
  switch (rarity) {
    case 0:
      return "#FFFFFF";
    case 1:
      return "rgb(165,165,165)";
    case 2:
      return "rgb(87,177,20)";
    case 3:
      return "#6389e5";
    case 4:
      return "#B83FFF";
    case 5:
      return "#FFB60C";
    case 6:
      return "transparent";
    default:
      return "#FFFFFF";
  }
}