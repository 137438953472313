import { City } from "pages/WorldDomination/WDGridPanel/City";
import { ICity } from "pages/WorldDomination/WDGridPanel/WDGrid";
import React from "react";

export function CityItem({
  attacksPlanned,
  city,
  failedNukes,
  handleCityClick,
  shields,
  shieldsPlanned,
  successfulNukes,
}: {
  city: ICity;
  attacksPlanned: number;
  shields: number;
  shieldsPlanned: number;
  successfulNukes: number;
  failedNukes: number;
  handleCityClick: any;
}) {
  let color = "";

  if (successfulNukes === 0 && failedNukes === 0) color = "";
  if (successfulNukes > 0) color = "yellow";
  if (failedNukes > 0 && successfulNukes === 0) color = "blue";
  return (
    <City
      key={city.id}
      onClick={() => handleCityClick(city)}
      shields={shields}
      shieldsPlanned={shieldsPlanned}
      name={city.name}
      cityPoints={city.points}
      attacksPlanned={attacksPlanned}
      cityId={city.id}
      color={color}
    />
  );
}
