import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { getAccountDetails, idp_GetAccount } from "api";
import { DefenceCity, OffenceCity } from "api/api.types";
import butter from "assets/butter_icon.png";
import uranium from "assets/uranium_icon.png";
import Box from "components/Box";
import Img from "components/Img";
import { SimpleTextTooltip } from "components/Staking/SimpleTextTooltip";
import Text from "components/Text";
import { useSwrImmutable } from "hooks/useSwr";
import { DefenseSummary } from "pages/WorldDomination/DefenseOffenceSummary/DefenseSummary";
import { OffenseSummary } from "pages/WorldDomination/DefenseOffenceSummary/OffenseSummary";
import { BuyUraniumModal } from "pages/WorldDomination/WDStatsPanel/BuyUraniumModal";
import { ConstructionModal } from "pages/WorldDomination/WDStatsPanel/ConstructionModal";
import { DonateButterModal } from "pages/WorldDomination/WDStatsPanel/DonateButterModal";
import { OfficerLimitsModal } from "pages/WorldDomination/WDStatsPanel/OfficerLimitsModal";
import { WDStatsPanelSignUpJoin } from "pages/WorldDomination/WDStatsPanel/WDStatsPanelSignUpJoin";
import { WDStatsPanelViewDisabled } from "pages/WorldDomination/WDStatsPanel/WDStatsPanelViewDisabled";
import { useEffect, useState } from "react";
import {
  useGetClanAssetsQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRestrictionsQuery,
  useGetRoundQueueQuery,
  useGetUserRestrictionsQuery,
} from "redux/rtkQuery/rtkApi";
import {
  actions,
  selectBuildShield,
  selectEventEnded,
  selectLastTurnEnded,
} from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import { secondsToDHM } from "utils/dateHelpers";
import { numberWithCommas } from "utils/numberWithComas";
import buildPoints from "../assets/buildPoints.png";
import cityShield from "../assets/cityShield.png";
import cogs from "../assets/cogs.svg";
import fall from "../assets/fall.svg";
import grow from "../assets/grow.svg";
import ironDome from "../assets/ironDome.png";
import missileSilo from "../assets/missileSilo.png";
import nuke from "../assets/nukes.png";
import oilRig from "../assets/oilRig.png";
import stable from "../assets/stable.svg";
import * as s from "./StatsPanel.styled";
import { Icon } from "./StatsPanel.styled";

export type IShowModal =
  | "oil_rig"
  | "missile_silo"
  | "nuke"
  | "iron_dome"
  | "city_shield"
  | null;

export function WDStatsPanelView() {
  const dispatch = useAppDispatch();

  const [showConstructionModal, setShowConstructionModal] =
    useState<IShowModal>(null);
  const [showDonateBtrModal, setShowDonateBtrModal] = useState(false);
  const [showBuyUraniumModal, setShowBuyUraniumModal] = useState(false);
  const [showDefenseModal, setShowDefenseModal] = useState(false);
  const [showOffenseModal, setShowOffenseModal] = useState(false);
  const [showLimitsModal, setShowLimitsModal] = useState(false);
  

  const {data:userRestrictions} = useGetUserRestrictionsQuery()
  const {data:restrictions,refetch} = useGetRestrictionsQuery()
  const lastTurnEnded = useAppSelector(selectLastTurnEnded);
  const buildShieldCity = useAppSelector(selectBuildShield);
  const eventEnded = useAppSelector(selectEventEnded)

  const [auth, loadingAuth] = useSwrImmutable("auth", idp_GetAccount);
  const [player, loadingDetails] = useSwrImmutable(
    auth?.userId?.value ? [auth?.userId?.value, "user"] : null,
    getAccountDetails
  );
  const { data: event } = useGetEventQuery();
  const { data: turn } = useGetEventTurnQuery(event?.id ?? skipToken);
  const { data: queue } = useGetRoundQueueQuery();
  const { data: assets } = useGetClanAssetsQuery(event?.id ?? skipToken);
  
  useEffect(() => {
    if (buildShieldCity) setShowConstructionModal("city_shield");
  }, [buildShieldCity]);
  
  
  if(eventEnded) return <WDStatsPanelViewDisabled />
  if (loadingAuth || loadingDetails ) return null;
  if (!auth || !player || !player?.clan) return <WDStatsPanelSignUpJoin />;
  if (!queue || !turn || !assets || !userRestrictions ) return <s.Wrapper><s.LoadingText>Calculating...</s.LoadingText></s.Wrapper>;
  
  const canCurrentUserSet = userRestrictions.canCurrentUserSet;
  
  const buildPointsBalance = turn.clan.balancesList.find(
    (i) => i.currency === "BUILD_POINT"
  )!.amount;
  const uraniumBalance = turn.clan.balancesList.find(
    (i) => i.currency === "URANIUM"
  )!.amount;
  const clanButterBalance = turn.clan.balancesList.find(
    (i) => i.currency === "BUTTER"
  )!.amount;

  const nukesCount = assets.nukesList.length;
  const nukesPlannedCount = queue.nuke.reduce((acc, i) => acc + i.count, 0);
  const attacksCount = queue.attackCityByNuke.reduce(
    (acc, i) => acc + i.count,
    0
  );
  const nukesAvailableCount = nukesCount - attacksCount;

  const cityShieldsCount = assets.cityShieldsList.length;
  const cityShieldsPlanned = queue.cityShield.reduce(
    (acc, i) => acc + i.count,
    0
  );

  const missileSiloCount = assets.missileSilosList.length;
  const missileSiloPlanned = queue.missileSilo.reduce(
    (acc, i) => acc + i.count,
    0
  );

  const oilRigCount = assets.oilRigsList.length;
  const oilRigPlanned = queue.oilRig.reduce((acc, i) => acc + i.count, 0);

  const ironDomeCount = assets.ironDomesList.length;

  const uranuimPriceButter = turn.market.productsList
    ?.find((i) => i.productId === "BUY_URANIUM")
    ?.pricesList?.find((i) => i.currency === "BUTTER")?.amount;

  const defenceCities = turn.battleSummaryList.reduce(
    (acc, i) => acc.concat(i.defence.citiesList),
    [] as DefenceCity[]
  );

  const offenceCities = turn.battleSummaryList.reduce(
    (acc, i) => acc.concat(i.offence.citiesList),
    [] as OffenceCity[]
  );

  const defence = defenceCities.reduce(
    (acc, city) => {
      acc.strikesLost += city.successfulNukes;
      acc.strikesRepelled += city.failedNukes;
      acc.pointsLost += city.damagePoints;
      return acc;
    },
    {
      strikesLost: 0,
      strikesRepelled: 0,
      pointsLost: 0,
    }
  );

  const offence = offenceCities.reduce(
    (acc, i) => {
      acc.strikesSuccessful += i.myNukes.successful;
      acc.strikesFailed += i.myNukes.failed;
      acc.pointsGained += i.myNukes.vampirePoints;
      return acc;
    },
    {
      strikesSuccessful: 0,
      strikesFailed: 0,
      pointsGained: 0,
    }
  );
  
  const uraniumPriceChange =
    turn.market.productsList
      .find((i) => i.productId === "BUY_URANIUM")!
      .pricesList.find((i) => i.currency === "BUTTER")!.diffInPercentage /
    10000;
  const turnEndsInMs = turn.turn.endsAt.seconds - Date.now() / 1000
  const turnEndsInText = turnEndsInMs > 0 ? secondsToDHM(turnEndsInMs) : 'processing';
  
  
  
  return (
    <s.Wrapper>
      <s.BlockTurn>
        <s.Yellow>Current Turn:</s.Yellow>
        <s.White>
          {turn.round}/{turn.total}
        </s.White>
        <s.Yellow>Turn Ends In:</s.Yellow>
        <s.White>
          {lastTurnEnded && 'finished'}
          {!lastTurnEnded && turnEndsInText}
        </s.White>
      </s.BlockTurn>
      <s.BlockPoints>
        <s.Yellow>Build Points:</s.Yellow>
        <s.White>
          <s.Icon src={buildPoints} /> {numberWithCommas(buildPointsBalance)}
        </s.White>
        <s.Yellow> Butter:</s.Yellow>
        <s.White>
          <s.Icon src={butter} />
          {numberWithCommas(clanButterBalance)}
          <SimpleTextTooltip overlay={"Donate Butter"}>
            <s.Plus onClick={() => lastTurnEnded ? null : setShowDonateBtrModal(true)} />
          </SimpleTextTooltip>
        </s.White>
      </s.BlockPoints>
      <s.BlockMarket>
        <s.Yellow>Uranium Cost:</s.Yellow>
        <s.Blue>
          <Icon src={butter} width={20} />
          {numberWithCommas(uranuimPriceButter)} Butter
          <s.CostChange val={uraniumPriceChange} />
        </s.Blue>
        <s.MarketDirection>
          {uraniumPriceChange > 0 && <img src={grow} width={45} />}
          {uraniumPriceChange < 0 && <img src={fall} width={45} />}
          {uraniumPriceChange === 0 && <img src={stable} width={45} />}
          {mapCostChangeToText(uraniumPriceChange)}
        </s.MarketDirection>
        <s.Yellow>Uranium Balance:</s.Yellow>
        <s.Green>
          <Icon src={uranium} width={20} />
          {numberWithCommas(uraniumBalance)} units
          <SimpleTextTooltip overlay={"Buy Uranium"}>
            <s.Plus onClick={() => lastTurnEnded ? null :setShowBuyUraniumModal(true)} />
          </SimpleTextTooltip>
        </s.Green>
      </s.BlockMarket>
      <s.BlockConstruction>
        <div>
          <s.Title>Construction: </s.Title>
          <s.HelpText>(click on each to build more)</s.HelpText>
          <Box flex="1" />
          {canCurrentUserSet && (
            <Box
              alignItems="center"
              pointer
              onClick={() => lastTurnEnded ? null : setShowLimitsModal(true)}
            >
              <Img src={cogs} mr={5} />{" "}
              <Text w={400} s={14} color="#ff8a03" capitalize>
                Set Limits
              </Text>
            </Box>
          )}
        </div>
        <div className="items">
          <div className="item">
            <img
              src={oilRig}
              width={108}
              onClick={() => lastTurnEnded ? null : setShowConstructionModal("oil_rig")}
            ></img>
            <div className="name">OIL RIG</div>
            <s.Number val={oilRigCount} />
            <s.Planned>
              {queue.oilRig.length > 0 && <>(+{oilRigPlanned} planned)</>}
            </s.Planned>
          </div>
          <div className="item">
            <img
              src={missileSilo}
              width={108}
              onClick={() => lastTurnEnded ? null : setShowConstructionModal("missile_silo")}
            ></img>
            <div className="name">MISSILE SILO</div>
            <s.Number val={missileSiloCount} />
            <s.Planned>
              {queue.missileSilo.length > 0 && (
                <>(+{missileSiloPlanned} planned)</>
              )}
            </s.Planned>
          </div>
          <div className="item">
            <img
              src={nuke}
              width={108}
              onClick={() => lastTurnEnded ? null : setShowConstructionModal("nuke")}
            ></img>
            <div className="name">NUKE</div>
            <s.Number val={nukesAvailableCount} />
            <s.Planned>
              {queue.nuke.length > 0 && <>(+{nukesPlannedCount} planned)</>}
            </s.Planned>
          </div>
          <div className="item">
            <img
              src={ironDome}
              width={108}
              onClick={() => lastTurnEnded ? null : setShowConstructionModal("iron_dome")}
            ></img>
            <div className="name">IRON DOME</div>
            <s.Number val={ironDomeCount} />
            <s.Planned>
              {queue.ironDome.length > 0 && (
                <>(+{queue.ironDome.length} planned)</>
              )}
            </s.Planned>
          </div>
          <div className="item">
            <img
              src={cityShield}
              width={108}
              onClick={() => lastTurnEnded ? null : setShowConstructionModal("city_shield")}
            ></img>
            <div className="name">CITY SHIELD</div>
            <s.Number val={cityShieldsCount} />
            <s.Planned>
              {queue.cityShield.length > 0 && (
                <>(+{cityShieldsPlanned} planned)</>
              )}
            </s.Planned>
          </div>
        </div>
      </s.BlockConstruction>
      <s.Summary>
        <div className="title">Defense Summary:</div>
        <div className="content">
          {defenceCities.length > 0 ? (
            <>
              <s.SummaryItem positive={true}>
                {defence.strikesRepelled} inbound strikes repelled
              </s.SummaryItem>
              <s.SummaryItem positive={false}>
                {defence.strikesLost} inbound strikes lost
              </s.SummaryItem>
              <s.SummaryItem positive={false}>
                {numberWithCommas(defence.pointsLost)} points lost
              </s.SummaryItem>
              <div
                className="seeDetails"
                onClick={() => setShowDefenseModal(true)}
              >
                See Details
              </div>
            </>
          ) : (
            <s.NoSummary />
          )}
        </div>
      </s.Summary>
      <s.Summary>
        <div className="title">Offence Summary:</div>
        <div className="content">
          {offenceCities.length > 0 ? (
            <>
              <s.SummaryItem positive={true}>
                {offence.strikesSuccessful} strikes successful
              </s.SummaryItem>
              <s.SummaryItem positive={false}>
                {offence.strikesFailed} strikes failed
              </s.SummaryItem>
              <s.SummaryItem positive={true}>
                {numberWithCommas(offence.pointsGained)} points gained
              </s.SummaryItem>
              <div
                className="seeDetails"
                onClick={() => setShowOffenseModal(true)}
              >
                See Details
              </div>
            </>
          ) : (
            <s.NoSummary />
          )}
        </div>
      </s.Summary>
      {showConstructionModal && (
        <ConstructionModal
          type={showConstructionModal}
          player={player}
          onClose={() => {
            setShowConstructionModal(null);
            dispatch(actions.setBuildShieldCity(undefined));
          }}
        />
      )}
      {showDonateBtrModal && (
        <DonateButterModal onClose={() => setShowDonateBtrModal(false)} />
      )}
      {showBuyUraniumModal && (
        <BuyUraniumModal
          onClose={() => setShowBuyUraniumModal(false)}
          balanceButter={clanButterBalance}
          balanceBP={buildPointsBalance}
        />
      )}
      {showDefenseModal && (
        <DefenseSummary onClose={() => setShowDefenseModal(false)} />
      )}
      {showOffenseModal && (
        <OffenseSummary onClose={() => setShowOffenseModal(false)} />
      )}
      {showLimitsModal && (
        <OfficerLimitsModal refetch={refetch} restrictions={restrictions} onClose={() => setShowLimitsModal(false)} />
      )}
    </s.Wrapper>
  );
}

function mapCostChangeToText(change: number) {
  if (change > 0) return "Increasing From Global Nuke Production";
  if (change === 0) return "Global Prices Stable \u00A0 No Major Activity";
  if (change < 0) return "Decreasing From Global Nuke Production";
}
