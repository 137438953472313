import tier1bg from "components/TieredLeaderboard/assets/tier1desk.png";
import tier2bg from "components/TieredLeaderboard/assets/tier2desk.png";
import tier3bg from "components/TieredLeaderboard/assets/tier3desk.png";
import styled from "styled-components/macro";

export const Header = styled.div`
  display: grid;
  grid-template-columns: 117px 223px 71px 341px 418px;
  background: #171717;
  margin-bottom: 8px;

  & > div {
    text-align: center;
    line-height: 40px;
    font-weight: 350;
    font-size: 12px;
    border-right: 1px solid #ffffff1f;
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const IndividualHeader = styled(Header)`
  grid-template-columns: 117px 144px 141px 341px 1fr
`;

export const TierItem = styled.div`
  display: flex;
  flex: 1;
  min-height: 36px;
  //CLAN
  & > div:nth-child(1) {
    display: flex;
    align-items: stretch;
    margin-right: 1px;
    width: 219px;
    font-weight: 350;
    font-size: 12px;

    & > div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      color: black;
      background: #ffb60c;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      color: white;
      background: black;
    }
  }

  //MEMBERS
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    font-weight: 350;
    font-size: 12px;
    background: black;
    margin-right: 1px;
    color: white;
    column-gap: 3px;

    & > img {
      filter: opacity(0.5);
    }
  }

  //POINTS
  & > div:nth-child(3) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    //align-content: center;
    align-items: center;
    width: 340px;
    font-weight: 350;
    font-size: 12px;
    background: black;
    margin-right: 1px;
    color: white;
    column-gap: 3px;
    padding: 0 16px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
`;

export const IndividualTiredItem = styled(TierItem)`
  & > div:nth-child(1) {
    width: 140px;
  }
  & > div:nth-child(2) {
    width: 140px;
`;

export const Tier = styled.div<{ tier: number }>`
  min-height: 120px;
  background: rgba(23, 23, 23, 0.75);
  padding: 5px;
  display: flex;
  align-items: stretch;
  margin-bottom: 5px;
  //TIER
  & > div:nth-child(1) {
    width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ tier }) => (tier < 4 ? "black" : "white")};
    background: ${({ tier }) =>
      tier === 1
        ? `url(${tier1bg})`
        : tier === 2
        ? `url(${tier2bg})`
        : tier === 3
        ? `url(${tier3bg})`
        : "black"};
    background-size: cover;
    min-height: 110px;
    margin-right: 5px;

    & > .tier {
      font-weight: 700;
      font-size: 22px;
    }
  }

  & > .clans {
    //font-weight: 350;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
`;

export const Rewards = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 6px;
  width: 413px;
  font-weight: 350;
  font-size: 12px;
  background: black;
  color: white;
  column-gap: 3px;

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    & > div {
      display: flex;
      column-gap: 8px;
      width: 113px;
      height: 44px;
      align-items: center;
      background: #171717;
      padding-left: 9px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    column-gap: 6px;
  }
`;

export const SeeAll = styled.div`
  width: 90px;
  height: 90px;
  background: #2e2e2e;
  position: relative;
  & > div:nth-child(1) {
    position: absolute;
    color: #ffb60c;
    font-weight: 700;
    font-size: 22px;
    top: 30px;
    left: 30px;
  }
  & > div:nth-child(2) {
    display: flex;
    column-gap: 6px;
    align-items: center;
    position: absolute;
    color: #838383;
    font-weight: 350;
    font-size: 14px;
    bottom: 9px;
    left: 18px;
  }
`;

export const TooltipInner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  gap: 6px;
`;
export const TooltipCherarBP = styled.div`
  display: flex;
  gap: 6px;
  & > div {
    flex: 1 1 50%;
    display: flex;
    column-gap: 8px;
    width: 113px;
    height: 44px;
    align-items: center;
    background: #171717;
    padding-left: 9px;
    & > div {
      display: flex;
      flex-direction: column;
      & > div:nth-child(1) {
        font-weight: 325;
        font-size: 12px;
        color: #838383;
      }
      & > div:nth-child(2) {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
`;
