import { mapTagToMapName } from "api/transformDataHelpers";
import checkbox from "assets/checkbox.svg";
import checkboxActive from "assets/checkboxActive.svg";
import ApplyBtn from "components/ApplyBtn";
import FiltersMobile from "components/FiltersMobile";
import { useClickOutside } from "hooks/useClickOutside";
import { useImagePreloader } from "hooks/useImagePreloader";
import { useIsMobile } from "hooks/useMediaQuery";
import { ChangeEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as s from "./styled";

export type IOption = {
  key: string | number;
  value: string;
  selected: boolean;
};

export type IFilterItem = {
  name: string;
  options: IOption[];
  showSearch: boolean;
};

export type ISchemaType = {
  name: string;
  showSearch: boolean;
  options?: IOption[];
}[];

export const initialFilters = [
  {
    name: "Maps",
    options: [
      "port",
      "griefstreet",
      "frostbite",
      "loudhills",
      "canals",
      "industry",
      "piquant",
      "cyberpunk",
      "factory",
      "invasion",
      "shinigamisquare"
    ].map((i) => ({ key: i, value: mapTagToMapName(i), selected: false })),
    showSearch: false,
  },
  {
    name: "Modes",
    options: [
      { key: 1, value: "CTF", selected: false },
      { key: 2, value: "TDM", selected: false },
      { key: 3, value: "BR", selected: false },
      // { key: 4, value: "FFA", selected: false },
    ],
    showSearch: false,
  },
];

function paramsToObject(search: URLSearchParams) {
  const result: any = {};
  for (const [key] of search) {
    result[key] = search.getAll(key);
  }
  return result;
}

export function getMergedFilters(
  filtersFromURL: URLSearchParams,
  filtersInitial: IFilterItem[]
) {
  return filtersInitial.map((item) => {
    const name = item.name;
    const URLOptions = filtersFromURL.getAll(name);
    if (!URLOptions.length) return item;
    const options = item.options.map((option) => ({
      ...option,
      selected: URLOptions.includes(option.value),
    }));
    return { ...item, options };
  });
}

export default function TableFilters(props: { mergedFilters: IFilterItem[] }) {
  const isMobile = useIsMobile();
  const [filters, setFilters] = useSearchParams();
  useImagePreloader([checkboxActive, checkbox]);
  const handleApply = (name: string, options: IOption[]) => {
    const filtersAsObject = paramsToObject(filters);
    const selectedOptions = options
      .filter((i) => i.selected)
      .map((i) => i.value);
    setFilters({ ...filtersAsObject, [name]: selectedOptions });
  };

  const handleApplyMobile = (filters: IFilterItem[]) => {
    const newFilters = filters.reduce((acc, item) => {
      acc[item.name] = item.options
        .filter((i) => i.selected)
        .map((i) => i.value);
      return acc;
    }, {} as Record<any, any>);
    setFilters(newFilters);
  };

  return (
    <s.FilterWrapper>
      {isMobile ? (
        <FiltersMobile
          filters={props.mergedFilters}
          handleApply={handleApplyMobile}
        />
      ) : (
        <>
          <span>Filter By:</span>
          {props.mergedFilters.map((item) => (
            <FilterItem
              key={item.name}
              filter={item}
              handleApply={handleApply}
            />
          ))}
        </>
      )}
    </s.FilterWrapper>
  );
}

function FilterItem({
  filter,
  handleApply,
}: {
  filter: IFilterItem;
  handleApply: (name: string, options: IOption[]) => void;
}) {
  const { name, showSearch, options: initialOptions } = filter;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(initialOptions);
  const [search, setSearch] = useState("");

  const handleCheckOption = (item: IOption) => {
    setOptions(
      options.map((i) =>
        i.key === item.key ? { ...i, selected: !i.selected } : i
      )
    );
  };

  const handleApplyBtnClick = () => {
    setOpen(false);
    handleApply(name, options);
  };

  const ref = useClickOutside(open, () => {
    setOpen(false);
    setOptions(initialOptions);
  });

  const selectedOptions = options.filter((i) => i.selected).map((i) => i.value);

  function handleSearchChange(e: ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  return (
    <s.FilterItemWrapper ref={ref}>
      <s.FilterInner onClick={() => setOpen(!open)} open={open}>
        <s.FilterTitle open={open}>
          {!!selectedOptions.length ? selectedOptions.toString() : name}
        </s.FilterTitle>
        <s.FilterArrow open={open} />
      </s.FilterInner>
      <s.FilterContent open={open}>
        <s.FilterSearch show={showSearch}>
          <s.FilterSearchIcon />
          <s.FilterSearchInput value={search} onChange={handleSearchChange} />
        </s.FilterSearch>
        <s.FilterContentHdr>SELECT {name}</s.FilterContentHdr>
        <s.FilterItems>
          {options.map((item) => (
            <s.FilterContentItem
              key={item.key}
              selected={item.selected}
              onClick={() => handleCheckOption(item)}
            >
              <s.FilterContentItemCheckbox selected={item.selected} />
              <span>{item.value}</span>
            </s.FilterContentItem>
          ))}
        </s.FilterItems>
        <ApplyBtn onClick={handleApplyBtnClick} />
      </s.FilterContent>
    </s.FilterItemWrapper>
  );
}
