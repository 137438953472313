import {
  BoundedWallet,
  ClanEventInfo,
  EventTurnRaw,
  FeatureFlag,
  IAllRecipes,
  IClanInfo,
  IClanInfo2,
  IClanMembersResponse,
  IClanResponse,
  IConfigDescription,
  IEvent,
  IFriendsResp,
  IGameAuthResponse,
  IGetAccountDetailsResponse,
  IGetClanAssets,
  IGetRoundQueueRaw,
  IItem,
  IItemsNormalized,
  ILeaderboardList,
  IMetadataCollection,
  InventoryItems,
  IPlayerScores,
  IRankAndHistoryResp,
  IRewardItemToken,
  IRoundQueue,
  ITierClanResponse,
  ITokenMetadata,
  ITokensNormalized,
  IUserRestrictions,
  QueueRestrictions,
} from "api/api.types";
import { followingStatusToStr, gameModeToStr } from "api/transformDataHelpers";
import icon1 from "assets/userIcon1.png";
import icon2 from "assets/userIcon2.png";
import icon3 from "assets/userIcon3.png";
import icon4 from "assets/userIcon4.png";
import icon5 from "assets/userIcon5.png";
import { IFilterItem } from "components/Filters";
import { server } from "config";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  IAddToQueue,
  IGetProductPrice,
  IGetProductPriceParams,
  IRemoveFromQueue,
} from "redux/rtkQuery/rtkApiTypes";
import { ISlots } from "types/stakingTypes";
import { IBalance, IHistory, IminiItem } from "types/transferTypes";
import { greenLog } from "utils/advancedLog";
import { secondsToHMS } from "utils/dateHelpers";
import { hash } from "utils/hash_code";
import { mapNumToRole } from "utils/mappers/mapNumToRole";
import {
  ISlotStatus,
  mapStakingStatusToValue,
} from "utils/mappers/mapStakingStatusToValue";
import { normalizeArr } from "utils/normalizeArr";
import { numberWithCommas } from "utils/numberWithComas";
import { v4 as uuidv4 } from "uuid";

const icons = [icon1, icon2, icon3, icon4, icon5];

let bundle = require("api/server_bundle");
// @ts-ignore
if (window.service) {
  // @ts-ignore
  bundle = window.service;
}

console.log(`bundle: `, bundle);

export const grpcDefault = [server, null, { withCredentials: true }];

export function getClanMembers(): Promise<IClanMembersResponse> {
  const client = new bundle.clans.ClansPromiseClient(...grpcDefault);
  const req = new bundle.clans.Empty();
  return client.getMates(req).then((res: any) => {
    const newList = res.getMembersList().map((item: any) => ({
      id: item.array[0][0],
      name: item.array[1],
      membersList: res.getMembersList().map((item: any) => item.toObject()),
    }));

    return newList;
  });
}

export function getLastEventpoints(): Promise<ILeaderboardList> {
  const client = new bundle.clanevents.ClanEventPromiseClient(...grpcDefault);
  const req = new bundle.clanevents.GetPlayerLeaderboard.Request();
  const eventReq = new bundle.clanevents.GetPreviousEvent.Request();
  return client
    .getPreviousEvent(eventReq)
    .then((res: any) => res.getEvent().getId())
    .then((id: string) => {
      req.setEventId(id);
      return client
        .getPlayerLeaderboard(req)
        .then((res: any) => res.toObject());
    })
    .catch((e: Error) => {
      console.log("getPreviousEvent:", e.message);
    });
}

export function getTreasuryBalance(): Promise<IBalance> {
  const filter = new bundle.clantreasury.GetBalances.Filter();
  filter.setButter(true);
  filter.setEnrichedOrbs(true);
  filter.setGamedataItems(true);
  filter.setMintableTokens(true);
  filter.setShards(true);
  const client = new bundle.clantreasury.OverviewPromiseClient(...grpcDefault);
  const request = new bundle.clantreasury.GetBalances.Request();

  return client
    .getBalances(request.setFilter(filter))
    .then((res: any) => {
      console.log("clantreasury_GetBalances", res.toObject());
      return res.toObject();
    })
    .catch((e: any) => {
      const log = {
        request: "clantreasury_GetBalances",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clantreasury_GetBalances", log);
      throw e;
    });
}

export function getTreasuryOrbs(): Promise<IBalance> {
  const filter = new bundle.clantreasury.GetBalances.Filter();
  filter.setEnrichedOrbs(true);

  const client = new bundle.clantreasury.OverviewPromiseClient(...grpcDefault);
  const request = new bundle.clantreasury.GetBalances.Request();

  return client
    .getBalances(request.setFilter(filter))
    .then((res: any) => {
      console.log("clantreasury_GetBalances(orbs)", res.toObject());
      return res.toObject();
    })
    .catch((e: any) => {
      const log = {
        request: "clantreasury_GetBalances(orbs)",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clantreasury_GetBalances(orbs)", log);
      throw e;
    });
}

export function getMiniTableItems(
  ids: { id: string; lvl: number }[]
): Promise<IminiItem[]> {
  const client = new bundle.cgs.TokensPromiseClient(...grpcDefault);

  return Promise.all(
    ids.map(({ id, lvl }) => {
      const request = new bundle.cgs.GetConfigDescription.Request();
      request.setConfigId(id);
      request.setLevel(lvl);
      return client
        .getConfigDescription(request)
        .then((response: any) => {
          const result = { ...response.toObject(), id };
          // console.log("cgs_getMiniTableItems", result);

          return result;
        })
        .catch((e: Error) =>
          console.log("cgs_getMiniTableItemsError: ", e.message)
        );
    })
  );
}

export function getTransferHistory(nexPage: string): Promise<IHistory> {
  const client = new bundle.clantreasury.OperationsPromiseClient(
    ...grpcDefault
  );

  const reqHistory = new bundle.clantreasury.OperationsHistory.Request();
  reqHistory.setLimit(17);
  if (nexPage) {
    reqHistory.setPageCursor(nexPage);
  }

  return client.history(reqHistory).then((res: any) => res.toObject());
}

export function sendTransaction(
  amount: number,
  recipientId: string,
  dataItem: any,
  reqId: string
) {
  const gameItemId = dataItem?.item?.itemId;
  const miniId = dataItem?.token?.configId;
  const shard = dataItem?.fund?.account;
  const client = new bundle.clantreasury.OperationsPromiseClient(
    ...grpcDefault
  );
  const itemInfo = new bundle.clantreasury.TransferItem.Info();

  if (gameItemId) {
    const gdItem = new bundle.clantreasury.GamedataItem();
    gdItem.setItemId(gameItemId);
    itemInfo.setGamedataItem(gdItem);
  } else if (miniId) {
    const mitnableTokens = new bundle.clantreasury.MintableToken();
    mitnableTokens.setConfigId(miniId);
    mitnableTokens.setLevel(dataItem?.token?.level);
    itemInfo.setMintableToken(mitnableTokens);
  } else if (shard) {
    const shardItem = new bundle.clantreasury.OffchainFund();
    shardItem.setAccount(shard);
    itemInfo.setOffchainFund(shardItem);
  } else {
    itemInfo.setButter(true);
  }

  const item = new bundle.clantreasury.TransferItem();
  item.setAmount(amount);
  item.setInfo(itemInfo);

  const req = new bundle.clantreasury.SubmitTransfer.Request();
  req.setReasonId(reqId);
  req.setRecipientUserId(recipientId);
  req.setItem(item);

  return client.submitTransfer(req);
}

export function getClanInfo(): Promise<IClanInfo> {
  const client = new bundle.clans.ClansPromiseClient(...grpcDefault);
  return client.info(new bundle.clans.Empty()).then((res: any) => {
    const clanInfo = {
      clanName: res.getClan().array[1],
      role: res.getRole(),
    };
    return clanInfo;
  });
}

export function getAccounts(ids: string[]) {
  return Promise.all(
    ids.map((userId) => {
      return getAccountDetails(userId);
    })
  );
}

export function getAccountDetails(userId: string): IProfileFull | undefined {
  const client = new bundle.gameaccount.OverviewPromiseClient(...grpcDefault);
  const request = new bundle.gameaccount.GetAccountDetails.Request();
  request.setUserId(userId);

  return client
    .getAccountDetails(request)
    .then((data: any) => data.toObject())
    .then((data: IGetAccountDetailsResponse) => {
      console.log("debug GetAccountDetails", data);
      const playerRaw = data.details;
      const player: IProfileFull | undefined = playerRaw
        ? {
            icon: icons[hash(playerRaw.username)],
            id: userId,
            isOwner: playerRaw?.clan?.isOwner,
            hash: playerRaw.hash,
            name: playerRaw.username,
            score: numberWithCommas(playerRaw.level.exp.current),
            kills: numberWithCommas(playerRaw.kills),
            deaths: numberWithCommas(playerRaw.deaths),
            assists: numberWithCommas(playerRaw.assists),
            clan: playerRaw?.clan,
            onlineStatus: true,
            level: playerRaw.level.level,
            followers: numberWithCommas(0),
            following: numberWithCommas(0),
            suffix: playerRaw.suffix,
            kdr: (playerRaw.deaths
              ? playerRaw.kills / playerRaw.deaths
              : playerRaw.kills
            ).toFixed(2),
            overall: {
              gameTime: secondsToHMS(playerRaw.totalGameTime),
              gamesPlayed: numberWithCommas(playerRaw.totalGamesPlayed),
              gamesWon: numberWithCommas(playerRaw.totalGamesWon),
              experience: numberWithCommas(playerRaw.experience),
              headshots: numberWithCommas(playerRaw.headshots),
            },
          }
        : undefined;

      return player;
    });
}

//auth === null => user is guest
export function idp_GetAccount(): Promise<
  IGameAuthResponse | undefined | null
> {
  const client = new bundle.idp.AccountsPromiseClient(...grpcDefault);
  let request = new bundle.idp.GetAccount();
  return client
    .getAccount(request)
    .then((response: any) => {
      console.log(`idp_GetAccount: `, response.toObject());
      return response.toObject();
    })
    .catch((e: Error) => {
      console.log(`idp_GetAccount: `, e, "not logged in or guest");
      if (e.message === "wrong credentials") return null;
      else throw e;
    });
}

export function fetchScores(
  type: "daily" | "seasonal",
  mergedFilters: IFilterItem[]
): Promise<IPlayer[]> {
  const client = new bundle.leaderboards.FeedPromiseClient(...grpcDefault);
  const request = new bundle.leaderboards.Scores.Request();
  const period =
    type === "seasonal"
      ? bundle.leaderboards.Period.SEASONAL
      : bundle.leaderboards.Period.DAILY;
  request.setPeriod(period);

  const filters = new bundle.leaderboards.Filter();
  const mapsList = mergedFilters[0].options //modes
    .filter((i) => i.selected)
    .map((i) => i.key);

  const modesList = mergedFilters[1].options // maps
    .filter((i) => i.selected)
    .map((i) => i.key);
  if (mapsList.length) filters.setMatchMapList(mapsList);
  if (modesList.length) filters.setMatchModeList(modesList);
  // else filters.setMatchModeList([0]);

  request.setFilter(filters);

  return client.scores(request).then((response: any) => {
    let data: IPlayerScores[] = response.toObject().playersList;
    console.log(`debug fetchScores: `, response.toObject());
    data.sort((a, b) => b.stat.experience - a.stat.experience);

    return data.map((item, i) => ({
      id: item.user.id.value,
      rank: i + 1,
      name: item.user.username,
      kills: numberWithCommas(item.stat.kills),
      deaths: numberWithCommas(item.stat.deaths),
      score: numberWithCommas(item.stat.experience),
      assists: numberWithCommas(0),
      kdr: (item.stat.deaths
        ? item.stat.kills / item.stat.deaths
        : item.stat.kills
      ).toFixed(2),
      icon: icons[hash(item.user.username + item.user.suffix)],
    }));
  });
}

export function fetchClans(): IClan[] {
  const client = new bundle.leaderboards.FeedPromiseClient(...grpcDefault);
  const request = new bundle.leaderboards.Clans.Request();
  const period = bundle.leaderboards.Period.SEASONAL;
  request.setPeriod(period);

  return client.clans(request).then((response: any) => {
    const data: IClanResponse = response.toObject();

    console.log(`fetched ClanScores: `, data);
    data.clansList.sort((a, b) => b.experience - a.experience);
    return data.clansList.map((item, i: number) => ({
      id: item.name + " " + item.slug,
      name: item.name,
      score: numberWithCommas(item.experience),
      members: item.membersNum,
      slug: item.slug,
      owner: {
        username: item.owner.username,
        suffix: item.owner.suffix,
        id: item.owner.id.value,
      },
      rank: i + 1,
    }));
  });
}

export function leaderboards_fetchRankAndHistory(
  userId?: string
): Promise<{ dailyRank: number; matchHistory: IMatchHistory[] }> {
  const request = new bundle.leaderboards.Profile.Request();

  const id = new bundle.idp.UUID();
  id.setValue(userId);
  if (userId) request.setUserId(id);
  const client = new bundle.leaderboards.FeedPromiseClient(...grpcDefault);
  return client
    .profile(request)
    .then((response: any) => {
      const data: IRankAndHistoryResp = response.toObject();
      console.log(`leaderboards_fetchRankAndHistory: `, data);

      const matchHistory: IMatchHistory[] = data.matchHistoryList.map(
        (item) => ({
          id: item.startedAt.unixMilli.toString(),
          mode: gameModeToStr(item.mode),
          time: (
            formatDistanceToNow(new Date(item.startedAt.unixMilli)) + " Ago"
          ).replace(/about /g, ""),
          map: item.map,
          rank: item.rank,
          kills: numberWithCommas(item.stat.kills),
          deaths: numberWithCommas(item.stat.deaths),
          experience: numberWithCommas(item.stat.experience),
          server: "none",
          headshots: numberWithCommas(item.stat.headshots),
          status:
            item.status === 2 ? "victory" : item.status === 1 ? "defeat" : "",
        })
      );

      return { dailyRank: data.dailyRank, matchHistory };
    })
    .catch((e: Error) => {
      if (e.message === "not found") {
        console.log(`fetchMatchHistory: not found`);
        return [];
      }
      throw e;
    });
}

export function friends_fetchFriends(userId: string): Promise<IFriendsResp> {
  const client = new bundle.friends.ConnectionsPromiseClient(...grpcDefault);
  const request = new bundle.friends.GetOverview.Request();
  request.setUserId(userId);
  return client.getOverview(request).then((response: any) => {
    const data: any = response.toObject();
    console.log(`friends_fetchFriends: `, data);
    return {
      ...data,
      followingStatus: followingStatusToStr(data.followingStatus),
    };
  });
}

export function friends_manageFriend(
  userId: string,
  type: "add" | "remove"
): Promise<any> {
  const client = new bundle.friends.ConnectionsPromiseClient(...grpcDefault);
  const request =
    type === "add"
      ? new bundle.friends.AddConnection.Request()
      : new bundle.friends.RemoveConnection.Request();
  request.setUserId(userId);
  const operation =
    type === "add" ? client.add.bind(client) : client.remove.bind(client);

  return operation(request).then((response: any) => {
    const data: any = response.toObject();
    console.log(`friends_manageFriend_${type}: `, data);
    return {};
  });
}

export function addFriend(userId: string): Promise<any> {
  return friends_manageFriend(userId, "add");
}

export function removeFriend(userId: string): Promise<any> {
  return friends_manageFriend(userId, "remove");
}

export function clanevents_getCurrentEvent(): Promise<IEvent> {
  const client = new bundle.clanevents.ClanEventPromiseClient(...grpcDefault);
  const request = new bundle.clanevents.GetCurrentEvent.Request();

  return client
    .getCurrentEvent(request)
    .then((response: any) => {
      const data = response.toObject();
      console.log("clanevents_getCurrentEvent", data.event);

      return data.event;
    })
    .catch((e: Error) => {
      if (e.message === "no active event") {
        console.info(
          "clanevents_getCurrentEvent",
          "no current event, getting previous"
        );
      } else {
        console.warn("clanevents_getCurrentEvent", e);
      }
      const prevEventReq = new bundle.clanevents.GetPreviousEvent.Request();
      return client.getPreviousEvent(prevEventReq).then((res: any) => {
        const data: any = res.toObject();
        console.log(`clanevents_getPreviousEvent: `, data.event);
        return data.event;
      });
    });
}

export function clanevents_getPreviousEvent(): Promise<IEvent> {
  const client = new bundle.clanevents.ClanEventPromiseClient(...grpcDefault);
  const req = new bundle.clanevents.GetPreviousEvent.Request();
  return client
    .getPreviousEvent(req)
    .then((res: any) => {
      console.log("clanevents_getPreviousEvent", res.toObject().event);
      return res.toObject().event;
    })
    .catch((e: Error) => {
      console.log("clanevents_getPreviousEvent", e);
    });
}

const inventoryPromiseClient = new bundle.inventory.InventoryPromiseClient(
  ...grpcDefault
);

// Передаём внутрь айдишники айтемов и получаем их данные
export function inventory_getItemMany(
  ids: string[]
): Promise<IItemsNormalized> {
  // @ts-ignore
  return Promise.all(
    ids.map((id) => {
      const request = new bundle.inventory.GetItemRequest();
      request.setId(id);
      return inventoryPromiseClient
        .getItem(request)
        .then((response: any) => {
          return response.toObject().item;
        })
        .catch(() => null);
    })
  ).then((data: Array<IItem | null>) => {
    const _data = normalize(data);
    console.log(`inventory_getItems:`, _data);
    return _data;
  });
}

export function inventory_getItem(id: string): Promise<IItem> {
  const client = new bundle.inventory.InventoryPromiseClient(...grpcDefault);
  const request = new bundle.inventory.GetItemRequest();
  request.setId(id);
  return client
    .getItem(request)
    .then((response: any) => {
      
      return response.toObject().item;
    })
    .catch(() => null);
}

function normalize<T extends { id: string; [x: string]: any } | null>(
  data: Array<T | null>
) {
  return data.reduce((acc, curr) => {
    if (curr === null) return acc;
    acc[curr.id] = curr;
    return acc;
  }, {} as { [x: string]: T });
}

// TOKENS

const tokensPromiseClient = new bundle.cgs.TokensPromiseClient(...grpcDefault);

export function inventory_getTokens(
  tokens: IRewardItemToken[]
): Promise<ITokensNormalized> {
  // @ts-ignore
  return Promise.all(
    tokens.map((token) => {
      const request = new bundle.cgs.GetConfigDescription.Request();
      request.setConfigId(token.configId);
      request.setLevel(token.level);
      return tokensPromiseClient
        .getConfigDescription(request)
        .then((response: any) => {
          console.log(`token:`, response.toObject());
          return { id: token.configId, ...response.toObject() };
        })
        .catch((e: Error) => {
          console.log(`getToken_error`, e);
          return null;
        });
    })
  ).then((data: Array<IItem | null>) => {
    const _data = normalize(data);
    console.log(`inventory_getTokens:`, _data);
    return _data;
  });
}

export function cgs_getConfigDescription(
  id: string,
  lvl?: number
): Promise<IConfigDescription> {
  const request = new bundle.cgs.GetConfigDescription.Request();
  request.setConfigId(id);
  if(lvl) request.setLevel(lvl);
  return tokensPromiseClient
    .getConfigDescription(request)
    .then((response: any) => {
      console.log(`cgs_getConfigDescription:`, response.toObject());
      return response.toObject();
    })
    .catch((e: any) => {
      const log = {
        request: "cgs_getConfigDescription",

        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("cgs_getConfigDescription", log);
      throw e;
    });
}

export type IGetClanLeaderboardParams = {
  clanSlots?: boolean;
  allItems?: boolean;
};

export async function clanevents_getClanLeaderboard(
  event: IEvent,
  params?: IGetClanLeaderboardParams
): Promise<IGetClanLeaderboard[]> {
  const client = new bundle.clanevents.ClanEventPromiseClient(...grpcDefault);
  const request = new bundle.clanevents.GetClanLeaderboard.Request();
  if (event.id) {
    try {
      request.setEventId(event.id);
      if (params) {
        const reqParams = new bundle.clanevents.GetClanLeaderboard.Params();
        // if (params.allItems) reqParams.setWithClanSlots(true);
        if (params.clanSlots) reqParams.setAllItems(true);
        request.setParams(reqParams);
      }
      const response = await client.getClanLeaderboard(request);
      let data: any = response.toObject();
      console.log("clanevents_getClanLeaderboard", data);
      data = data.leaderboardList.map((item: ITierClanResponse) => ({
        ...item,
      }));
      return data;
    } catch (e: any) {
      const log = {
        request: "clanevents_getClanLeaderboard",
        eventName: event.name,
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_getClanLeaderboard", log);
      return [];
    }
  }
  return [];
}

export function clanevents_getIntraClanLB(): Promise<any[]> {
  const client = new bundle.clanevents.ClanEventPromiseClient(...grpcDefault);
  const request = new bundle.clanevents.GetPlayerLeaderboard.Request();

  const eventReq = new bundle.clanevents.GetCurrentEvent.Request();

  return client
    .getCurrentEvent(eventReq)
    .then((res: any) => {
      const currentDate = new Date().valueOf();

      const diffTime =
        res.getEvent().getStartAt().getSeconds() * 1000 - currentDate;
      if (diffTime < 0) return res.getEvent().getId();
      else {
        const prevEventReq = new bundle.clanevents.GetPreviousEvent.Request();
        return client
          .getPreviousEvent(prevEventReq)
          .then((res: any) => res.getEvent().getId());
      }
    })
    .then((id: string) => {
      request.setEventId(id);
      return client.getPlayerLeaderboard(request).then((response: any) => {
        const data: any = response.toObject();
        console.log("clanevents_intraClanLB", data);
        return data.leaderboardList;
      });
    })
    .catch(() => {
      const prevEventReq = new bundle.clanevents.GetPreviousEvent.Request();
      return client
        .getPreviousEvent(prevEventReq)
        .then((res: any) => res.getEvent().getId())
        .then((id: string) => {
          request.setEventId(id);
          return client.getPlayerLeaderboard(request).then((response: any) => {
            const data: any = response.toObject();
            console.log("catch prevoiusEvent clanevents_intraClanLB", data);
            return data.leaderboardList;
          });
        });
    });
}

// Получение всех айтемов игрока
export function inventory_getItems(): Promise<InventoryItems> {
  const client = new bundle.inventory.InventoryPromiseClient(...grpcDefault);
  const request = new bundle.inventory.Empty();

  return client
    .getItems(request)
    .then((response: any) => {
      console.log("inventory_getItems", response.toObject());
  
      // const mintableItems: any = response
      //   .getItemsList()
      //   .filter((item: any) => item.getGenerated()?.getMintStatus()).length;
      // const characters: any = response
      //   .getItemsList()
      //   .filter((item: any) => item.getCategory() === 1).length;
      // const weapons: any = response.getItemsList().filter((item: any) => {
      //   const category = item.getCategory();
      //   return (
      //     category === 2 ||
      //     category === 11 ||
      //     category === 3 ||
      //     category === 5 ||
      //     category === 7 ||
      //     category === 8 ||
      //     category === 10
      //   );
      // }).length;

      return {
        totalItems: response.toObject()?.itemsList,
        mintableItems:0,
        characters:0,
        weapons:0,
      };
    })
    .catch((e: Error) => {
      console.log("inventory_getItems", e);
    });
}

export function staking_getSlots(eventId: string): Promise<ISlots> {
  const client = new bundle.staking.StakingPromiseClient(...grpcDefault);
  const request = new bundle.staking.GetSlots.Request();
  request.setEventId(eventId);
  return client
    .getSlots(request)
    .then((res: any) => {
      console.log("staking_getSlots", res.toObject());
      return res.toObject();
    })
    .catch((error: any) => {
      const log = {
        request: "staking_getSlots",
        params: request.toObject(),
        error: { message: error.message, code: error.code },
      };
      console.warn("staking_getSlots", log);
    });
}

export function cgs_getTokenMetadata(id: string): Promise<ITokenMetadata> {
  const client = new bundle.cgs.TokensPromiseClient(...grpcDefault);
  const request = new bundle.cgs.GetTokenMetadata.Request();
  const tokenId = new bundle.cgs.UUID();
  tokenId.setUuid(id);
  request.setTokenId(tokenId);
  return client
    .getMetadata(request)
    .then((response: any) => {
      const data: any = response.toObject();
      return data.metadata;
    })
    .catch((e: Error) => {
      console.log("tokenId", id);
      console.log("getTokenMetadata", e);
    });
}

export function cgs_getCollectionName(id: string): Promise<ITokenMetadata> {
  const client = new bundle.cgs.TokensPromiseClient(...grpcDefault);
  const request = new bundle.cgs.GetTokenMetadata.Request();
  const tokenId = new bundle.cgs.UUID();
  tokenId.setUuid(id);
  request.setTokenId(tokenId);
  return client
    .getMetadata(request)
    .then((response: any) => {
      const name: any = response.getMetadata().getCollection().getName();
      return name;
    })
    .catch((e: Error) => console.log(e));
}

export function getMetadataCollection(
  ids: string[]
): Promise<IMetadataCollection[]> {
  const client = new bundle.cgs.TokensPromiseClient(...grpcDefault);

  return Promise.all(
    ids.map((id) => {
      const request = new bundle.cgs.GetTokenMetadata.Request();
      const tokenId = new bundle.cgs.UUID();
      tokenId.setUuid(id);
      request.setTokenId(tokenId);
      return client
        .getMetadata(request)
        .then((response: any) => {
          return {
            name: response.getMetadata().getName(),
            collectionName: response.getMetadata().getCollection().getName(),
            id: id,
          };
        })
        .catch((e: Error) => console.log(e.message));
    })
  );
}

export function staking_getSlotId(
  item_id: string,
  slot_set_id: string,
  slot_number: number
): Promise<any> {
  const client = new bundle.staking.StakingPromiseClient(...grpcDefault);
  const request = new bundle.staking.StakeItem.Request();

  request.setItemId(item_id);
  request.setSlotSetId(slot_set_id);
  request.setSlotNumber(slot_number);

  return client
    .stakeItem(request)
    .then((response: any) => {
      const data: any = response.toObject();
      console.log(`staking_stakeItem:`, data);
      return data;
    })
    .catch((e: Error) => {
      console.log(`item_id: `, item_id);
      console.log(`slot_set_id: `, slot_set_id);
      console.log(`slot_number: `, slot_number);
      console.log(e);
      throw e;
    });
}

export function staking_setSlotStatus(
  id: string,
  transaction: string,
  status: ISlotStatus
): Promise<any> {
  const client = new bundle.staking.StakingPromiseClient(...grpcDefault);
  const request = new bundle.staking.SetSlotStatus.Request();
  request.setId(id);
  request.setStatus(mapStakingStatusToValue(status));
  request.setTransaction(transaction);

  return client
    .setSlotStatus(request)
    .then((response: any) => {
      const data: any = response.toObject();
      console.log(`staking_setSlotStatus:`, data);
      return data;
    })
    .catch((e: Error) => {
      console.log(`id: `, id);
      console.log(`transaction: `, transaction);
      console.log(`status: `, mapStakingStatusToValue(status) + ` (${status})`);
      console.log(e);
      throw e;
    });
}

export function clanevents_getClanEventInfo(
  eventId: string
): Promise<ClanEventInfo> {
  const eventClient = new bundle.clanevents.ClanEventPromiseClient(
    ...grpcDefault
  );
  const request = new bundle.clanevents.GetClanEventInfo.Request();
  request.setEventId(eventId);
  return eventClient
    .getClanEventInfo(request)
    .then((res: any) => {
      console.log("clanevents_getClanEventInfo", res.toObject());
      return res.toObject();
    })
    .catch((e: Error) => {
      const log = {
        request: "clanevents_getClanEventInfo",
        params: { eventId },
        error: e.message,
      };
      console.warn("clanevents_getClanEventInfo", log);
      throw e;
    });
}

export function offchain_wallets_getButterBalance(): Promise<number> {
  const client = new bundle.offchain_wallets.WalletsPromiseClient(
    ...grpcDefault
  );
  const request = new bundle.offchain_wallets.GetBalance.Request();

  request.setType("BUTTER");
  return client
    .getBalance(request)
    .then((res: any) => {
      greenLog(res.toObject(), "offchain_wallets_getButterBalance");
      return res.toObject().amount;
    })
    .catch((e: any) => {
      const log = {
        request: "offchain_wallets_getButterBalance",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("offchain_wallets_getButterBalance", log);
      return undefined;
    });
}

export function unlockSlot(slotID: string): Promise<any> {
  const client = new bundle.staking.StakingPromiseClient(...grpcDefault);

  const request = new bundle.staking.Unlock.Request();
  request.setRequestId(uuidv4());
  request.setSlotSetId(slotID);

  return client.unlock(request).then((res: any) => res.toObject());
}

export function wallets_getBoundWallet(): Promise<BoundedWallet> {
  const client = new bundle.wallets.ConnectServicePromiseClient(...grpcDefault);
  const request = new bundle.wallets.GetBoundWallet.Request();
  return client
    .getBoundWallet(request)
    .then((response: any) => {
      const data: any = response.toObject();
      console.log(`wallets_getBoundWallet:`, data.wallet);
      return data.wallet;
    })
    .catch((e: Error) => {
      console.log(e);
      throw e;
    });
}

export function fflags_getUserFeatures(): Promise<FeatureFlag[]> {
  const client = new bundle.fflags.FeatureFlagsPromiseClient(...grpcDefault);
  const request = new bundle.fflags.Empty();
  return client
    .getUserFeatures(request)
    .then((response: any) => {
      const data: any = response.toObject();
      console.log(`fflags_getUserFeatures:`, data.featuresList);
      return data.featuresList;
    })
    .catch((e: Error) => {
      console.log(`fflags_getUserFeatures:`, e);
      return [];
    });
}

export type IStatPanelData = {
  currentTurn: number;
  totalTurns: number;
  turnEndIn: number;
  buildPointsBalance: number;
  butterBalance: number;
  uraniumCost: number;
  uraniumBalance: number;
  uraniumCostChange: number;
  oilRig: number;
  oilRigCost: number;
  oilRigPlanned: number;
  missileSilo: number;
  missileSiloPlanned: number;
  missileSiloCost: number;
  ironDome: number;
  ironDomePlanned: number;
  ironDomeCost: number;
  cityShield: number;
  cityShieldPlanned: number;
  cityShieldCost: number;
  defenceSummary: Array<{ text: string; positive: boolean }>;
  offenceSummary: Array<{ text: string; positive: boolean }>;
};
const wd_stats: IStatPanelData = {
  currentTurn: 1,
  totalTurns: 32,
  turnEndIn: 50_000,
  buildPointsBalance: 5_000_000,
  butterBalance: 6_000_000,
  uraniumCost: 428,
  uraniumBalance: 1000,
  uraniumCostChange: -0.3,
  oilRig: 1,
  oilRigPlanned: 1,
  oilRigCost: 4_000_000,
  missileSilo: 10,
  missileSiloPlanned: 0,
  missileSiloCost: 4_000_000,
  ironDome: 0,
  ironDomePlanned: 0,
  ironDomeCost: 4_000_000,
  cityShield: 7,
  cityShieldPlanned: 1,
  cityShieldCost: 5000,
  defenceSummary: [
    { text: "9 inbound strikes repelled", positive: true },
    { text: "2 inbound strikes lost", positive: false },
    { text: "321,005 points lost", positive: false },
  ],
  offenceSummary: [],
};

export function wd_getStats(): Promise<IStatPanelData> {
  return new Promise((res) => res(wd_stats));
}

export function clanevents_getLeaderboardViewEvent(): Promise<IEvent> {
  const client = new bundle.clanevents.ClanEventPromiseClient(...grpcDefault);
  const request = new bundle.clanevents.GetLeaderboardViewEvent.Request();
  return client
    .getLeaderboardViewEvent(request)
    .then((response: any) => {
      const data: any = response.toObject();
      console.log(`clanevents_getLeaderboardViewEvent:`, data.event);
      return data.event;
    })
    .catch((e: any) => {
      const log = {
        request: "clanevents_getLeaderboardViewEvent",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_getLeaderboardViewEvent", log);
      return [];
    });
}

export function clanevents_getClanAssets(
  eventId: string
): Promise<IGetClanAssets> {
  const client = new bundle.clanevents.WorldDominationPromiseClient(
    ...grpcDefault
  );
  const request = new bundle.clanevents.GetClanAssets.Request();
  request.setEventId(eventId);

  return client
    .getClanAssets(request)
    .then((response: any) => {
      const data: any = response.toObject();
      console.log(`clanevents_getClanAssets:`, data);
      return data;
    })
    .catch((e: any) => {
      const log = {
        request: "clanevents_getClanAssets",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_getClanAssets", log);
      throw e;
    });
}

export function clanevents_getEventTurn(
  eventId: string
): Promise<EventTurnRaw> {
  const client = new bundle.clanevents.WorldDominationPromiseClient(
    ...grpcDefault
  );
  const request = new bundle.clanevents.GetEventTurn.Request();
  request.setEventId(eventId);

  return client
    .getEventTurn(request)
    .then((response: any) => {
      const data: any = response.toObject();
      console.log(`clanevents_getEventTurn:`, data);
      return data;
    })
    .catch((e: any) => {
      const log = {
        request: "clanevents_getEventTurn",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_getEventTurn", log);
      throw e;
    });
}

export function clanevents_getRoundQueue(): Promise<IRoundQueue> {
  const client = new bundle.clanevents.WorldDominationPromiseClient(
    ...grpcDefault
  );
  const request = new bundle.clanevents.GetRoundQueue.Request();
  return client
    .getRoundQueue(request)
    .then((response: any) => {
      const rawData: IGetRoundQueueRaw = response.toObject();
      console.log(`clanevents_getRoundQueueRaw:`, rawData);
      const data: IRoundQueue = {
        turnId: rawData.turnId,
        nuke: [],
        oilRig: [],
        ironDome: [],
        missileSilo: [],
        cityShield: [],
        attackCityByNuke: [],
      };
      rawData.itemsList.forEach((item) => {
        const newItem = {
          id: item.id,
          count: item.dataCount,
        };

        if (item.data.buildNuke) data.nuke.push(newItem);
        if (item.data.buildMissileSilo) data.missileSilo.push(newItem);
        if (item.data.buildOilRig) data.oilRig.push(newItem);
        if (item.data.buildIronDome) data.ironDome.push(newItem);
        if (item.data.buildCityShield)
          data.cityShield.push({
            ...newItem,
            cityId: item.data.buildCityShield.cityId,
          });
        if (item.data.attackCityByNuke) {
          data.attackCityByNuke.push({
            ...newItem,
            cityId: item.data.attackCityByNuke.cityId,
            nukeId: item.data.attackCityByNuke.nukeId,
          });
        }
      });
      console.log(`clanevents_getRoundQueue:`, data);
      return data;
    })
    .catch((e: any) => {
      const log = {
        request: "clanevents_getRoundQueue",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_getRoundQueue", log);
      throw e;
    });
}

export function clanevents_addToRoundQueue(arg: IAddToQueue): Promise<unknown> {
  const client = new bundle.clanevents.WorldDominationPromiseClient(
    ...grpcDefault
  );

  const request = new bundle.clanevents.AddToRoundQueue.Request();
  const itemData = new bundle.clanevents.RoundQueue.ItemData();

  const uuid = arg.requestId || uuidv4();

  if (arg.type === "attackCity") {
    const attack = new bundle.clanevents.RoundQueue.AttackCityByNuke();
    attack.setCityId(arg.cityId);
    attack.setClanId(arg.clanId);
    itemData.setAttackCityByNuke(attack);
  }
  if (arg.type === "cityShield") {
    const buildCityShield = new bundle.clanevents.RoundQueue.BuildCityShield();
    buildCityShield.setCityId(arg.cityId);
    itemData.setBuildCityShield(buildCityShield);
  }
  if (arg.type === "nuke") {
    itemData.setBuildNuke(true);
  }

  if (arg.type === "missileSilo") {
    itemData.setBuildMissileSilo(true);
  }

  if (arg.type === "oilRig") {
    itemData.setBuildOilRig(true);
  }
  if (arg.type === "ironDome") {
    itemData.setBuildIronDome(true);
  }

  if (arg.type === "uranium") {
    itemData.setBuyUranium(arg.amount);
  }

  request.setItemData(itemData);
  request.setCurrency(arg.currency);
  request.setRequestId(uuid);
  request.setTurnId(arg.turnId);
  request.setItemDataCount(arg.amount);
  greenLog(request.toObject(), "Request addToRoundQueue");
  return client
    .addToRoundQueue(request)
    .then(() => true)
    .catch((e: any) => {
      const log = {
        request: "clanevents_addToRoundQueue",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_addToRoundQueue", log);
      return false;
    });
}

export function clanevents_removeFromRoundQueue(
  arg: IRemoveFromQueue
): Promise<unknown> {
  const client = new bundle.clanevents.WorldDominationPromiseClient(
    ...grpcDefault
  );
  console.log(arg.itemIds);
  const uuid = uuidv4();
  const request = new bundle.clanevents.RemoveFromRoundQueue.Request();
  request.setItemIdsList(arg.itemIds);
  request.setTurnId(arg.turnId);
  request.setRequestId(uuid);
  return client
    .removeFromRoundQueue(request)
    .then()
    .catch((e: any) => {
      const log = {
        request: "clanevents_removeFromRoundQueue",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_removeFromRoundQueue", log);
      throw e;
    });
}

export function clantreasury_donate(amount: number): Promise<EventTurnRaw> {
  const client = new bundle.clantreasury.OperationsPromiseClient(
    ...grpcDefault
  );
  const request = new bundle.clantreasury.Donate.Request();
  const item = new bundle.clantreasury.Item();
  const itemInfo = new bundle.clantreasury.Item.Info();

  itemInfo.setButter(true);
  item.setAmount(amount);
  item.setInfo(itemInfo);
  request.setItem(item);
  request.setReasonId(uuidv4());
  return client
    .donate(request)
    .then((response: any) => {
      const data: any = response.toObject();
      console.log(`clantreasury_donate:`, data);
      return data;
    })
    .catch((e: any) => {
      const log = {
        request: "clantreasury_donate",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clantreasury_donate", log);
      throw e;
    });
}

export function clanevents_getProductPrice(
  arg: IGetProductPriceParams
): Promise<IGetProductPrice> {
  const client = new bundle.clanevents.WorldDominationPromiseClient(
    ...grpcDefault
  );

  const request = new bundle.clanevents.GetProductPrice.Request();
  const itemData = new bundle.clanevents.RoundQueue.ItemData();
  const buildCityShield = new bundle.clanevents.RoundQueue.BuildCityShield();
  buildCityShield.setCityId(arg.cityId);
  itemData.setBuildCityShield(buildCityShield);

  request.setEventId(arg.eventId);
  request.setItemData(itemData);
  request.setItemDataAmount(arg.amount);
  request.setTurnId(arg.turnId);
  request.setItemData(itemData);
  request.setCurrency(arg.currency);
  return client
    .getProductPrice(request)
    .then((resp: any) => {
      console.log("clanevents_getProductPrice", resp.toObject());
      return resp.toObject();
    })
    .catch((e: any) => {
      const log = {
        request: "clanevents_getProductPrice",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_getProductPrice", log);
      return undefined;
    });
}

export function clanevents_getRestrictions(): Promise<QueueRestrictions> {
  const client = new bundle.clanevents.WorldDominationPromiseClient(
    ...grpcDefault
  );

  const request = new bundle.clanevents.GetRestrictions.Request();
  request.setRole("OFFICER");

  return client
    .getRestrictions(request)
    .then((resp: any) => {
      console.log("clanevents_getRestrictions", resp.toObject());
      return resp.toObject().queueRestrictions;
    })
    .catch((e: any) => {
      const log = {
        request: "clanevents_getRestrictions",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_getRestrictions", log);
      return undefined;
    });
}

export function clanevents_getUserRestrictions(): Promise<IUserRestrictions> {
  const client = new bundle.clanevents.WorldDominationPromiseClient(
    ...grpcDefault
  );

  const request = new bundle.clanevents.GetUserRestrictions.Request();
  return client
    .getUserRestrictions(request)
    .then((resp: any) => {
      console.log("clanevents_getUserRestrictions", resp.toObject());
      return resp.toObject();
    })
    .catch((e: any) => {
      const log = {
        request: "clanevents_getUserRestrictions",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_getUserRestrictions", log);
      throw e;
    });
}

export function clanevents_setRestrictions(props: {
  buildPointsLimit?: number;
  butterLimit?: number;
  uraniumLimit?: number;
  canAttack?: boolean;
}): Promise<IUserRestrictions> {
  const client = new bundle.clanevents.WorldDominationPromiseClient(
    ...grpcDefault
  );
  console.log(`props: `, props);

  const request = new bundle.clanevents.SetRestrictions.Request();
  const queueRestrictions = new bundle.clanevents.QueueRestrictions();
  const buildPointsLimit_ =
    new bundle.clanevents.QueueRestrictions.FundLimits();
  const butterPointsLimit_ =
    new bundle.clanevents.QueueRestrictions.FundLimits();
  const uraniumPointsLimit_ =
    new bundle.clanevents.QueueRestrictions.FundLimits();

  if (props.buildPointsLimit === undefined) {
    buildPointsLimit_.setUnlimited(true);
    buildPointsLimit_.setLimit(0);
  } else {
    buildPointsLimit_.setUnlimited(false);
    buildPointsLimit_.setLimit(props.buildPointsLimit);
  }

  if (props.butterLimit === undefined) {
    butterPointsLimit_.setUnlimited(true);
    butterPointsLimit_.setLimit(0);
  } else {
    butterPointsLimit_.setUnlimited(false);
    butterPointsLimit_.setLimit(props.butterLimit);
  }

  if (props.uraniumLimit === undefined) {
    uraniumPointsLimit_.setUnlimited(true);
    uraniumPointsLimit_.setLimit(0);
  } else {
    uraniumPointsLimit_.setUnlimited(false);
    uraniumPointsLimit_.setLimit(props.uraniumLimit);
  }

  queueRestrictions.setBuildpoints(buildPointsLimit_);
  queueRestrictions.setButter(butterPointsLimit_);
  queueRestrictions.setUranium(uraniumPointsLimit_);
  queueRestrictions.setCanAttack(props.canAttack);

  request.setQueueRestrictions(queueRestrictions);
  request.setRole("OFFICER");

  return client
    .setRestrictions(request)
    .then((resp: any) => {
      console.log("clanevents_setRestrictions", resp.toObject());
      return resp.toObject();
    })
    .catch((e: any) => {
      const log = {
        request: "clanevents_setRestrictions",
        params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_setRestrictions", log);
      throw e;
    });
}

export function clans_info(): Promise<IClanInfo2> {
  const client = new bundle.clans.ClansPromiseClient(...grpcDefault);
  const request = new bundle.clans.Empty();

  // const uuid = new bundle.clans.UUID()
  // uuid.setValue("c07f5469-38df-47b6-ab24-f150c6ae8a14")
  // request.setId(uuid)

  return client
    .info(request)
    .then((resp: any) => {
      const data = resp.toObject();
      console.log("clans_info", resp.toObject());

      return { clan: data.clan, role: mapNumToRole(data.role) };
    })
    .catch((e: any) => {
      const log = {
        request: "clans_info",
        // params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("clans_info", log);
      throw e;
    });
}

export function shards_getAllRecipes(): Promise<IAllRecipes> {
  const client = new bundle.shards.ShardsPromiseClient(...grpcDefault);
  const request = new bundle.shards.GetAllRecipes();
  return client
    .getAllRecipes(request)
    .then((resp: any) => {
      const data = resp.toObject();
      // console.log("shards_getAllRecipes", resp.toObject());
      console.log("shards_getAllRecipes", normalizeArr(data.recipesList, "id"));
      return normalizeArr(data.recipesList, "id");
    })
    .catch((e: any) => {
      const log = {
        request: "shards_getAllRecipes",
        // params: request.toObject(),
        error: { message: e.message, code: e.code },
      };
      console.warn("shards_getAllRecipes", log);
      throw e;
    });
}

export type IIndividualLBItem = {
  economicScore: number;
  id: string;
  militaryScore: number;
  orbsDonated: number;
  rank: number;
  totalScore: number;
};

export function clanevents_getIndividualPlayerLeaderboard(
  eventId: string
): Promise<IIndividualLBItem[]> {
  const client = new bundle.clanevents.ClanEventPromiseClient(...grpcDefault);
  const req = new bundle.clanevents.GetIndividualPlayerLeaderboard.Request();
  req.setEventId(eventId);
  console.log("req.toObject()", req.toObject());

  return client
    .getIndividualPlayerLeaderboard(req)
    .then((resp: any) => {
      const data = resp.toObject();
      console.log("clanevents_getIndividualPlayerLeaderboard", data);
      return data.leaderboardList;
    })
    .catch((e: any) => {
      const log = {
        request: "clanevents_getIndividualPlayerLeaderboard",
        params: { eventId },
        error: { message: e.message, code: e.code },
      };
      console.warn("clanevents_getIndividualPlayerLeaderboard", log);
      throw e;
    });
}

type IAttrKey =
'Agility' |
'Alignment' |
'Allegiance' |
'Birthplace' |
'Drop Type' |
'Eyes' |
'Face' |
'Hairstyle' |
'Hands' |
'Headgear' |
'Image Full Size URL' |
'Image Thumbnail URL' |
'Intellect' |
'Item Type' |
'Legs' |
'Level' |
'Luck' |
'Negative Quirk' |
'Personality' |
'Pet' |
'Positive Quirk' |
'Power Score' |
'Primary Name' |
'Rarity' |
'Relationship Status' |
'Season' |
'Specialty' |
'Strength' |
'Top' |
'Toughness' |
'Vitality' |
'Zodiac' |
'_curr_level_points' |
'_min_mint_level' |
'_next_level_points' |
'_points'


type IAttribute = {
  displayType: string
  hidden: boolean
  name: IAttrKey
  value: {string: string, int64: number, pb_double: number}
}

type IAttributes = {
  [x in IAttrKey]:IAttribute
} & {id:string}

export function attributes_getItemAttributes(
  tokenId: string
): Promise<IAttributes> {
  const client = new bundle.attributes.ItemsAttributesPromiseClient(...grpcDefault);
  const request = new bundle.attributes.GetAttributes.Request();
  const uuid = new bundle.attributes.UUID();
  uuid.setUuid(tokenId);
  request.setTokenId(uuid);
  
  return client
  .getAttributes(request)
  .then((resp: any) => {
    const data = resp.toObject();
    console.log("attributes_getItemAttributes", data);
    const result = data.attrsList.reduce((acc: IAttributes,item:IAttribute)=>{
      acc[item.name] = item
      return acc
    },{} as IAttributes)
    return result;
  })
  .catch((e: any) => {
    const log = {
      request: "attributes_getItemAttributes",
      params: { tokenId },
      error: { message: e.message, code: e.code },
    };
    console.warn("attributes_getItemAttributes", log);
    throw e;
  });
}

export type IItemsAttributes = {
  [x:string]:IAttributes
}
export function attributes_getItemsAttributes(
  tokenIds: string[]
): Promise<IItemsAttributes> {
  const client = new bundle.attributes.ItemsAttributesPromiseClient(...grpcDefault);
  return Promise.all(
    tokenIds.map(tokenId=>{
      const request = new bundle.attributes.GetAttributes.Request();
      const uuid = new bundle.attributes.UUID();
      uuid.setUuid(tokenId);
      request.setTokenId(uuid);
      return client
      .getAttributes(request)
      .then((resp: any) => {
        const data = resp.toObject();
        // console.log("data", data);
        
  
        const result = data.attrsList.reduce(
          (acc: IAttributes, item: IAttribute) => {
            acc[item.name] = item;
            return acc;
          },
          { id: tokenId } as unknown as IAttributes
        );
        return result;
      })
      .catch((e: any) => {
        const log = {
          request: "attributes_getItemsAttributes",
          params: { tokenId },
          error: { message: e.message, code: e.code },
        };
        console.warn("clanevents_getIndividualPlayerLeaderboard", log);
        throw e;
      });
    })).then((data:IAttributes[])=>data.reduce((acc,item)=>{
      acc[item.id] = item
    return acc
  },{} as any))
}
