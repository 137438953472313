import axios from "axios";
import { serverGW } from "config";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: serverGW,
});

export const reportUser = (
  userName: string
): Promise<true | false | undefined> => {
  let formData = new FormData();
  formData.append("username", userName);

  return axiosInstance({
    url: "?request=report_user",
    method: "post",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(({ data }: { data: any }) => {
    console.log(`gateway reportUserResult: `, data);
    return data.success;
  });
};

export type IGWClan = {
  id: number;
  clan_name: string;
  short_name: string;
  members: number;
};


export interface IGetClanDetailsResult {
  success: boolean;
  clan_name: string;
  slug: string;
  social_link: string;
  is_private: number;
  members: Member[];
}

export interface Member {
  user_id: number;
  username: string;
  level: number;
  experience: number;
  verified: number;
  active_skin: string;
  last_login: string;
  approved: number;
  today_experience: number;
}


export const fetchCanJoinClan = (
  slug: string
): Promise<{ success: boolean; message: string }> => {
  let formData = new FormData();
  formData.append("clan_slug", slug);

  return axiosInstance({
    url: "?request=can_join_clan",
    method: "post",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(({ data }: { data: any }) => {
    console.log(`gateway fetchCanJoinClan: `, data);
    return data;
  });
};

export const joinClan = (
  slug: string
): Promise<{ success: boolean; message: string }> => {
  let formData = new FormData();
  formData.append("clan_slug", slug);

  return axiosInstance({
    url: "?request=join_clan_code",
    method: "post",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(({ data }: { data: any }) => {
    console.log(`gateway fetchCanJoinClan: `, data);
    return data;
  });
};



