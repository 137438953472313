import { IIndividualLBItem } from "api";
import { IEvent, IRewardListEntry } from "api/api.types";
import { I_MobTierClan } from "components/TieredLeaderboard/componetns/IndividualLB/I_MobTierClan";
import * as s from "components/TieredLeaderboard/componetns/Mob.styled";
import { MobRewards } from "components/TieredLeaderboard/componetns/MobRewards";
import { Place } from "components/TieredLeaderboard/componetns/Place";

export function I_MobTierItem({
  players,
  event,
  item,
}: {
  players: IIndividualLBItem[];
  event: IEvent;
  item: IRewardListEntry;
}) {
  const playersFiltered = players.filter(
    (player) =>
      player.rank >= item.placeFromInclusive &&
      player.rank < item.placeToExclusive
  );
  if (playersFiltered.length === 0) return null;
  return (
    <s.Tier key={item.tier} tier={item.tier}>
      <Place event={event} place={item.tier} />
      <div className="clans">
        {playersFiltered.map((player) => (
          <I_MobTierClan key={player.id} player={player} />
        ))}
      </div>
      <MobRewards
        rewards={event.rewardsList[item.tier - 1].rewardsList}
      />
    </s.Tier>
  );
}

