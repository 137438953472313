import clanWarBg from "components/Staking/StakeForEventPoints/assets/clanWarBg.png";
import infoIcon from "components/Staking/StakeForEventPoints/assets/infoIcon.svg";
import { IEventStatus } from "redux/slices/eventPointsSlice";
import styled from "styled-components/macro";

export const Wrapper = styled.div`
  max-width: 1110px;
  margin: 35px auto 0;
`;

export const HeadWrapper = styled.div`
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 15px 30px 35px;
`;
export const HeadTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;

  & > .title {
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    text-transform: capitalize;
    color: #ffffff;
  }
`;

export const Blocks = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1.2fr 1fr;

  & > div {
    & > div:not(.title) {
      position: relative;

      height: 468px;
    }

    & > .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      color: #ffb60c;
      margin-bottom: 20px;
    }
  }
`;

export const Event = styled.div<{ bg?: string; status?: IEventStatus }>`
  // серым становится только фон
  background-image: ${({ status }) =>
      status?.status === "past" && "linear-gradient(black, black),"}
    ${({ bg }) => `url("${bg}")`};
  ${({ status }) =>
    status?.status === "past" && "background-blend-mode: saturation;"}

  background-size: 100% 100%;
  position: relative;
  border: 1px solid hsl(0 0% 20% / 1);
  border-radius: 4px;
  & .top {
    margin-top: 21px;
    text-align: center;
  }
  & .name {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
  }

  & .ends {
    white-space: nowrap;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-weight: 350;
    font-size: 16px;
    color: #ffffff;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

    & .time {
      font-weight: 500;
      font-size: 16px;
      color: #ffb60c;
    }
  }

  & .clanWar {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 218px;
    height: 57px;
    background: url("${clanWarBg}");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;

    & > img {
      margin-top: 14px;
      width: 173px;
      height: 36px;
    }
  }

  & .stats {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    gap: 30px;

    & > div {
      & > img {
        height: 20px;
      }

      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #ffb60c;
    }
  }
`;

export const Bonuses = styled.div`
  padding: 22px 17px;
  display: grid;
  grid-column-gap: 13px;
  grid-row-gap: 27px;
  grid-template-columns: min-content max-content;
  background: #1f1f1f;
  border: 1px solid hsl(0 0% 20% / 1);
  border-radius: 4px;
  overflow-y: hidden;

  & .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(255, 255, 255, 0.2);
  }

  & .information {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    /* justify-content: space-evenly; */
  }

  & .white {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    max-width: 240px;
    margin-bottom: 8px;
  }

  & .yellow {
    font-weight: 350;
    font-size: 14px;
    line-height: 17px;
    color: #ffb60c;
    max-width: 220px;
  }
`;

export const RarityBonuses = styled.div`
  padding: 20px 17px 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1f1f1f;
  border: 1px solid hsl(0 0% 20% / 1);
  border-radius: 4px;
  overflow-y: hidden;

  & .information {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    /* justify-content: space-evenly; */
  }

  & .white {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    max-width: 240px;
    margin-bottom: 8px;
  }

  & .yellow {
    font-weight: 350;
    font-size: 14px;
    line-height: 17px;
    color: #ffb60c;
    max-width: 220px;
  }
`;

export const Contribution = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  & .item {
    background: #1f1f1f;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 23px 20px 0 20px;
    text-align: center;
    position: relative;
    height: 196px;

    & .count {
      margin-top: 14px;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      text-transform: uppercase;
      color: #ffb60c;
    }

    & .text {
      margin-top: 2px;
      font-weight: 350;
      font-size: 14px;
      line-height: 17px;
      color: #838383;
    }
  }
`;

export const InfoIcon = styled.img.attrs({
  src: infoIcon,
})`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const TooltipContent = styled.div<{ maxWidth?: string }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
  max-width: ${({ maxWidth }) => maxWidth || "350px"};
  background-color: black;
  border-radius: 5px;
`;

export const Link = styled.a`
  color: white;
  &:visited {
    color: white;
  }
`;

export const ClanPerformanceItem = styled.div`
  display: flex;
  flex-direction: column;
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  align-items: center;
  flex: 1 1 40px;
  padding-top: 38px;
  height: 196px;
`;
