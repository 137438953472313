import * as s from "components/IntraClan/SubmenuTired/sumbenuTired.styled";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";

export function SubmenuTired() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const pathname = useLocation().pathname;

  const navigate = useNavigate();
  return (
    <s.Wrap>
      <s.Menuitem
        onClick={() => navigate("/clanWars/tieredLB/clan")}
        active={pathname.includes("/clanWars/tieredLB/clan")}
      >
        Clan
      </s.Menuitem>
      <s.Menuitem
        onClick={() => navigate("/clanWars/tieredLB/individual")}
        active={pathname.includes("/clanWars/tieredLB/individual")}
      >
        Individual
      </s.Menuitem>
    </s.Wrap>
  );
}
