import { attributes_getItemAttributes } from "api";
import * as s from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import { TooltipContent } from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import { useSwrImmutable } from "hooks/useSwr";
import { IItem, IToltipData } from "types/stakingTypes";
import { mapRarityStrToNum } from "utils/mappers/mapRarityStrToNum";
import { MyTooltip } from "../../MyTooltip";

export function ItemView(props: {
  rarity?: number;
  currentItem?: IItem;
  mintable?: boolean;
  boost?: boolean;
  tooltipData?: IToltipData;
  src?: string;
  className?: string;
  onClick?: () => void;
  pointer?: boolean;
  w?: number;
  h?: number;
}) {
  let [itemAttrs] = useSwrImmutable(
    !props.src && props.currentItem
      ? [props.currentItem.id, "itemAttrs"]
      : null,
    attributes_getItemAttributes
  );
  const attrSrc = itemAttrs?.["Image Thumbnail URL"].value.string;
  const attrRarity = mapRarityStrToNum(itemAttrs?.Rarity.value.string);
  return (
    <s.ItemWrap
      w={props.w}
      h={props.h}
      pointer={props.pointer}
      rarity={props.rarity || attrRarity || 0}
      className={props.className}
      onClick={props.onClick}
      data-id={props?.currentItem?.id}
      data-token-mint={props?.currentItem?.generated?.tokenMint}
    >
      <img src={props.src || attrSrc} />
      <div className="upsBlock">
        {props.boost && <s.StakingBoost />}
        {props.tooltipData && (
          <MyTooltip
            visible={!!props?.tooltipData?.totalPointsRate}
            overlay={
              <TooltipContent maxWidth={"350px"}>
                <div>
                  <span style={{ color: "#FFB60C" }}>
                    {props?.tooltipData?.status}
                  </span>{" "}
                  = {props?.tooltipData?.nft} points/hr
                </div>
                {props?.tooltipData?.itemBonuses?.map((item: any) => (
                  <div key={item?.name}>
                    <span style={{ color: "#FFB60C" }}>{item?.name}</span> ={" "}
                    {item?.description}
                  </div>
                ))}
                <div style={{ marginTop: "16px" }}>
                  <span>TOTAL POINTS PER HOUR</span> ={" "}
                  <span style={{ color: "#FFB60C" }}>
                    {props?.tooltipData?.totalPointsRate} points/hr
                  </span>
                </div>
              </TooltipContent>
            }
          >
            <s.Mintable />
          </MyTooltip>
        )}
      </div>
    </s.ItemWrap>
  );
}
