import { IEvent, IRewardListEntry } from "api/api.types";
import { filterClansToTier } from "components/TieredLeaderboard/componetns/helpers";
import * as s from "components/TieredLeaderboard/componetns/Mob.styled";
import { MobRewards } from "components/TieredLeaderboard/componetns/MobRewards";
import { MobTierClan } from "components/TieredLeaderboard/componetns/MobTierClan";
import { Place } from "components/TieredLeaderboard/componetns/Place";

export function MobTierItem({
  clans,
  event,
  item,
}: {
  clans: IGetClanLeaderboard[];
  event: IEvent;
  item: IRewardListEntry;
}) {
  const clansFiltered = clans.filter((clan) => filterClansToTier(item, clan));
  if (clansFiltered.length === 0) return null;
  return (
    <s.Tier key={item.tier} tier={item.tier}>
      <Place event={event} place={item.tier} />
      <div className="clans">
        {clansFiltered.map((clan) => (
          <MobTierClan key={clan.id} clan={clan} />
        ))}
      </div>
      <MobRewards rewards={event.rewardsList[item.tier - 1].rewardsList} />
    </s.Tier>
  );
}

