import { IIndividualLBItem } from "api";
import economic from "assets/economic-point-icon-grey.png";
import arrow from "assets/filterArrow.svg";
import military from "assets/military-point-icon-grey.png";
import total from "components/TieredLeaderboard/assets/totalWhite.png";
import * as s from "components/TieredLeaderboard/componetns/Mob.styled";
import { usePlayer } from "hooks/usePlayer";
import { useState } from "react";
import styled, { css } from "styled-components/macro";
import fullwStatBg from "../../../../assets/stat-bg.png";
import { Gap } from "../../../Gap";

export function I_MobTierClan({
  player: {
    id,
    economicScore,
    militaryScore,
    rank,
    totalScore,
  },
}: {
  player: IIndividualLBItem;
}) {
  const [open, setOpen] = useState(false);
  const player = usePlayer(id)
  return (
    <>
      <s.TierItem>
        <div>
          <div>{rank}</div>
          <div>{player?.name}</div>
        </div>
        <div>
          {player?.clan?.name}
        </div>

        <div onClick={() => setOpen(!open)}>
          <TableArrowMob open={open} />
        </div>
      </s.TierItem>
      <HiddenStats open={open}>
        <Gap h={3} />
        <StatsItemCW>
          <div>
            <StatsItemImg src={military} alt="kills" />
          </div>
          <StatsItemText>Military Points</StatsItemText>
          <StatsItemCount>{militaryScore}</StatsItemCount>
        </StatsItemCW>
        <StatsItemCW>
          <div>
            <StatsItemImg src={economic} alt="deaths" />
          </div>
          <StatsItemText>Economic Points</StatsItemText>
          <StatsItemCount>
            {economicScore}
          </StatsItemCount>
        </StatsItemCW>
        <StatsItemCW>
          <div>
            <StatsItemImg src={total} alt="kdr" />
          </div>
          <StatsItemText>Total Points</StatsItemText>
          <StatsItemCount>{totalScore}</StatsItemCount>
        </StatsItemCW>
        {/*{!(propi % 2) && <Separator />}*/}
      </HiddenStats>
    </>
  );
}

export const HiddenStats = styled.div<{ open: boolean }>`
  height: ${({ open }) => (open ? `130px` : "0px")};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.1s;
  row-gap: 3px;
  //grid-template-rows: 1fr 2px 1fr;
`;

export const StatsItemCW = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  background-image: url(${fullwStatBg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  grid-column: 1 / span 2;

  & > div:nth-child(1) {
    display: flex;
    justify-content: center;
    flex: 0 0 40px;
  }
`;

export const StatsItemImg = styled.img`
  margin-left: 10px;
`;

export const StatsItemText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  margin-left: 8px;
`;
export const StatsItemCount = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  margin-left: auto;
  margin-right: 12px;
`;

export const TableArrowMob = styled.img.attrs({
  src: arrow,
})<{ open: boolean }>`
  width: 20px;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.1s;
  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      transform: rotate(-180deg);
    `};
`;
