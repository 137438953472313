import Box from "components/Box";
import Img from "components/Img";
import Text from "components/Text";
import cityShieldGreenIcon from "pages/WorldDomination/assets/cityShieldGreenIcon.svg";
import cityShieldIcon from "pages/WorldDomination/assets/cityShieldIcon.svg";
import { NukesPlanned } from "pages/WorldDomination/WDGridPanel/City.styled";
import * as s from "pages/WorldDomination/WDGridPanel/WDGrid.styled";
import React from "react";
import { numberWithCommas } from "utils/numberWithComas";

export const City = React.memo(function City({
  attacksPlanned,
  cityId,
  cityPoints,
  color,
  name,
  onClick,
  shields,
  shieldsPlanned,
}: {
  onClick: () => void;
  shields: false | number;
  shieldsPlanned: number;
  name: string;
  cityPoints: number;
  attacksPlanned: any;
  cityId: string;
  color: string;
}) {
  return (
    <s.CityWrapper data-id={cityId}>
      <s.City onClick={onClick} color={color}>
        {shields || shieldsPlanned ? (
          <Box gap={10}>
            {shields > 0 && (
              <Text>
                {shields} <Img inline src={cityShieldIcon} w={10} h={11} />
              </Text>
            )}
            {shieldsPlanned > 0 && (
              <Text green>
                +{shieldsPlanned}{" "}
                <Img inline src={cityShieldGreenIcon} w={10} h={11} />
              </Text>
            )}
          </Box>
        ) : null}
        <s.CityName color={color}>{name}</s.CityName>
        <s.CityPoints>{numberWithCommas(cityPoints)}</s.CityPoints>
        {attacksPlanned > 0 && <NukesPlanned count={attacksPlanned} />}
        {/*{percentsLost > 0 && <PercentsLost count={percentsLost} />}*/}
      </s.City>
    </s.CityWrapper>
  );
});
