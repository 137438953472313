import { inventory_getItem } from "api";
import { useSwrImmutable } from "hooks/useSwr";
import { greenLog } from "utils/advancedLog";
import * as s from "./TieredLeaderboard.styled";

const url = "https://assets-cdn.miniroyale.io/";

export function RewardItem({
  w = 90,
  item: { itemId, amount },
}: {
  w?:number
  item: {
    itemId: string;
    amount: number;
  };
}) {
  greenLog(w, "w")
  
  const [itemData] = useSwrImmutable(itemId, inventory_getItem);
  if(!itemData) return null
  const itemSrc = itemData.standard.asset.icon;
  return (
    <s.RewardItem rarity={itemData.rarity} w={w}>
      <s.RewardItemAmount amount={amount} />
      <img src={url + itemSrc} alt={itemData.standard.asset.icon} />
    </s.RewardItem>
  );
}



