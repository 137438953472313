import {
  getMiniTableItems,
  getTreasuryBalance,
  inventory_getItemMany,
} from "api";
import { IItemsNormalized } from "api/api.types";
import { Submenu } from "components/IntraClan/Submenu/Submenu";
import {
  BlockWrapper,
  BtnWrapper,
  Container,
  Title,
  TransferBtn,
} from "components/Transfer/ClanMembers/ClanMembers.style";
import { ClanMembersTable } from "components/Transfer/ClanMembers/ClanMembersTable";
import { CurrenciesTable } from "components/Transfer/Currencies/CurrenciesTable";
import { Wrapper } from "components/Wrapper";
import { useIsMobile, useIsTablet } from "hooks/useMediaQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  IBalance,
  IgameData,
  IImagesSrc,
  IminiItem,
  IminiTable,
  IShard,
} from "types/transferTypes";

export const TransferPage = () => {
  const isLeader = sessionStorage.getItem("isClanLeader");
  const navigate = useNavigate();
  if (!isLeader) navigate("/transferHistory");

  const [balance, setBalance] = useState({} as IBalance);
  const [miniImages, setMiniImg] = useState<IminiItem[]>([]);
  const [gdItems, setGdItems] = useState<IItemsNormalized>();
  const [currentItem, setItem] = useState<
    IgameData | IminiTable | IShard | number | null
  >(null);
  const [isUpdated, setUpdate] = useState("");
  const url = "https://assets-cdn.miniroyale.io/";

  useEffect(() => {
    getTreasuryBalance().then((data: IBalance) => {
      const newData = {
        ...data,
        gamedataItemsList: data.gamedataItemsList.map((item: IgameData) => ({
          ...item,
          selected: false,
        })),
        mintableTokensList: data.mintableTokensList.map((item: IminiTable) => ({
          ...item,
          selected: false,
        })),
        shards: data.offchainFundsList
          .filter((i) => i.fund.account.includes("SHARDS_"))
          .map((i) => ({ ...i, selected: false })),
      };

      newData.allItems = [
        ...newData.mintableTokensList,
        ...newData.gamedataItemsList,
      ];
      newData.butter.selected = false;
      setBalance(newData);

      const miniIds = data?.mintableTokensList
        .map(({ token }: IminiTable) => ({
          id: token?.configId,
          lvl: token.level,
        }))
        .filter((item: { id: string; lvl: number }) => item);
      getMiniTableItems(miniIds).then((data: IminiItem[]) => setMiniImg(data));

      const ids = data?.gamedataItemsList
        .map(({ item }: IgameData) => item?.itemId)
        .filter((item: string) => item);
      inventory_getItemMany(ids).then((data: IItemsNormalized) =>
        setGdItems(data)
      );
    });
  }, [isUpdated]);

  const onSelect = (incItem: IminiTable | IgameData | string | IShard) => {
    if (typeof incItem === "string" && incItem === "butter") {
      const newDataItems = balance.allItems.map((item) => ({
        ...item,
        selected: false,
      }));
      const newShards = balance.shards.map((item) => ({
        ...item,
        selected: false,
      }));

      setBalance({
        ...balance,
        allItems: newDataItems,
        butter: { ...balance.butter, selected: true },
        shards: newShards,
      });
      setItem(balance?.butter?.total);
    } else if (typeof incItem !== "string" && "fund" in incItem) {
      const newDataItems = balance.allItems.map((dataItem) => ({
        ...dataItem,
        selected: false,
      }));
      const newShards = balance.shards.map((shard) => {
        return shard.fund.account === incItem.fund.account
          ? { ...shard, selected: true }
          : { ...shard, selected: false };
      });
      setBalance({
        ...balance,
        allItems: newDataItems,
        butter: { ...balance.butter, selected: false },
        shards: newShards,
      });
      setItem(incItem as IminiTable | IgameData | IShard);
    } else {
      const newDataItems = balance.allItems.map((dataItem: IminiTable) => {
        const gameDataId = (incItem as IgameData)?.item?.itemId;
        const miniTableId = (incItem as IminiTable)?.token?.configId;

        return (gameDataId && dataItem?.item?.itemId === gameDataId) ||
          (miniTableId && dataItem?.token?.configId === miniTableId)
          ? { ...dataItem, selected: true }
          : { ...dataItem, selected: false };
      });
      const newShards = balance.shards.map((item) => ({
        ...item,
        selected: false,
      }));
      setBalance({
        ...balance,
        allItems: newDataItems,
        butter: { ...balance.butter, selected: false },
        shards: newShards,
      });
      setItem(incItem as IminiTable | IgameData);
    }
  };

  const setGdImage = (incItem: IminiTable | IgameData): IImagesSrc | null => {
    const gdId = incItem?.item?.itemId;
    const miniId = incItem?.token?.configId;
    if (gdId) {
      const src = gdItems?.[gdId]?.standard?.asset?.icon;

      const result = {
        src: `${url}${src}`,
        rarity: gdItems?.[gdId]?.rarity,
      };
      return result;
    } else if (miniId) {
      const result = {
        src: miniImages?.filter((item: IminiItem) => miniId === item.id)?.[0]
          ?.asset?.iconUrl,
        rarity: +miniImages?.filter(
          (item: IminiItem) => miniId === item.id
        )?.[0]?.rarity,
      };
      return result;
    } else return null;
  };

  const setGdname = (incItem: IminiTable | IgameData): string => {
    const gdId = incItem?.item?.itemId;
    const miniId = incItem?.token?.configId;
    if (gdId) {
      return gdItems?.[gdId]?.name as string;
    } else if (miniId) {
      return miniImages?.filter((item: IminiItem) => miniId === item.id)?.[0]
        ?.name;
    } else return "Butter";
  };

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return (
    <Wrapper>
      <Submenu />
      {isMobile && (
        <BtnWrapper>
          <Title>
            <div style={{ fontWeight: "700" }}>Transfer Items</div>
          </Title>
          <TransferBtn
            onClick={() => navigate("/clanWars/transferHistory")}
            isLeader
          >
            Transfer History
          </TransferBtn>
        </BtnWrapper>
      )}
      <Container isMobile={isMobile}>
        {!isMobile && (
          <BtnWrapper>
            <Title>
              <div style={{ fontWeight: "700" }}>Transfer Items</div>
            </Title>
            <TransferBtn
              onClick={() => navigate("/clanWars/transferHistory")}
              isLeader
            >
              Transfer History
            </TransferBtn>
          </BtnWrapper>
        )}
        <BlockWrapper>
          <CurrenciesTable
            balance={balance}
            currentItem={currentItem}
            onSelect={onSelect}
            setGdImage={setGdImage}
            setUpdate={setUpdate}
            setGdname={setGdname}
          />
          {!isTablet && !isMobile && (
            <ClanMembersTable
              currentItem={currentItem}
              setGdImage={setGdImage}
              setUpdate={setUpdate}
              setGdname={setGdname}
            />
          )}
        </BlockWrapper>
      </Container>
      {/*<DevButton onClick={shards_getAllRecipes} />*/}
      {/*<DevButton onClick={()=>cgs_getConfigDescription("QuestsS3/Season003-SpaceWarriorMan04Suit01Green",1)} />*/}
    </Wrapper>
  );
};
