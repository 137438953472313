import {
  clanevents_getClanLeaderboard,
  clanevents_getLeaderboardViewEvent,
} from "api";
import { DeskTable } from "components/TieredLeaderboard/componetns/DeskTable";
import { TabletTable } from "components/TieredLeaderboard/componetns/TabletTable";
import { useMediaAll } from "hooks/useMediaQuery";
import { useSwrImmutable } from "hooks/useSwr";
import React from "react";
import { MobTable } from "./componetns/MobTable";

export function ClanTieredLeaderboard() {
  const { isDesktop, isMobile, isTablet } = useMediaAll();

  const [event] = useSwrImmutable("event", clanevents_getLeaderboardViewEvent, {
    refreshInterval: 10_1000,
  });

  const [clans, loadingClanLB] = useSwrImmutable(
    event ? [event, { clanSlots: true, allItems: true }, "clanLB"] : null,
    clanevents_getClanLeaderboard
  );

  if (loadingClanLB || !clans || !event) return null;

  return (
    <>
      {isDesktop && <DeskTable clans={clans} event={event} />}
      {isTablet && <TabletTable clans={clans} event={event} />}
      {isMobile && <MobTable clans={clans} event={event} />}
    </>
  );
}
