import {
  clanevents_getIndividualPlayerLeaderboard,
  clanevents_getLeaderboardViewEvent,
} from "api";
import { I_DeskTableIndividual } from "components/TieredLeaderboard/componetns/IndividualLB/I_DeskTableIndividual";
import { I_MobTable } from "components/TieredLeaderboard/componetns/IndividualLB/I_MobTable";
import { I_TabletTable } from "components/TieredLeaderboard/componetns/IndividualLB/I_TabletTable";
import { useMediaAll } from "hooks/useMediaQuery";
import { useSwrImmutable } from "hooks/useSwr";
import React from "react";

export function IndividualTieredLeaderboard() {
  const { isDesktop, isMobile, isTablet } = useMediaAll();

  const [event] = useSwrImmutable("event", clanevents_getLeaderboardViewEvent, {
    refreshInterval: 10_1000,
  });

  const [players] = useSwrImmutable(
    event ? [event.id, "playerLB"] : null,
    clanevents_getIndividualPlayerLeaderboard
  );
  if (!players || !event) return null;

  return (
    <>
      {isDesktop && <I_DeskTableIndividual players={players} event={event} />}
      {isTablet && <I_TabletTable players={players} event={event} />}
      {isMobile && <I_MobTable players={players} event={event} />}
    </>
  );
}
