import * as s from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import React from "react";
import { selectBonuses, selectEvent } from "redux/slices/eventPointsSlice";
import { useAppSelector } from "store";
import { BonusType, IBonusItem } from "types/stakingTypes";

export function Bonuses() {
  const bonuses = useAppSelector(selectBonuses);
  const event = useAppSelector(selectEvent);

  const EventStakingBonuses = bonuses.filter(
    (bonus: IBonusItem) => bonus.type === BonusType.MAIN_BONUS
  );

  const isFutureEvent = (event?.endAt as any)?.startsIn;

  return (
    <div>
      <div className="title">
        {isFutureEvent && "upcoming "}event staking bonuses:
      </div>
      <s.Bonuses>
        {EventStakingBonuses?.map((item: IBonusItem, i: number) => {
          return (
            <React.Fragment key={i}>
              <div>
                <img src={item?.icon} alt="icon" className="icon" />
              </div>
              <div className="information">
                <div className="white">{item?.name}</div>
                <div className="yellow">{item?.description}</div>
              </div>
            </React.Fragment>
          );
        })}
      </s.Bonuses>
    </div>
  );
}
